
































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import { EventBus, BusEvents } from '../../helpers/eventbus';
import * as helpers from '../../helpers';
import GenericForm from '../../components/spec/GenericForm.vue';
import * as consts from '../../consts';
import * as tools from '../../tests/model/tools';
import SuperSubScriptDisplayer from '../../components/SuperSubScriptDisplayer.vue';
import Modal from '../../components/spec/Modal.vue';
import { create, all } from 'mathjs';
const math = create(all);

@Component({
  components: {
    GenericForm,
    SuperSubScriptDisplayer,
    Modal,
  },
})
export default class SampleParamsEditor extends Vue {
  @Ref() readonly modalFormula: Modal;
  @Prop({ type: Object, required: true, default: null }) sample;
  @Prop({ type: Object, required: true, default: null }) testProp;
  @Prop({ type: Boolean, required: false, default: false }) useFinal;
  @Prop({ required: false, default: false }) isEditable;
  @Prop({ required: false, default: false }) methodForm;
  @Prop({ required: false, default: false }) resultCard;
  @Prop({ type: Boolean, required: false, default: true }) authorised;
  test = undefined;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;
  isEditing = false;
  isFinalEditing = false;
  sampleTestParamsTree = [];
  isEditingFinalParam = false;
  idArray = [];
  enabled = true;
  editedSampleTestParam = null;
  editedFinalParam = null;
  isEditingSampleTestParam = false;
  deletedParams = [];
  formula = '';
  symbol = '';
  idCounter = consts.NEW_PARAM_ID_START + 1;
  paramsToUpdateAll = [];
  paramsToUpdate1 = [];
  paramsToUpdate2 = [];
  paramsToUpdate3 = [];
  paramsToUpdate4 = [];
  editField = 'editField';
  editFinalField = 'editFinalField';

  openFormulaModal() {
    this.modalFormula.showModal();
  }
  get defaultSymbol() {
    return '$' + this.test['number'] + '_';
  }
  get sampleTestParamsTemplate() {
    return this.arrayFromTree().filter(stp => {
      return !stp.is_final_result;
    });
  }
  get finalParams() {
    if (!this.useFinal) return [];
    if (this.test.sampleTestParams.constructor === Array) {
      return this.test.sampleTestParams.filter(stp => {
        return stp.is_final_result;
      });
    } else {
      if (this.test.sampleTestParams.is_final_result) {
        return this.test.sampleTestParams;
      } else {
        return [];
      }
    }
  }
  setup() {
    this.test = this.testProp;
    //console.log('setup test', this.test);
    if (this.methodForm === true) {
      if (this.test.test_params !== undefined) {
        if (this.test.test_params.constructor === Array) {
          this.test.sampleTestParams.push(...this.test.test_params);
        } else {
          this.test.sampleTestParams.push(this.test.test_params);
        }
      }
    }
    console.log('setup stp', this.test.sampleTestParams);
  }
  mounted() {
    EventBus.$on(BusEvents.CLEAR, () => {
      // console.log('clear');
    });
    if ('tests' in this.sample) {
      this.sample['tests'].forEach(test => {
        //console.log('test', test);
        test.sampleTestParams.forEach(param => {
          this.update(param.value, param);
        });
      });
    }
  }
  showFormula(param) {
    this.formula = param.formula;
    this.symbol = param.symbol;
    this.modalFormula.showModal();
  }
  controlLeftClick(stp) {
    stp.hover = false;
    function arr(paramArr) {
      paramArr.forEach(s => {
        s.level--;
        arr(s.children);
      });
    }
    if (stp.level > 0) {
      stp.level--;
      arr(stp.children);
      this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
      this.treeFromParams();
      if (stp.level === 0) {
        stp.parent_id = null;
        stp.index = this.sampleTestParamsTree.length;
        this.test.sampleTestParams.push(stp);
        this.sampleTestParamsTree.push(stp);
      } else {
        const parent = this.test.sampleTestParams.find(s => stp.parent_id === s.id);
        const parentParent = this.test.sampleTestParams.find(s => parent.parent_id === s.id);
        const levelParams = this.test.sampleTestParams.filter(
          s => stp.level === s.level && s.parent_id === parentParent.id
        );
        stp.parent_id = parentParent.id;
        console.log('PARENT PARENT', parentParent);
        stp.index = levelParams.length;
        this.test.sampleTestParams.push(stp);
        parentParent.children.push(stp);
      }
      console.log(stp.name, stp.level, stp.index);
      this.treeFromParams();
      this.sortTree();
      //this.$forceUpdate();
    }
  }
  controlRightClick(stp) {
    console.log('RIGHT CLICK PARAM', stp);
    console.log('RIGHT CLICK STPS', this.test.sampleTestParams);
    stp.hover = false;
    function arr(paramArr) {
      paramArr.forEach(s => {
        if (s.level < consts.MAX_LEVELS) {
          s.level++;
        }
        arr(s.children);
      });
    }
    function arrCheckIfTooManyLevels(paramArr) {
      paramArr.forEach(s => {
        if (s.level >= consts.MAX_LEVELS) {
          return false;
        }
        if (!arrCheckIfTooManyLevels(s.children)) {
          return false;
        }
      });
      return true;
    }
    if (stp.level < consts.MAX_LEVELS) {
      if (arrCheckIfTooManyLevels(stp.children)) {
        let parent = this.test.sampleTestParams.find(
          s => stp.parent_id === s.parent_id && stp.index - 1 === s.index && s.is_final_result === false
        );
        if (parent === undefined) {
          parent = this.test.sampleTestParams.find(
            s => stp.parent_id === s.parent_id && stp.index - 2 === s.index && s.is_final_result === false
          );
        }
        console.log('parent', parent);
        if (parent) {
          stp.level++;
          arr(stp.children);
          this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
          this.treeFromParams();
          const levelParams = this.test.sampleTestParams.filter(
            s => stp.level === s.level && s.parent_id === parent.id && s.is_final_result === false
          );
          stp.parent_id = parent.id;
          console.log('PARENT PARENT', parent);
          stp.index = levelParams.length;
          this.test.sampleTestParams.push(stp);
          parent.children.push(stp);

          console.log(stp.name, stp.level, stp.index);
          this.treeFromParams();
          this.sortTree();
          console.log('RIGHT CLICK ARRAY', this.arrayFromTree());
          console.log('RIGHT CLICK ARRAY stp', this.test.sampleTestParams);
          //this.$forceUpdate();
        }
      }
    }
  }
  controlDownClick(stp) {
    this.sortTree();
    console.log('TREE', this.sampleTestParamsTree);
    const levelParams = this.test.sampleTestParams.filter(
      s => stp.level === s.level && s.parent_id === stp.parent_id && s.is_final_result === stp.is_final_result
    );
    console.log('LEVEL PARAMS', levelParams.length);
    if (stp.index < levelParams.length - 1) {
      const lower = this.test.sampleTestParams.find(
        s =>
          s.parent_id === stp.parent_id &&
          s.index === stp.index + 1 &&
          s.level === stp.level &&
          s.is_final_result === stp.is_final_result
      );
      stp.index++;
      lower.index--;
      stp.hover = false;
      this.sortTree();
      //this.$forceUpdate();
      console.log(stp.name, stp.level, stp.index);
      console.log('DOWN TO ARRAY tree', this.arrayFromTree());
      console.log('DOWN TO ARRAY stp', this.test.sampleTestParams);
    }
  }
  controlDownClickFinal(fp) {
    if (this.test.sampleTestParams.filter(s => s.is_final_result === true).length > 1) {
      const lower = this.test.sampleTestParams.find(
        s => s.is_final_result === fp.is_final_result && s.index === fp.index + 1
      );
      fp.index++;
      if (lower) {
        lower.index--;
      }
      this.sortFinalParams();
      this.sortTree();
    }
  }
  controlUpClick(stp) {
    this.sortTree();
    if (stp.index > 0) {
      const higher = this.test.sampleTestParams.find(
        s =>
          s.parent_id === stp.parent_id &&
          s.index === stp.index - 1 &&
          s.level === stp.level &&
          s.is_final_result === stp.is_final_result
      );
      console.log('HIGHER', higher);
      stp.index--;
      if (higher) {
        higher.index++;
      }
      stp.hover = false;
      this.sortTree();
      //this.$forceUpdate();
      console.log(stp.name, stp.level, stp.index);
      console.log('UP TO ARRAY tree', this.arrayFromTree());
      console.log('UP TO ARRAY stp', this.test.sampleTestParams);
    }
  }
  controlUpClickFinal(fp) {
    if (fp.index > 0) {
      const higher = this.test.sampleTestParams.find(
        s => s.is_final_result === fp.is_final_result && s.index === fp.index - 1
      );
      fp.index--;
      if (higher) {
        higher.index++;
      }
      this.sortFinalParams();
      this.sortTree();
    }
  }
  controlDeleteClick(stp) {
    function arr(context, param) {
      const paramArr = param.children;
      paramArr.forEach(s => {
        context.deletedParams.push(context.test.sampleTestParams.find(a => a.id === s.id));
        context.test.sampleTestParams = context.test.sampleTestParams.filter(a => a.id != s.id);
        arr(context, s);
      });
    }
    if (stp.is_final_result) {
      if (this.test.sampleTestParams.filter(p => p.is_final_result === true).length > 1) {
        this.finalParams
          .filter(f => f.index > stp.index)
          .forEach(el => {
            el.index--;
          });
        this.deletedParams.push(this.test.sampleTestParams.find(a => a.id === stp.id));
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        for (let i = 0; i < this.finalParams.length; i++) {
          if (this.finalParams[i].id === stp.id) {
            this.finalParams.splice(i, 1);
          }
        }
        this.treeFromParams();
      } else {
        this.deletedParams.push(stp);
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        this.finalParams.shift();
        this.treeFromParams();
      }
    } else {
      if (this.test.sampleTestParams.filter(p => p.is_final_result === false).length > 1) {
        if (stp.level != 0) {
          const parent = this.test.sampleTestParams.find(s => s.id === stp.parent_id);
          parent.children
            .filter(s => s.index > stp.index)
            .forEach(el => {
              el.index--;
            });
        } else {
          this.sampleTestParamsTree
            .filter(s => s.index > stp.index)
            .forEach(el => {
              el.index--;
            });
        }
        arr(this, stp);
        this.deletedParams.push(this.test.sampleTestParams.find(a => a.id === stp.id));
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        this.treeFromParams();
      } else {
        this.deletedParams.push(stp);
        this.test.sampleTestParams = this.test.sampleTestParams.filter(s => s.id != stp.id);
        this.treeFromParams();
      }
    }
    this.test.deletedParams = this.deletedParams;
    console.log('deleted', this.test.deletedParams);
  }

  defaultChildAddRC = {
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: false,
  };

  defaultChildAddMF = {
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: false,
    id: this.idCounter,
    parent_id: null,
  };

  defaultChildAddFinalRC = {
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: true,
  };

  defaultChildAddFinalMF = {
    index: null,
    level: 0,
    children: [],
    parent: null,
    name: 'Dodaj',
    is_final_result: true,
    id: this.idCounter,
    parent_id: null,
  };

  addTopParamRC() {
    const newObj = Object.assign({}, this.defaultChildAddRC);
    if (this.defaultSymbol !== '$undefined') {
      newObj['symbol'] = this.defaultSymbol;
    }
    this.idCounter++;
    this.test.sampleTestParams.push(newObj);
    this.treeFromParams();
  }

  addTopParamMF() {
    const newObj = Object.assign({}, this.defaultChildAddMF);
    if (this.defaultSymbol !== '$undefined') {
      newObj['symbol'] = this.defaultSymbol;
    }
    this.idCounter++;
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.forEach(s => this.idArray.push(s.id));
    } else {
      this.idArray.push(this.test.sampleTestParams.id);
    }
    if (this.idArray) {
      if (this.idArray.includes(newObj.id)) {
        newObj.id =
          Math.max(
            ...this.test.sampleTestParams.map(s => {
              return s.id;
            })
          ) + 1;
      }
    }
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.push(newObj);
    } else {
      this.test.sampleTestParams = [this.test.sampleTestParams, newObj];
    }
    this.treeFromParams();
  }

  addTopFinalParamRC() {
    const newObj = Object.assign({}, this.defaultChildAddFinalRC);
    if (this.defaultSymbol !== '$undefined') {
      newObj['symbol'] = this.defaultSymbol;
    }
    this.idCounter++;
    this.finalParams.push(newObj);
    this.treeFromParams();
    this.test.sampleTestParams.push(newObj);
  }

  addTopFinalParamMF() {
    const newObj = Object.assign({}, this.defaultChildAddFinalMF);
    if (this.defaultSymbol !== '$undefined') {
      newObj['symbol'] = this.defaultSymbol;
    }
    this.idCounter++;
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.forEach(s => this.idArray.push(s.id));
    } else {
      this.idArray.push(this.test.sampleTestParams.id);
    }
    if (this.idArray) {
      if (this.idArray.includes(newObj.id)) {
        newObj.id =
          Math.max(
            ...this.test.sampleTestParams.map(s => {
              return s.id;
            })
          ) + 1;
      }
    }
    if (this.test.sampleTestParams.filter(s => s.is_final_result === true).length === 0) {
      newObj.index = 0;
    } else {
      newObj.index = this.test.sampleTestParams.filter(s => s.is_final_result === true).length;
    }
    this.finalParams.push(newObj);
    this.treeFromParams();
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.push(newObj);
    } else {
      this.test.sampleTestParams = [this.test.sampleTestParams, newObj];
    }
  }

  controlCopyClick(stp) {
    function arr(param, context) {
      const paramArr = param.children;
      paramArr.forEach(s => {
        s.id = context.idCounter;
        context.idCounter++;
        if (stp.is_final_result === false) {
          s.parent_id = param.id;
        }
        context.test.sampleTestParams.push(s);
        arr(s, context);
      });
    }
    const coppied = JSON.parse(JSON.stringify(stp));
    if (stp.is_final_result === false) {
      stp.hover = false;
      if (stp.level != 0) {
        const parent = this.test.sampleTestParams.find(s => s.id === stp.parent_id);
        parent.children
          .filter(s => s.index > coppied.index)
          .forEach(el => {
            el.index++;
          });
        coppied.index++;
        coppied.id = this.idCounter;
        this.idCounter++;
        this.test.sampleTestParams.push(coppied);
        parent.children.push(coppied);
        arr(coppied, this);
        this.sortTree();
      } else {
        this.sampleTestParamsTree
          .filter(s => s.index > coppied.index)
          .forEach(el => {
            el.index++;
          });
        coppied.index++;
        coppied.id = this.idCounter;
        this.idCounter++;
        this.sampleTestParamsTree.push(coppied);
        this.test.sampleTestParams.push(coppied);
        arr(coppied, this);
        this.sortTree();
      }
    } else {
      this.finalParams
        .filter(f => f.index > coppied.index)
        .forEach(el => {
          el.index++;
        });
      coppied.index++;
      coppied.id = this.idCounter;
      this.idCounter++;
      this.finalParams.push(coppied);
      this.test.sampleTestParams.push(coppied);
      this.sortFinalParams();
    }
    console.log('TEST PARAMS', this.test.sampleTestParams);
    //this.$forceUpdate();
  }
  hoverOn(stp) {
    stp['hover'] = true;
    this.$forceUpdate();
  }
  hoverOff(stp) {
    stp['hover'] = false;
    this.$forceUpdate();
  }
  hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }
  editParamClickSample(stp) {
    this.isEditingSampleTestParam = true;
    this.editedSampleTestParam = stp;
    setTimeout(() => {
      const editField = document.getElementById('editField');
      editField.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }
  editParamClickFinal(stp) {
    this.isEditingFinalParam = true;
    this.editedFinalParam = stp;
    setTimeout(() => {
      const editFinalField = document.getElementById('editFinalField');
      editFinalField.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }
  editParamStop(editedParam) {
    setTimeout(() => {
      const stoppedParam = document.getElementById('param' + editedParam.id);
      stoppedParam.scrollIntoView({ behavior: 'smooth' });
    }, 500);

    /******************* symbols - validation ******************/
    let emptySymbol = false;
    const emptySymbolNames = [];
    const emptySymbolStps = [];
    let notUniqueSymbol = false;
    const notUniqueSymbolNames = [];
    const notUniqueSymbolStps = [];
    let noTestNoInSymbol = false;
    const noTestSymbolNames = [];
    const noTestSymbolStps = [];
    const symbols = [];
    const stps = this.test['sampleTestParams'];

    for (let index = 0; index < stps.length; index++) {
      if (stps[index].symbol === '') {
        emptySymbol = true;
        emptySymbolNames.push(stps[index].name);
        emptySymbolStps.push(stps[index]);
      }
    }

    for (let index = 0; index < stps.length; index++) {
      if (!stps[index].symbol.includes('$' + this.test['number'])) {
        noTestNoInSymbol = true;
        noTestSymbolNames.push(stps[index].name);
        noTestSymbolStps.push(stps[index]);
      }
    }

    stps.forEach(stp => symbols.push(stp.symbol));
    if (this.hasDuplicates(symbols)) {
      notUniqueSymbol = true;
      const uniqueStps = new Set(symbols);
      const duplicates = symbols.filter(symbol => {
        if (uniqueStps.has(symbol)) {
          uniqueStps.delete(symbol);
        } else {
          return symbol;
        }
      });
      duplicates.forEach(duplicate => {
        for (let index = 0; index < stps.length; index++) {
          if (stps[index].symbol === duplicate) {
            notUniqueSymbolNames.push(stps[index].name);
            notUniqueSymbolStps.push(stps[index]);
          }
        }
      });
    }

    if (emptySymbol === true && emptySymbolStps.includes(editedParam)) {
      helpers.error('Należy uzupełnić symbol dla: ' + emptySymbolNames.join(', ') + '.');
    } else if (notUniqueSymbol === true && notUniqueSymbolStps.includes(editedParam)) {
      helpers.error('Należy zmienić symbol na unikalny dla: ' + notUniqueSymbolNames.join(', ') + '.');
    } else if (noTestNoInSymbol === true && noTestSymbolStps.includes(editedParam)) {
      helpers.error('Należy uzupełnić symbol o $ i numer metody dla: ' + noTestSymbolNames.join(', ') + '.');
    } else {
      /******************* symbols - end of validation ******************/
      this.isEditingSampleTestParam = false;
      this.isEditingFinalParam = false;
    }
  }
  toggleEditing() {
    this.treeFromParams();
    this.isEditing = !this.isEditing;
  }
  toggleFinalEditing() {
    this.treeFromParams();
    this.isFinalEditing = !this.isFinalEditing;
  }

  arrayFromTree() {
    const a = [];
    function arr(paramArr) {
      paramArr.forEach(stp => {
        a.push(stp);
        arr(stp.children);
      });
    }
    arr(this.sampleTestParamsTree);
    const paramsArray = a;

    return paramsArray;
  }

  treeFromParams() {
    console.log('TREE FROM PARAMS start');
    this.sampleTestParamsTree = [];
    if (this.test.sampleTestParams.constructor === Array) {
      this.test.sampleTestParams.forEach(param => {
        param.children = [];
      });
      for (let lvl = 0; lvl <= 5; lvl++) {
        const levelParams = this.test.sampleTestParams.filter(stp => stp.level === lvl && !stp.is_final_result);
        if (lvl === 0) {
          this.sampleTestParamsTree.push(...levelParams);
        } else {
          levelParams.forEach(levelParam => {
            const parent = this.test.sampleTestParams.find(stp => stp.id === levelParam.parent_id);
            if (parent) {
              parent.children.push(levelParam);
            }
          });
        }
      }
      console.log('TREE FROM PARAMS stpTree before sort', this.sampleTestParamsTree);
      this.sortTree();
    } else {
      if (!this.test.sampleTestParams.is_final_result) {
        this.sampleTestParamsTree.push(this.test.sampleTestParams);
        this.sortTree();
      }
    }
    console.log('TREE', this.sampleTestParamsTree);
  }
  sortFinalParams() {
    this.finalParams.sort((a, b) => {
      return parseInt(a.index) - parseInt(b.index);
    });
  }
  sortTree() {
    function arr(paramArr) {
      if (paramArr.filter(s => s.index === 0).length != 1) {
        let ind = 0;
        paramArr.forEach(el => {
          el.index = ind;
          ind++;
        });
      }
      if (paramArr.length === 1) {
        paramArr[0].index = 0;
      }
      paramArr.forEach(stp => {
        stp.children.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index);
        });
        arr(stp.children);
      });
    }
    function arrIndexFix(paramArr) {
      let ind = 0;
      paramArr.forEach(el => {
        el.index = ind;
        ind++;
      });

      if (paramArr.length === 1) {
        paramArr[0].index = 0;
      }
      paramArr.forEach(stp => {
        stp.children.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index);
        });
        arr(stp.children);
      });
    }
    this.sampleTestParamsTree.sort((a, b) => {
      return parseInt(a.index) - parseInt(b.index);
    });
    arr(this.sampleTestParamsTree);
    arrIndexFix(this.sampleTestParamsTree);
    console.log('SORTED TREE:', this.test.sampleTestParams);
    this.update(null);
  }

  //  FORMULAS

  async updateParamValue(ptu, symbolObj) {
    console.log('### EVALUATE', ptu.symbol, ptu.formula, symbolObj);
    let precisionEvaluated = '';
    try {
      precisionEvaluated = math.evaluate(ptu.precision, symbolObj);
    } catch (er) {
      console.log('Error in precision evaluation:', ptu.symbol, ptu.precision);
      helpers.error(
        'Błędne wyliczenie dokładności parametru: [' + ptu.symbol + ']  dokładność: [' + ptu.precision + ']'
      );
      return;
    }
    let value = null;
    if (ptu.formula.includes(' or ')) {
      const formulaArray = ptu.formula.split(' or ');
      formulaArray.forEach(sym => {
        if (!(sym in symbolObj)) {
          const index = formulaArray.indexOf(sym);
          if (index > -1) {
            formulaArray.splice(index, 1);
          }
        }
      });
      value = math.evaluate(formulaArray[0], symbolObj);
      if (typeof value === 'number') {
        value = math.format(value, { notation: 'fixed', precision: parseInt(precisionEvaluated, 10) });
      }
    } else if (ptu.formula.includes('sz3')) {
      const args = ptu.formula.replace(/ /g, '');
      const argsArray = args.substring(4, args.length - 1).split(',');
      const result1 = symbolObj[argsArray[0]];
      const result2 = symbolObj[argsArray[1]];
      const result3 = symbolObj[argsArray[2]];
      const repeatability = symbolObj[argsArray[3]];
      let formula = null;
      if (parseFloat(Math.abs(result1 - result2).toFixed(6)) <= repeatability) {
        console.log('średnia z 1 i 2');
        formula = (result1 + result2) / 2;
      } else {
        if (
          parseFloat(Math.abs(result1 - result3).toFixed(6)) <= repeatability &&
          parseFloat(Math.abs(result2 - result3).toFixed(6)) <= repeatability
        ) {
          console.log('średnia z 3');
          formula = (result1 + result2 + result3) / 3;
        } else {
          if (parseFloat(Math.abs(result1 - result3).toFixed(6)) > parseFloat(Math.abs(result2 - result3).toFixed(6))) {
            if (parseFloat(Math.abs(result2 - result3).toFixed(6)) <= repeatability) {
              console.log('średnia z 2 i 3');
              formula = (result2 + result3) / 2;
            } else {
              console.log('wynik 0');
              formula = 0;
            }
          } else {
            if (parseFloat(Math.abs(result1 - result3).toFixed(6)) <= repeatability) {
              console.log('średnia z 1 i 3');
              formula = (result1 + result3) / 2;
            } else {
              console.log('wynik 0');
              formula = 0;
            }
          }
        }
      }
      value = math.evaluate(formula.toString(), symbolObj);
      if (typeof value === 'number') {
        value = math.format(value, { notation: 'fixed', precision: parseInt(precisionEvaluated, 10) });
      }
    } else {
      value = math.evaluate(ptu.formula, symbolObj);
      if (typeof value === 'number') {
        value = math.format(value, { notation: 'fixed', precision: parseInt(precisionEvaluated, 10) });
      }
    }
    if (isNaN(value) === true) {
      return 'N/A';
    } else {
      return value;
    }
  }

  async updateParamValueIteration(i, iterations, paramsWithFormula, paramsWithSymbol, symbolObj, isFormula) {
    for (const ptu of iterations[i]) {
      const re = new RegExp(ptu.symbol.substring(1), 'g');
      this.paramsToUpdateAll = paramsWithFormula.filter(ptu => ptu.formula.match(re));
      this.paramsToUpdateAll.forEach(pa => {
        const index = iterations[i + 1].findIndex(ptu => ptu.id === pa.id);
        if (index === -1) {
          iterations[i + 1].push(pa);
        }
      });
      symbolObj = {};
      for (const pws of paramsWithSymbol) {
        symbolObj[pws.symbol] = parseFloat(String(pws.value).replace(',', '.'));
      }
      for (const ptu of this.paramsToUpdateAll) {
        try {
          ptu.value = await this.updateParamValue(ptu, symbolObj);
        } catch (er) {
          console.log('Error in formule evaluation:', ptu.formula, symbolObj);
          if (isFormula === true) {
            helpers.error('Błędne wyliczenie formuły parametru: [' + ptu.symbol + ']  formuła: [' + ptu.formula + ']');
          }
          return;
        }
      }
    }
  }

  async update(e: any, param: any = null, isFormula = false, isSymbol = false) {
    console.log(e, param);
    if (isSymbol) {
      /*
      if (String(e).match(/[$,.=+\-<>?:;*%]/g)) {
        error('Błędny zapis symbolu');
        param.symbol = '';
        return;
      }*/
    }

    if (param && this.resultCard === true) {
      await this.updateFormulas(e, param, isFormula, isSymbol);
    }
    this.$emit('update', this.test);
  }

  async updateFormulas(e: any, param: any, isFormula: boolean, isSymbol: boolean) {
    console.log(
      'updateFormulas e=',
      e,
      ' symbol=',
      param.symbol,
      ' param=',
      param,
      'isFormula=',
      isFormula,
      ' isSymbol=',
      isSymbol
    );
    const paramsWithSymbol = [];
    console.log('Iterating tests', this.sample['tests']);
    this.sample['tests'].forEach(test => {
      paramsWithSymbol.push(...test.sampleTestParams.filter(stpt => stpt.symbol != ''));
      console.log('test', test, 'stps with symbols', paramsWithSymbol);
    });
    const paramsWithFormula = this.test.sampleTestParams.filter(stpt => stpt.formula != '');
    let paramsToUpdate = paramsWithFormula;
    console.log(
      'ALL Params with formula',
      paramsToUpdate.map(ptu => ptu.symbol + ' => ' + ptu.formula)
    );
    if (isFormula) {
      paramsToUpdate = paramsToUpdate.filter(ptu => ptu.id === param.id);
      console.log(
        'all to update FORMULA',
        paramsToUpdate.map(ptu => ptu.formula)
      );
    } else if (isSymbol || param.symbol) {
      // eslint-disable-next-line
      const re = new RegExp(param.symbol.substring(1), 'g');
      console.log('REGEX', re);
      paramsToUpdate = paramsToUpdate.filter(ptu => ptu.formula.match(re));
      console.log(
        'ALL to UPDATE SYMBOL (found by regex)',
        paramsToUpdate.map(ptu => ptu.symbol + ' => ' + ptu.formula)
      );
    } else {
      return;
    }

    const symbolObj = {};
    for (const pws of paramsWithSymbol) {
      symbolObj[pws.symbol] = parseFloat(String(pws.value).replace(',', '.'));
    }
    console.log('symbols object', symbolObj);
    console.log(
      'formulas to update',
      paramsToUpdate.map(ptu => ptu.formula)
    );
    for (const ptu of paramsToUpdate) {
      try {
        ptu.value = await this.updateParamValue(ptu, symbolObj);
      } catch (er) {
        console.log('Error in formule evaluation:', ptu.formula, symbolObj);
        if (isFormula === true) {
          helpers.error('Błędne wyliczenie formuły parametru: [' + ptu.symbol + ']  formuła: [' + ptu.formula + ']');
        }
        return;
      }
    }
    const iterations = [
      paramsToUpdate,
      this.paramsToUpdate1,
      this.paramsToUpdate2,
      this.paramsToUpdate3,
      this.paramsToUpdate4,
    ];
    for (let i = 0; i < iterations.length - 1; i++) {
      iterations[i + 1] = [];
      await this.updateParamValueIteration(i, iterations, paramsWithFormula, paramsWithSymbol, symbolObj, isFormula);
    }
  }

  async created() {
    this.setup();
    this.enabled =
      (this.sample['status'] === consts.SampleStatus.IN_EXAM.id && this.authorised) ||
      (this.sample['status'] === consts.SampleStatus.CORRECT.id && this.authorised) ||
      (this.sample.status === consts.SampleStatus.REGISTERED.id &&
        this.authorised &&
        [consts.TestEnabledInRegisteredCol.WILGOC_WEGIEL, consts.TestEnabledInRegisteredCol.WILGOC_BIOMASA].includes(
          this.test.number
        ));
    this.treeFromParams();
    this.sortFinalParams();
    const paramsWithSymbol = [];
    if (this.resultCard === true) {
      this.sample['tests'].forEach(test => {
        paramsWithSymbol.push(...test.sampleTestParams.filter(stpt => stpt.symbol != ''));
      });
      const paramsWithFormula = this.test.sampleTestParams.filter(stpt => stpt.formula != '');
      const paramsToUpdate = paramsWithFormula.filter(pwf => !pwf.formula.includes('$'));
      const symbolObj = {};
      for (const pws of paramsWithSymbol) {
        symbolObj[pws.symbol] = parseFloat(String(pws.value).replace(',', '.'));
      }
      for (const ptu of paramsToUpdate) {
        try {
          ptu.value = await this.updateParamValue(ptu, symbolObj);
        } catch (er) {
          console.log('Error in formule evaluation:', ptu.formula, symbolObj);
          helpers.error('Błędne wyliczenie formuły parametru: [' + ptu.symbol + ']  formuła: [' + ptu.formula + ']');
          return;
        }
      }
      console.log('created stptempl', this.defaultSymbol);
      for (const pwf of paramsWithFormula) {
        const symbolObj = {};
        for (const pws of paramsWithSymbol) {
          symbolObj[pws.symbol] = parseFloat(String(pws.value).replace(',', '.'));
        }
        try {
          pwf.value = await this.updateParamValue(pwf, symbolObj);
        } catch (er) {
          console.log('Error in formule evaluation:', pwf.formula, symbolObj);
          helpers.error('Błędne wyliczenie formuły parametru: [' + pwf.symbol + ']  formuła: [' + pwf.formula + ']');
          return;
        }
      }
    }
  }
}
