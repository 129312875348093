


































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ModalExamFilter from '@/components/spec/ModalExamFilter.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericList from '@/components/spec/GenericList.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as consts from '@/consts';
import * as helpers from '@/helpers';
import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '@/consts';
import PersonelModal from './PersonelModal.vue';

import {
  AppView,
  Exam,
  DashboardItem,
  User,
  DashboardAction,
  Sample,
  ExtendedParam,
  StandardMultiselectEngine,
} from '@/types';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    PersonelModal,
    Modal,
    Footer,
  },
})
export default class PersonelList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly userModal: PersonelModal;
  @Ref() readonly list: GenericList;
  params = new Array<ExtendedParam>();

  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  editedUser = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;

  isEditing = false;

  reload() {
    this.list.fillObjects();
  }

  openUserModal(el: User) {
    if (Object.entries(el).length == 0) {
      this.isEditing = false;
    } else this.isEditing = true;
    const copyOfUser = Object.assign({}, el);
    this.editedUser = copyOfUser as User;
    this.userModal.showModal(this.editedUser as User);
  }

  get getModalTitle() {
    if (this.isEditing) return 'Edycja użytkownika';
    else return 'Nowy użytkownik';
  }

  get canUserAddPersonnel() {
    return helpers.isUserAuthorisedOfSymbol(consts.UserRightSymbols.PERSONNEL_ADD);
  }

  processLoaded(object: any) {
    for (const key of Object.keys(object.user)) {
      object[key] = object.user[key];
    }
    object.fullName = object.first_name + ' ' + object.last_name;
    object.status = consts.UserStatus.byId(object.status_id);
  }

  showModal(object: User) {
    this.editedUser = object;
    this.userModal.showModal(this.editedUser as User);
  }
  saveModal(e: any) {
    console.log('123');
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }
  sendFilterQuery(e: any) {
    console.log('OK', this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'user__first_name',
        headerName: 'Imię',
      },
      {
        fieldType: 'input',
        fieldName: 'user__last_name',
        headerName: 'Nazwisko',
      },
      {
        fieldType: 'input',
        fieldName: 'user__email',
        headerName: 'Email',
      },
      {
        fieldType: 'input',
        fieldName: 'position',
        headerName: 'Stanowisko',
      },
      {
        fieldType: 'multiselect',
        headerName: 'Status',
        multiSelectTrack: 'name',
        options: consts.UserStatus.array.map(el => {
          return { id: el.id, name: el.name };
        }),
        engine: new StandardMultiselectEngine('status_id', 'id'),
      },
    ];

    this.params = [
      {
        headerName: 'IMIĘ I NAZWISKO',
        value: '$fullName',
        sortField: 'user__first_name',
        columnCss: 'col-2 p-0 text-left',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'EMAIL',
        value: '$email',
        sortField: 'user__email',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'STANOWISKO',
        columnCss: 'col-3 p-0 text-center',
        headerCss: 'col-3 p-0 text-center',
        value: '$position',
      },
      {
        fieldType: 'badge',
        badgeVariant: '$status__badge',
        headerName: 'STATUS',
        sortField: 'status_id',
        value: '$status__name',
      },
      {
        headerName: 'TELEFON',
        value: '$phone',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'OPERACJE',
        fieldType: 'ntexts',
        href: {
          click: async (obj: User, href: any) => {
            obj.status = obj.status.getDoneEntityStatus(consts.PersonelOperationType.byId(href.status_id));
            obj.status_id = obj.status.getId;
            const response = await helpers.saveObjectModel(obj, 'UserData');
            if (response) {
              helpers.info('Wprowadzono zmianę statusu.');
            } else {
              helpers.error('Wystąpił nieoczekiwany błąd.');
            }
          },
          getValues: (obj: User) => {
            const opers = obj.status.opers;
            const options = [];
            opers.forEach(el => {
              options.push({text: el.name, id: el.id});
            });
            return options.map(el => {
              return { text: el.text, css: 'font-12 clickable-text', status_id: el.id};
            });
          }
        }
      },
    ];

    this.modalParams = [
      {
        headerName: 'Imię',
        fieldType: 'input',
        fieldName: 'firstname',
      },
      {
        fieldType: 'input',
        headerName: 'Nazwisko',
        fieldName: 'lastname',
      },
      {
        headerName: 'Pozycja',
        fieldType: 'input',
        fieldName: 'position',
      },
    ];
    console.log(this.objects, 'fetched objects');
  }
}
