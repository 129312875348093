










































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import * as helpers from '../../helpers';
import SampleParamsEditor from './SampleParamsEditor.vue';
import * as API from '../../store/api';
import store from '../../store';
import { User } from '@/types';

@Component({
  components: { SampleParamsEditor },
})
export default class ModalResultCard extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  btnSave = 0;
  isEditing = false;
  isFinalEditing = false;
  sampleTestParamsTree = [];
  isEditingFinalParam = false;
  bus = new Vue();
  selectedNumbers = [];
  sampleTool = [];
  fetchedSampleTools = [];
  users = [];
  authUsersIds = [];
  authUsers = [];
  user: User = new User();
  mappedUsers = [];
  resultUserValue = null;
  numberValue = null;
  enabled = true;
  editedSampleTestParam = null;
  isEditingSampleTestParam = false;
  deletedParams = [];
  methodId = null;
  testToolGroups = [];
  sample: any = {};
  test: any = {};
  canUserDoExam = false;
  toolKey = 0;
  toolTestsIds = [];

  @Emit()
  modalClose() {
    return 1;
  }

  get finalParams() {
    return this.test.sampleTestParams.filter(stp => {
      return stp.is_final_result;
    });
  }

  hoverOn(stp) {
    stp['hover'] = true;
    this.$forceUpdate();
  }
  hoverOff(stp) {
    stp['hover'] = false;
    this.$forceUpdate();
  }
  editParamClick(stp) {
    console.log('edit click', stp);
    if (!stp.is_final_result) {
      this.isEditingSampleTestParam = true;
    } else {
      this.isEditingFinalParam = true;
    }
    this.editedSampleTestParam = stp;
  }
  editParamStop() {
    this.isEditingSampleTestParam = false;
    this.isEditingFinalParam = false;
  }
  toggleEditing() {
    this.isEditing = !this.isEditing;
  }
  toggleFinalEditing() {
    this.isFinalEditing = !this.isFinalEditing;
  }
  async showModal(params: any) {
    console.log('ModalResultCard::showModal', params);
    this.test = params.currentTest;
    this.show = true;
    this.save = false;
    this.sample = params.sample;
    const user = this.$store.state.currentUser;
    this.canUserDoExam = params.canUserDoExam;
    this.enabled =
      (this.sample.status === consts.SampleStatus.IN_EXAM.id && this.canUserDoExam) ||
      (this.sample.status === consts.SampleStatus.CORRECT.id && this.canUserDoExam) ||
      (this.sample.status === consts.SampleStatus.REGISTERED.id &&
        this.canUserDoExam &&
        [consts.TestEnabledInRegisteredCol.WILGOC_WEGIEL, consts.TestEnabledInRegisteredCol.WILGOC_BIOMASA].includes(
          this.test.number
        ));
    this.params = params;
    let response = await API.getSampleTest(this.test.id);
    if (response.data.success) {
      this.test = response.data.sample_test;
      if (this.test.test_laborant_id === undefined) {
        this.test.test_laborant_id = null;
      }
      if (this.test.result_laborant_id === undefined) {
        this.test.result_laborant_id = null;
      }
      console.log('fetch test on showmodal', this.test);
    }
    this.bus.$emit('setup');
    this.bus.$emit('treeFromParams');

    const methodNumber = this.test.number;
    response = await API.fetchTest({ number: methodNumber });
    if (response.data.success) {
      const method = response.data.test;
      this.methodId = method.id;
    }

    response = await API.fetchUserMethodAuths({ test_id: this.methodId, auth_type: consts.TestAuthTypesSym.EXAM });
    this.authUsersIds = response.data.method_auths.map(aui => aui.user_id);

    response = await API.fetchUsers();
    this.users = response.data.users;
    this.authUsers = this.users.filter(u => this.authUsersIds.includes(u.id));

    this.mappedUsers = this.authUsers.map(u => {
      const retUser = { id: u.id, name: u.firstname + ' ' + u.lastname };
      return retUser;
    });
    console.log('MappedUsers', this.mappedUsers, this.test);
    this.selectedTestLaborant = this.mappedUsers.find(u => {
      return u.id === this.test.test_laborant;
    });

    if (this.enabled && this.test.result_laborant === null) {
      this.resultUserValue = this.mappedUsers.find(u => {
        return u.id === user.id;
      });
    } else {
      this.resultUserValue = this.mappedUsers.find(u => {
        return u.id === this.test.result_laborant;
      });
    }

    response = await API.fetchTestToolGroups(this.methodId);
    if (response.data.success) {
      this.testToolGroups = response.data.test_tool_groups;
      console.log('testToolGroups', this.testToolGroups);
    }

    response = await API.fetchToolTests({ test_id: this.methodId });
    this.toolTestsIds = response.data.tool_tests.map(tt => tt.tool_id);

    response = await API.fetchModel('Tool');
    this.tools = response.data.objs.map(tool => {
      return { tool_id: tool.id, tool_code_number: tool.code_number };
    });
    this.tools = this.tools.filter(t => this.toolTestsIds.includes(t.tool_id));

    const sampleId = this.test.sample;
    const testId = this.test.id;
    response = await API.fetchSampleTools(sampleId, testId);
    if (response.data.success) {
      this.sampleTools = response.data.sample_tools;
    }
  }

  tools = [];
  sampleTools = [];
  toolsKey = 0;
  /* wszystkie urządzenia dla danej grupy, bez względu na próbkę */
  get selectedGroupTools() {
    return group_id => {
      console.log(
        'selectedGroupTools',
        group_id,
        this.sampleTools,
        'ttgs',
        this.testToolGroups.length,
        'sts',
        this.sampleTools.length
      );
      if (this.sampleTools.filter(st => st.group_id === group_id).length === 0) {
        const groupIndex = this.testToolGroups.findIndex(g => g.id === group_id);
        const toolId = this.testToolGroups[groupIndex]['tool_id'];
        if (typeof toolId === 'number') {
          const st = {
            tool_id: toolId,
            tool_code_number: this.tools.find(t => t.tool_id === toolId).tool_code_number,
            group_id: group_id,
            test_id: this.test.id,
          };
          this.sampleTools.push(st);
        }
      }
      return this.sampleTools.find(st => st.group_id === group_id);
    };
  }
  selectTool(e: any, group_id: number) {
    console.log('selectTool', e, group_id);
    this.sampleTools = this.sampleTools.filter(st => st.group_id !== group_id); // usuwam wybrane urzadzenie dla grupy
    const st = {
      tool_id: e.tool_id,
      tool_code_number: e.tool_code_number,
      group_id: group_id,
      test_id: this.test.id,
    };
    this.sampleTools.push(st);
    //this.toolKey++;
  }

  async hideModal() {
    this.params['save'] = this.save;
    if (!this.save) {
      const response = await API.getSampleTest(this.test.id);
      if (response.data.success) {
        this.test = response.data.sample_test;
        console.log('fetch test on cancel', this.test);
      }
    }
    this.params['savedTest'] = this.test;
    console.log('TEST', this.test.sampleTestParams, 'SAVE', this.params['save']);
    this.deletedParams = [];
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
  emitUpdate(emitTest) {
    this.test = emitTest;
  }
  workUserSelected(e: any) {
    this.selectedTestLaborant = e;
  }
  carryUserSelected(e: any) {
    this.resultUserValue = e;
  }

  async saveModal() {
    if (this.testUserValue === undefined || this.resultUserValue === undefined) {
      helpers.error('Należy uzupełnić osoby');
    } else if (this.sampleTools.length < this.testToolGroups.length) {
      helpers.error('Należy uzupełnić numery kodowe aparatów');
    } else {
      console.log('test user', this.testUserValue);
      console.log('result user', this.resultUserValue);
      this.save = true;
      this.params['save'] = true;
      this.params['savedTest'] = this.test;
      this.$emit(consts.ModalEvents.OK_EVENT, this.params);
      this.show = false;
      this.test['test_laborant_id'] = this.testUserValue;
      this.test['result_laborant_id'] = this.resultUserValue;
      if (this.finalParams.length === 1) {
        this.test.value = this.finalParams[0].value;
      }
      console.log('TO SEND TEST', this.test);
      console.log('TO SEND SAMPLE', this.sample);

      // mogly sie zmienic nazwy grup, przepisuje je, przy zapisie sampletool zapiszemy tez testtoolgroup
      this.sampleTools.forEach(st => {
        st.group_name = this.testToolGroups.find(g => g.id === st.group_id).name;
      });

      console.log('TO SEND SAMPLE TOOL', this.sampleTool);
      const response = await API.updateCard(this.sample, this.test, this.sampleTools);
      console.log(response);
    }
  }

  selectedTestLaborant = {};
  get testUserValue() {
    return this.selectedTestLaborant;
  }

  async created() {
    console.log('start sampleTool', this.sampleTool);
  }
}
