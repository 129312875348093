


































































































































































































































































































































import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import * as helpers from '../../../helpers';
import {
  ExtendedParam,
  Tool,
  Method,
  User,
  UserRight,
  StandardMultiselectEngine,
  RightQueueItem,
  UsersRight,
  UserAuth,
  File,
} from '../../../types';
import * as consts from '../../../consts';
import * as API from '../../../store/api';
import Modal from '../../../components/spec/Modal.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericField from '../../../components/spec/GenericField.vue';
import MinimalGenericList from '../../../components/spec/MinimalGenericList.vue';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';
import FileManager from '../../../components/spec/FileManager.vue';
import { UserGroup } from '../../../types';
import { Guid } from 'guid-typescript';

const USER_NAME_EXISTS = 1;
const USER_EMAIL_EXISTS = 2;

@Component({
  components: {
    GenericForm,
    ModalQuestion,
    MinimalGenericList,
    FileManager,
    GenericField,
    Modal,
  },
})
export default class PersonelModal extends Vue {
  editedUser: User;
  @Ref() readonly userModal: Modal;
  @Ref() readonly passwordModal: Modal;
  @Ref() readonly fm: FileManager;
  @Ref() readonly passwordConfirmationModal: Modal;
  @Ref() readonly deleteConfirmationModal: Modal;
  @Prop() readonly isNew: boolean;
  @Prop() readonly title: string;

  mainSectionParams: Array<ExtendedParam> = [];
  loaded = false;
  tabs: Array<any>;
  userSign: Array<File> = [];
  selectedTool = {};
  selectedMethod = {};
  modalVisible = false;
  combinedKey = 0;

  generatedPassword = '';

  toolListParams: Array<ExtendedParam> = [];
  methodListParams: Array<ExtendedParam> = [];
  workerDataParams: Array<ExtendedParam> = [];

  userSpecialAuths: Array<UserAuth> = [];
  userProcessAuths: Array<UserAuth> = [];
  allSpecialAuths: Array<UserAuth> = [];
  allProcessAuths: Array<UserAuth> = [];

  userRights: Array<UserRight> = [];
  userGroups: Array<UserGroup> = [];

  revalidateGroups = 0;

  groups: Array<UserGroup> = [];
  rights: Array<UserRight> = [];
  tests = [];
  sectionTests = [];
  tools = [];
  codeNumberWithName({ code_number, name }) {
    return `${code_number} — [${name}]`;
  }
  sectionTools = [];
  sections = [];
  sectionsNames = [consts.Sections.SR, consts.Sections.SPIO, consts.Sections.SWIS];
  testAuthTypesAll = [
    { name: consts.TestAuthTypes.EXAM, id: 1, symbol: consts.TestAuthTypesSym.EXAM },
    { name: consts.TestAuthTypes.EDIT, id: 2, symbol: consts.TestAuthTypesSym.EDIT },
    { name: consts.TestAuthTypes.VERIFY, id: 3, symbol: consts.TestAuthTypesSym.VERIFY },
  ];
  testAuthTypes = [];

  dictRightOfSelectedUser: any;
  show = false;
  revalidateCurrentRightsOfUser = 0;

  userToolsAuths: Array<any> = [];
  deletedRights: Array<RightQueueItem> = [];
  newRights: Array<RightQueueItem> = [];
  userTestsAuths: Array<any> = [];

  saveKey = 0;

  newGuid = '';

  getSectionTests() {
    if (this.editedUser.section_id) {
      if (this.sectionsNames.includes(this.editedUser.section['value'])) {
        this.sectionTests = this.tests.filter(t => t.section === this.editedUser.section_id);
      } else {
        this.sectionTests = this.tests;
      }
    } else {
      this.sectionTests = this.tests;
    }
  }

  getSectionTools() {
    if (this.editedUser.section_id) {
      if (this.sectionsNames.includes(this.editedUser.section['value'])) {
        this.sectionTools = this.tools.filter(t => t.section_id === this.editedUser.section_id);
      } else {
        this.sectionTools = this.tools;
      }
    } else {
      this.sectionTools = this.tools;
    }
  }

  numberWithName({ number, name }) {
    return `${number} — [${name}]`;
  }

  addTool() {
    if ('id' in this.toolSelected) {
      if (this.userToolsAuths.find(uta => uta.tool_id === this.toolSelected['id']) !== undefined) {
        helpers.error('Istnieje już upoważnienie do tego urządzenia');
      } else {
        this.userToolsAuths.push({ tool: this.toolSelected, tool_id: this.toolSelected['id'] });
      }
    }
    this.toolSelected = {};
  }

  get toolValue() {
    return this.toolSelected;
  }
  toolSelected = {};
  selectTool(e: KeyboardEvent) {
    this.toolSelected = e;
  }

  addTest() {
    if (this.testAuthTypes.length === 0) {
      helpers.error('Należy zaznaczyć co najmniej jeden rodzaj upoważnienia');
    } else {
      if ('id' in this.testSelected) {
        if (this.userTestsAuths.find(uta => uta.test_id === this.testSelected['id']) !== undefined) {
          helpers.error('Istnieje już upoważnienie do tej metody');
        } else {
          const testAuthTypesSym = [];
          this.testAuthTypes.forEach(type => {
            testAuthTypesSym.push(type.symbol);
          });
          this.testSelected['auth_type'] = testAuthTypesSym.join(', ');
          this.userTestsAuths.push({
            test: this.testSelected,
            test_id: this.testSelected['id'],
            auth_type: testAuthTypesSym.join(', '),
          });
        }
      }

      this.testSelected = {};
    }
  }

  get testValue() {
    return this.testSelected;
  }
  testSelected: any = {};
  selectTest(e: KeyboardEvent) {
    this.testSelected = e;
  }

  get isRightSelected() {
    return right => {
      return this.userRights && this.userRights.find(r => r.id === right.id) !== undefined;
    };
  }
  toggleRight(right, addition) {
    if (addition === true) {
      this.userRights.push(right);
    } else {
      this.userRights = this.userRights.filter(r => r.id !== right.id);
    }
    this.combinedKey++;
  }

  get isGroupSelected() {
    return group => {
      return this.userGroups.find(g => g.id === group.id) !== undefined;
    };
  }
  toggleGroup(group, addition) {
    if (addition === true) {
      this.userGroups.push(group);
    } else {
      this.userGroups = this.userGroups.filter(g => g.id !== group.id);
    }
    this.combinedKey++;
  }

  get rightsCombined() {
    const combinedRights = [];
    this.userRights.forEach(r => {
      combinedRights.push(r);
    });
    this.userGroups.forEach(g => {
      g.rights.forEach(gr => {
        if (combinedRights.find(cr => cr.id === gr.id) === undefined) {
          combinedRights.push(gr);
        }
      });
    });
    return combinedRights;
  }

  get isSpecialSelected() {
    return auth => {
      return this.userSpecialAuths.find(s => s['id'] === auth.id) !== undefined;
    };
  }
  toggleSpecial(auth, addition) {
    if (addition === true) {
      this.userSpecialAuths.push(auth);
    } else {
      this.userSpecialAuths = this.userSpecialAuths.filter(s => s.id !== auth.id);
    }
  }

  get isProcessSelected() {
    return auth => {
      return this.userProcessAuths.find(s => s['id'] === auth.id) !== undefined;
    };
  }
  toggleProcess(auth, addition) {
    if (addition === true) {
      this.userProcessAuths.push(auth);
    } else {
      this.userProcessAuths = this.userProcessAuths.filter(s => s.id !== auth.id);
    }
  }

  get isTestAuthTypeSelected() {
    return type => {
      return this.testAuthTypes.find(t => t['id'] === type.id) !== undefined;
    };
  }
  toggleType(type, addition) {
    if (addition === true) {
      this.testAuthTypes.push(type);
    } else {
      this.testAuthTypes = this.testAuthTypes.filter(t => t.id !== type.id);
    }
  }

  async copyPassword() {
    await navigator.clipboard.writeText(this.generatedPassword);
    helpers.info('Skopiowano do schowka');
  }

  async showModal(editedUser: User) {
    await this.load();

    this.editedUser = editedUser;
    if (this.editedUser.section === undefined) {
      this.editedUser['section'] = { id: null, value: '' };
      this.editedUser['section_id'] = null;
    }
    console.log('user', this.editedUser);
    this.getSectionTests();
    this.getSectionTools();
    this.userSign = [];
    this.groups.forEach(el => {
      el['collapsed'] = false;
    });

    if (this.editedUser.id) {
      let response = await API.fetchUserAuths(this.editedUser.id);

      this.userToolsAuths = response.data.tool_auths;
      this.userTestsAuths = response.data.test_auths;
      this.userSpecialAuths = response.data.special_auths;
      this.userProcessAuths = response.data.process_auths;

      response = await API.getOneUserRights(this.editedUser.id);
      console.log('UserRights data', response.data);
      this.userRights = response.data.rights !== undefined ? response.data.rights : [];

      response = await API.getOneUserGroups(this.editedUser.id);
      console.log('UserGroups data', response.data);
      this.userGroups = response.data.groups !== undefined ? response.data.groups : [];

      if (this.editedUser.sign_image) {
        this.userSign.push(this.editedUser.sign_image);
        this.editedUser['sign_image_guid'] = this.editedUser.sign_image.guid;
      }
    } else {
      this.userTestsAuths = [];
      this.userToolsAuths = [];
      this.userSpecialAuths = [];
      this.userProcessAuths = [];
      this.userRights = [];
      this.userGroups = [];
      this.userSpecialAuths = [];
      this.editedUser.status_id = 0;
      this.editedUser.status = consts.UserStatus.byId(0);
    }

    const guid = Guid.create();
    this.newGuid = guid.toString();

    this.modalVisible = true;
  }

  emit() {
    this.editedUser.sign_image_guid = this.newGuid;
  }

  hideModal() {
    /*
    this.allRightArray.forEach(el => {
      el.ownedByUser = false;
    });
    */
    this.revalidateCurrentRightsOfUser++;
    this.modalVisible = false;
  }

  get canUserChangePassword() {
    return helpers.isUserAuthorisedOfSymbol(consts.UserRightSymbols.PASSWORD_RESET);
  }
  get canUserDelete() {
    return helpers.isUserAuthorisedOfSymbol(consts.UserRightSymbols.PERSONNEL_DELETE);
  }
  get canUserEditPersonnel() {
    return helpers.isUserAuthorisedOfSymbol(consts.UserRightSymbols.PERSONNEL_CHANGE);
  }

  async resetPasswordOfEditedUser() {
    if (!this.canUserChangePassword) {
      window.alert('Brak uprawnień');
      return;
    }
    const response = await API.resetPasswordOfUser(this.editedUser.id);
    if (response.data.success) {
      this.generatedPassword = response.data.password;
      this.passwordModal.showModal();
    }
  }
  async deleteUser() {
    if (!this.canUserDelete) {
      window.alert('Brak uprawnień');
      return;
    }
    const response = await API.deleteUser(this.editedUser.id);
    if (response.data.success) {
      helpers.info('Usunięto użytkownika');
      this.hideModal();
      this.$emit('REQUEST_RELOAD');
    }
  }

  async save() {
    /***************** employee's data - validation ****************/
    const requiredData = [
      { name: 'Imię', field: this.editedUser['first_name'] },
      { name: 'Nazwisko', field: this.editedUser['last_name'] },
      { name: 'Stanowisko', field: this.editedUser.position },
      { name: 'Adres e-mail', field: this.editedUser.email },
    ];
    const emptyData = [];
    for (let index = 0; index < requiredData.length; index++) {
      if (requiredData[index].field === undefined) {
        emptyData.push(requiredData[index].name);
      }
    }
    if (this.editedUser.section['value'].length === 0) {
      emptyData.push('Sekcja');
    }
    if (emptyData.length > 0) {
      helpers.error('Należy uzupełnić następujące dane: ' + emptyData.join(', ') + '.');
    } else if (!helpers.checkMail(this.editedUser.email)) {
      helpers.error('Nieprawidłowy adres e-mail');
    } else {
      console.log(this.newRights, 'newRights');
      console.log(this.deletedRights, 'deletedRights');

      if (this.isNew === true) {
        console.log('is new', this.isNew);
        await this.fm.deleteMarkedFiles();
      }

      this.editedUser['tools'] = this.userToolsAuths.map(t => t.tool);
      this.editedUser['methods'] = this.userTestsAuths.map(m => {
        return { auth_type: m.auth_type, test: m.test };
      });

      this.editedUser.firstname = this.editedUser['first_name'];
      this.editedUser.lastname = this.editedUser['last_name'];

      this.editedUser['special_auths'] = this.userSpecialAuths;
      this.editedUser['process_auths'] = this.userProcessAuths;

      this.editedUser['groups'] = this.userGroups;
      this.editedUser['rights'] = this.userRights;
      const response = await API.savePerson(this.editedUser);
      const newUser = response.data.user as User;
      const currentVuexUser = this.$store.state.currentUser as User;

      if (response.data.success) {
        if (newUser.id === currentVuexUser.id) {
          this.$store.commit('setCurrentUser', newUser);
        }
        helpers.info('Pomyślnie zapisano');
        this.hideModal();
        this.$emit('REQUEST_RELOAD');
      } else {
        if ('error_code' in response.data) {
          if (response.data.error_code === USER_NAME_EXISTS) {
            helpers.error('Użytkownik o podanym imieniu i nazwisku istnieje, podaj inne dane');
          }
          if (response.data.error_code === USER_NAME_EXISTS) {
            helpers.error('Użytkownik z podanym adresem e-mail już istnieje, podaj inny e-mail');
          }
        } else {
          helpers.error('Wystąpił błąd. Skontaktuj się z administratorem systemu');
        }
        this.saveKey++;
      }
    }
  }

  async load() {
    this.testAuthTypes = [];
    let response = await API.fetchTools();
    this.tools = response.data.tools;
    response = await API.fetchTests(false);
    this.tests = response.data.tests;
    console.log('Loaded tests', this.tests, 'tools', this.tools);
    response = await API.fetchAuths();
    this.allSpecialAuths = response.data.special_auths;
    this.allProcessAuths = response.data.process_auths;

    response = await API.fetchUserRights();
    this.rights = response.data.rights;
    console.log('Rights', response.data.rights);

    response = await API.fetchUserGroups();
    this.groups = response.data.groups;
    console.log('Groups', response);

    response = await API.fetchDictType(consts.DictType.SECTION);
    if (this.sections.length === 0) {
      response.data.objs.forEach(element => {
        this.sections.push(Object.assign({}, element));
      });
    }

    this.methodListParams = [
      {
        value: '$test__number',
        headerName: 'NUMER',
        headerCss: 'col-1 m-0 p-0 text-center',
      },
      {
        value: '$test__name',
        headerName: 'NAZWA METODY',
        headerCss: 'col-4 m-0 p-0 text-left',
      },
      {
        value: '$test__norm',
        headerName: 'NORMA',
        headerCss: 'col-3 m-0 p-0 text-center',
      },
      {
        value: '$auth_type',
        headerName: 'TYP UPOWAŻNIENIA',
        headerCss: 'col-2 m-0 p-0 text-center',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-1 m-0 p-0 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            if (this.canUserEditPersonnel === true) {
              this.userTestsAuths = this.userTestsAuths.filter(el => {
                return el['test_id'] !== obj['test_id'];
              });
            }
          },
        },
      },
    ];

    this.toolListParams = [
      {
        headerName: 'NR'.toUpperCase(),
        headerCss: 'col-2 text-center',
        value: '$tool__code_number',
      },
      {
        headerName: 'Nazwa urządzenia'.toUpperCase(),
        headerCss: 'col-5 text-left',
        value: '$tool__name',
      },
      {
        headerName: 'TYP'.toUpperCase(),
        headerCss: 'col-2 text-center',
        value: '$tool__tool_type',
      },
      {
        headerName: 'AKCJE',
        value: 'action',
        headerCss: 'col-2 text-center',
        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [{ text: 'Usuń', css: 'clickable-text' }];
          },
          click: (obj: any, href: any) => {
            this.userToolsAuths = this.userToolsAuths.filter(el => {
              return el['tool_id'] !== obj['tool_id'];
            });
          },
        },
      },
    ];

    this.mainSectionParams = [
      {
        headerName: 'Imię',
        fieldType: 'input',
        fieldName: 'first_name',
        disabled: !this.canUserEditPersonnel,
      },
      {
        fieldType: 'input',
        headerName: 'Nazwisko',
        fieldName: 'last_name',
        disabled: !this.canUserEditPersonnel,
      },
      {
        headerName: 'Numer telefonu',
        fieldType: 'input',
        fieldName: 'phone',
        disabled: !this.canUserEditPersonnel,
        changed: (object, param) => {
          if (!helpers.checkPhoneNumber(object.phone)) {
            object.phone = '';
            helpers.error('Nieprawidłowy numer telefonu');
          }
        },
      },
      {
        headerName: 'Stanowisko',
        fieldType: 'input',
        fieldName: 'position',
        disabled: !this.canUserEditPersonnel,
      },
      {
        value: 'section',
        fieldType: 'multiselect',
        fieldName: 'section',
        disabled: !this.canUserEditPersonnel,
        headerName: 'Sekcja',
        options: this.sections,
        multiSelectTrack: 'value',
        action: (e: any, object: any) => {
          object.section_id = e.id;
          object.section.id = e.id;
          object.section.value = e.value;
          this.getSectionTests();
          this.getSectionTools();
        },
      },
      {
        headerName: 'Status',
        fieldType: 'multiselect',
        disabled: !this.canUserEditPersonnel,
        options: consts.UserStatus.array,
        engine: new StandardMultiselectEngine('status_id', 'id'),
        multiSelectTrack: 'name',
      },
      {
        headerName: 'Adres e-mail',
        fieldType: 'input',
        fieldName: 'email',
        disabled: !this.canUserEditPersonnel,
        changed: (object, param) => {
          if (!helpers.checkMail(object.email)) {
            helpers.error('Nieprawidłowy adres e-mail');
          }
        },
      },
    ];
    this.loaded = true;
  }
}
