










































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import Modal from '../../../components/spec/Modal.vue';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';
import * as API from '../../../store/api';
import * as consts from '../../../consts';
import * as helpers from '../../../helpers';
import { ExtendedParam, ResultsDoc, StandardMultiselectEngine, User } from '../../../types';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
    ModalQuestion,
  },
})
export default class ResultDocsList extends Vue {
  @Ref() readonly modalFilter: Modal;
  params = new Array<ExtendedParam>();
  @Ref() readonly modal: Modal;
  @Ref() readonly questionModal: ModalQuestion;
  @Ref() readonly filterForm: GenericForm;
  @Ref() readonly list: GenericList;
  objects = [];
  users = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  url = '';
  object = {};
  copyfilterObject = {};
  filterObject = {};
  filterParams = new Array<ExtendedParam>();

  actionQuestion = 'Czy na pewno chcesz wykonać tę akcję?';
  questionModalConfirmationAction = () => {
    console.log('No action'); //
  };

  reload() {
    this.list.fillObjects();
    this.list.$forceUpdate();
  }

  clear() {
    this.copyfilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
  }
  showModal(object: any) {
    this.object = object;
    this.modal.showModal();
  }

  sendFilterQuery(e: any, object: any) {
    console.log('OK', this.filterObject, this.copyfilterObject);
    this.filterObject = Object.assign({}, this.copyfilterObject);
  }

  processLoaded(element: any) {
    element.status = consts.ReportStatus.arr[element.status_id];
  }

  redirectToResult(object: any) {
    this.$router.push(`resultsDocPreview/${object.id}`);
  }

  async created() {
    const responseUsers = await API.fetchUsers();
    if (responseUsers.data.success) {
      responseUsers.data.users.forEach(element => {
        const user = Object.assign(new User(), element);
        user.load();
        this.users.push(user);
      });
    }

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'number',
        headerName: 'Numer',
      },
      {
        fieldType: 'input',
        fieldName: 'object_type',
        headerName: 'Typ obiektu',
      },
      {
        fieldType: 'multiselect',
        value: 'status',
        options: consts.ReportStatus.arr.map(el => {
          return { name: el.name, id: el.id };
        }),
        engine: new StandardMultiselectEngine('status_id', 'id'),
        multiSelectTrack: 'name',
        headerName: 'Status',
      },
      {
        fieldType: 'multiselect',
        options: this.users,
        multiSelectTrack: 'firstname',
        //value: 'user_created',
        engine: new StandardMultiselectEngine('user_created_id', 'id'),
        headerName: 'Sporządził',
      },
      {
        fieldType: 'multiselect',
        options: this.users,
        multiSelectTrack: 'firstname',
        value: 'user_accepted',
        engine: new StandardMultiselectEngine('user_accepted_id', 'id'),
        headerName: 'Zaakceptował',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'user_authorised_id',
        options: this.users,
        multiSelectTrack: 'firstname',
        value: 'user_accepted',
        engine: new StandardMultiselectEngine('user_authorised_id', 'id'),
        headerName: 'Zautoryzował',
      },
      {
        fieldType: 'date',
        value: 'date',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.date__range = [dt.format(consts.DB_DATE_FORMAT), dt.add(1, 'day').format(consts.DB_DATE_FORMAT)];
          object.date = undefined;
        },
        headerName: 'Data',
      },
    ];

    this.params = [
      {
        value: '$number',
        headerName: 'NUMER',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        value: '$object_type',
        headerName: 'TYP OBIEKTU',
        headerCss: 'col-1 p-0 ',
      },
      {
        value: '$type',
        headerName: 'RODZAJ',
        headerCss: 'col-1 p-0 ',
      },
      {
        headerName: 'OKRES',
        value: '$period',
      },
      {
        headerName: 'DATA',
        value: (obj: any) => {
          const parsed = moment(obj.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
          return parsed;
        },
      },
      {
        fieldType: 'badge',
        value: '$status__name',
        badgeVariant: '$status__badge',
        sortField: 'status_id',
        headerName: 'STATUS',
      },

      {
        headerName: 'SPORZĄDZIŁ',
        value: (obj: any) => {
          if (obj.user_created) {
            return obj.user_created.first_name + ' ' + obj.user_created.last_name;
          }
          return '';
        },
        sortField: 'user_created__first_name',
      },
      {
        headerName: 'ZAAKCEPTOWAŁ',
        value: (obj: any) => {
          if (obj.user_accepted) {
            return obj.user_accepted.first_name + ' ' + obj.user_accepted.last_name;
          }
          return '';
        },
        sortField: 'user_created__first_name',
      },
      {
        headerName: 'ZAUTORYZOWAŁ',
        value: (obj: any) => {
          if (obj.user_authorised) {
            return obj.user_authorised.first_name + ' ' + obj.user_authorised.last_name;
          }
          return '';
        },
        sortField: 'user_accepted__first_name',
      },
      {
        headerName: 'PDF',
        fieldType: 'icon',
        icon: 'file-pdf',
        size: 'lg',
        value: 'user',
        css: 'pdf-icon',
        action: async (object: any, e: any) => {
          console.log(object);
          this.url = process.env.VUE_APP_API_URL + 'media/' + object.result_file.path.replace('docx', 'pdf');
          await this.$forceUpdate();
          document.getElementById('download').click();
        },
        visible: (obj: any) => obj.status.id === consts.ReportStatus.AUTHORISED.id,
      },
      {
        headerName: 'AKCJE',
        fieldType: 'bdropdown',
        getValues: (obj: any) => {
          return consts.ReportOperation.arr.map(el => {
            return {
              text: el.title,
              css: 'font-12',
              info: el,
              action: (obj: ResultsDoc, param: any) => {
                const info = param.info;
                if (consts.ReportOperation.DELETE.id === info.id) {
                  this.actionQuestion = 'Czy na pewno chcesz usunąć to sprawozdanie?';
                  this.questionModalConfirmationAction = async () => {
                    const r1 = await API.deleteResultsDoc(obj.id);
                    if (r1) {
                      helpers.requestSucess();
                    } else {
                      helpers.error('Wystąpił błąd po stronie serwera.');
                    }
                    await this.reload();
                  };
                  this.questionModal.showModal({});
                } else if (consts.ReportOperation.CLOSE.id === obj.id) {
                  obj.status_id = consts.ReportStatus.CLOSED.id;
                }
              },
            };
          });
        },
        value: '',
      },
    ];
  }

  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      setTimeout(() => {
        this.filterObject = { ...this.$route.query };
      }, 1000);
    }
  }
}
