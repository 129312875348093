




























import { Component, Vue, Ref } from 'vue-property-decorator';
import * as API from '../../store/api';
import TopPanel from '../../components/dummy/TopPanel.vue';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import GenericForm from '../../components/spec/GenericForm.vue';
import GenericList from '../../components/spec/GenericList.vue';
import Modal from '../../components/spec/Modal.vue';
import * as consts from '../../consts';
import * as types from '../../types';
import * as helpers from '../../helpers';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class Admin extends Vue {
  addActivity() {
    const activity = {
      user_id: this.$store.state.currentUser.id,
      name: 'Aktywność',
      type: 1,
      subtype: 2,
      symbol: '',
      value_str: '',
      value_int: 0,
    };
    API.addActivity(activity)
      .catch(response => {
        console.log(response);
        helpers.error('Błąd przy komunikacji z serwerem');
      })
      .then(response => {
        console.log(response);
      });
  }

  // po dodaniu swojego skryptu doloz kolejny button aby kolejna osoba miala prosciej
  // zawsze pamietaj zeby skrypty pisac tak, zeby dalo sie je wielokrotnie odpalac tj. dodanie wiersza sprawdzalo
  // czy istnieje juz taki w bazie
  fireScript(script_name) {
    API.fireScript(script_name)
      .then(response => {
        if (response.status === 201) {
          helpers.info('Skrypt wykonany poprawnie, komunikat: ' + response.data.status);
        }
      })
      .catch(error => {
        console.log(error);
        helpers.error('Błąd przy komunikacji z serwerem: ');
      });
  }
}
