





































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import * as API from '../../../store/api';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import GenericField from '../../../components/spec/GenericField.vue';
import Modal from '../../../components/spec/Modal.vue';
import * as helpers from '../../../helpers';
import { ExtendedParam, StandardMultiselectEngine } from '../../../types';
import * as consts from '../../../consts';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    GenericField,
    Header,
    Modal,
    Footer,
  },
})
export default class StructureList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly structureModal: Modal;
  @Ref() readonly list: GenericList;
  @Ref() filterForm: GenericForm;
  @Ref() relationForm: GenericForm;

  reload() {
    this.list.fillObjects();
  }
  params = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  objectCopy = {};
  filterObject = {};
  copyFilterObject = {};
  url = '';
  filterParams = new Array<ExtendedParam>();
  spinner = false;
  structures = [];
  dicts = [];
  dictObjects = [];
  dictObjectTypes = [];
  dictSources = [];
  dictTakeMethods = [];
  dictTakePlaces = [];
  dictPrepareNorms = [];
  dictRecalculateNorms = [];
  dictTestAreas = [];
  structureModalTitle = '';
  dictPrepareNormID = '';
  dictRecalculateNormID = '';
  dictAreaID = '';
  objects = [];

  async loadDicts() {
    console.log('Loading dicts');
    const response = await API.fetchDicts();
    this.dicts = response.data.dicts;
    this.dictObjects = this.dicts.filter(d => d.type === consts.DictType.OBJECT);
    this.dictObjectTypes = this.dicts.filter(d => d.type === consts.DictType.OBJECT_TYPE);
    this.dictSources = this.dicts.filter(d => d.type === consts.DictType.SOURCE);
    this.dictTakeMethods = this.dicts.filter(d => d.type === consts.DictType.TAKE_METHOD);
    this.dictTakePlaces = this.dicts.filter(d => d.type === consts.DictType.TAKE_PLACE);
    this.dictPrepareNorms = this.dicts.filter(d => d.type === consts.DictType.PREPARE_NORM);
    this.dictRecalculateNorms = this.dicts.filter(d => d.type === consts.DictType.CALC_NORM);
    this.dictTestAreas = this.dicts.filter(d => d.type === consts.DictType.AREA);
    console.log('Dicts loaded..');
  }

  async openFilterModal() {
    await this.modalFilter.showModal();
  }

  async showStructureModal(object: any = {}) {
    console.log('show object', object);
    if (object && 'id' in object) {
      this.structureModalTitle = 'Edytuj pozycję struktury';
    } else {
      this.structureModalTitle = 'Dodaj nową pozycję struktury';
    }
    this.objectCopy = Object.assign({}, object);
    this.structureModal.showModal();
  }

  async saveStructure() {
    const response = await API.fetchStructures();
    this.structures = response.data.structures;
    const optionalValues = ['prepare_norm_id', 'recalculate_norm_id', 'area_test_id'];
    optionalValues.forEach(v => {
      if (!(v in this.objectCopy)) {
        this.objectCopy[v] = null;
      }
    });
    if (!('active' in this.objectCopy)) {
      this.objectCopy['active'] = false;
    }
    const identicalStructure = this.structures.filter(
      s =>
        s.object_id === this.objectCopy['object_id'] &&
        s.object_type_id === this.objectCopy['object_type_id'] &&
        s.source_id === this.objectCopy['source_id'] &&
        s.take_method_id === this.objectCopy['take_method_id'] &&
        s.take_place_id === this.objectCopy['take_place_id'] &&
        s.prepare_norm_id === this.objectCopy['prepare_norm_id'] &&
        s.recalculate_norm_id === this.objectCopy['recalculate_norm_id'] &&
        s.area_test_id === this.objectCopy['area_test_id']
    );
    if (identicalStructure.length > 0 && identicalStructure[0].id !== this.objectCopy['id']) {
      helpers.error('Istnieje już identyczna pozycja struktury!');
    } else {
      if (
        !('object_id' in this.objectCopy) ||
        !('object_type_id' in this.objectCopy) ||
        !('source_id' in this.objectCopy) ||
        !('take_method_id' in this.objectCopy) ||
        !('take_place_id' in this.objectCopy)
      ) {
        helpers.error('Należy wypełnić wszystkie pola oznaczone gwiazdką');
      } else {
        console.log('obj to save', this.objectCopy);
        const response = await API.saveStructure(this.objectCopy);
        if (response.data.success) {
          helpers.info('Pomyślnie zapisano');
          this.list.fillObjects();
        } else {
          helpers.error('Wystąpił nieoczekiwany błąd po stronie serwera');
        }
        this.structureModal.hideModal();
        this.objectCopy = null;
      }
    }
  }

  clear() {
    this.copyFilterObject = {};
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  sendFilterQuery(e: any) {
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async setup() {
    this.reload();
  }

  async created() {
    await this.loadDicts();

    this.filterParams = [
      {
        fieldType: 'multiselect',
        options: this.dictObjects,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('object_id', 'id'),
        headerName: 'Obiekt',
      },
      {
        fieldType: 'multiselect',
        options: this.dictObjectTypes,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('object_type_id', 'id'),
        headerName: 'Typ obiektu',
      },
      {
        fieldType: 'multiselect',
        options: this.dictSources,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('source_id', 'id'),
        headerName: 'Pochodzenie',
      },
      {
        fieldType: 'multiselect',
        options: this.dictTakeMethods,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('take_method_id', 'id'),
        headerName: 'Metoda pobrania',
      },
      {
        fieldType: 'multiselect',
        options: this.dictTakePlaces,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('take_place_id', 'id'),
        headerName: 'Miejsce pobrania',
      },
      {
        fieldType: 'multiselect',
        options: this.dictPrepareNorms,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('prepare_norm_id', 'id'),
        headerName: 'Norma przygotowania',
      },
      {
        fieldType: 'multiselect',
        options: this.dictRecalculateNorms,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('recalculate_norm_id', 'id'),
        headerName: 'Norma przeliczenia',
      },
      {
        fieldType: 'multiselect',
        options: this.dictTestAreas,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('area_test_id', 'id'),
        headerName: 'Obszar badań',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'active',
        options: [
          { name: 'TAK', id: 1 },
          { name: 'NIE', id: 0 },
        ],
        multiSelectTrack: 'name',
        action: (e: any, object: any) => {
          object.active = e.id;
        },
        headerName: 'Aktywny',
      },
    ];

    this.params = [
      {
        headerName: 'OBIEKT',
        value: '$object__value',
        sortField: '$object__value',
        columnCss: 'col-1 p-0 text-left font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'TYP OBIEKTU  ',
        value: '$object_type__value',
        sortField: '$object_type__value',
        columnCss: 'col-1 p-0 text-left font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'POCHODZENIE',
        value: '$source__value',
        sortField: '$source__value',
        columnCss: 'col-1 p-0 text-left font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'METODA POBRANIA',
        value: '$take_method__value',
        sortField: '$take_method__value',
        columnCss: 'col-1 p-0 text-left font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'MIEJSCE POBRANIA',
        value: '$take_place__value',
        sortField: '$take_place__value',
        columnCss: 'col-1 p-0 text-left font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'NORMA PRZYGOTOWANIA',
        value: '$prepare_norm__value',
        sortField: '$prepare_norm__value',
        columnCss: 'col-1 p-0 text-left font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'NORMA PRZELICZENIA',
        value: '$recalculate_norm__value',
        sortField: '$recalculate_norm__value',
        columnCss: 'col-1 p-0 text-left font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'OBSZAR BADAŃ',
        value: '$area_test__value',
        sortField: '$area_test__value',
        columnCss: 'col-2 p-0 text-left font-16',
        headerCss: 'col-2 p-0 text-center',
      },

      {
        headerName: 'Aktywny',
        fieldType: 'boolean',
        value: 'active',
        columnCss: 'col-1 p-0 text-center font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'Operacje',
        fieldType: 'bdropdown',
        getValues: (obj: any) => {
          return [
            {
              css: 'font-12',
              text: 'Usuń',
              action: async (obj: any) => {
                await API.deleteObjectModelById(obj.id, 'Structure');
                await this.setup();
                this.$forceUpdate();
              },
            },
          ];
        },
      },
    ];

    this.modalParams = [
      {
        fieldType: 'multiselect',
        options: this.dictObjects,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('object_id', 'id'),
        headerName: 'Obiekt *',
      },
      {
        fieldType: 'multiselect',
        options: this.dictObjectTypes,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('object_type_id', 'id'),
        headerName: 'Typ obiektu *',
      },
      {
        fieldType: 'multiselect',
        options: this.dictSources,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('source_id', 'id'),
        headerName: 'Pochodzenie *',
      },
      {
        fieldType: 'multiselect',
        options: this.dictTakeMethods,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('take_method_id', 'id'),
        headerName: 'Metoda pobrania *',
      },
      {
        fieldType: 'multiselect',
        options: this.dictTakePlaces,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('take_place_id', 'id'),
        headerName: 'Miejsce pobrania *',
      },
      {
        fieldType: 'multiselect',
        options: this.dictPrepareNorms,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('prepare_norm_id', 'id'),
        headerName: 'Norma przygotowania',
      },
      {
        fieldType: 'multiselect',
        options: this.dictRecalculateNorms,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('recalculate_norm_id', 'id'),
        headerName: 'Norma przeliczenia',
      },
      {
        fieldType: 'multiselect',
        options: this.dictTestAreas,
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('area_test_id', 'id'),
        headerName: 'Obszar badań',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'checkbox',
        fieldName: 'active',
        mainCss: 'mt-2',
      },
    ];
  }
}
