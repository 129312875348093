












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus, BusEvents } from '../../helpers/eventbus';
import * as consts from '../../consts';

const NOT_CHOOSEN = 100;

@Component
export default class Modal extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ default: 'Anuluj' }) readonly buttonCancelText: string;
  @Prop({ default: 'Zapisz' }) readonly buttonOKText: string;
  @Prop({ type: String, required: false, default: 'lg' }) size;
  @Prop({ type: Boolean, required: false, default: false }) backClose;
  @Prop({ type: Boolean, required: false, default: false }) okOnly;
  @Prop({ type: Boolean, required: false, default: true }) okVisible;
  @Prop({ type: String, required: false, default: 'success' }) okVariant;
  @Prop({ type: Boolean, required: false, default: false }) disabledOkButton;
  @Prop({ type: Boolean, required: false, default: true }) ifValid;
  @Prop({ type: Boolean, required: false, default: true }) hideOnSave;
  @Prop({ type: Number, required: false }) widthPercentage;

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  showModal(params = {}) {
    this.show = true;
    this.params = params;
    console.log('Params', this.params);
  }
  clear() {
    this.$emit('CLEAR');
  }
  saveModal() {
    // console.log('value 1', this.ifValid);
    this.$root.$on('ifValue', value => {
      this.ifValid = value;
      // console.log('emit received')
    });
    this.save = true;
    this.$emit('SAVE', this.params);
    this.btnSave++;
    if (this.hideOnSave && this.ifValid === true) {
      // console.log('valid true show false', this.ifValid);
      this.show = false;
    } else {
      this.ifValid = true;
      // console.log('false, change to true', this.ifValid);
    }
    this.$root.$on('ifValue', value => {
      this.ifValid = value;
      // console.log('value after save', this.ifValid);
    });
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
