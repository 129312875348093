





































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericList from '@/components/spec/GenericList.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as consts from '@/consts';
import * as helpers from '@/helpers';
import { ExtendedParam, StandardMultiselectEngine } from '@/types';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class EnvConditionList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly modal: Modal;
  @Ref() readonly list: GenericList;
  params = [];
  objects = [];
  modalParams = new Array<any>();
  modalEdit = new Array<any>();
  show = true;
  object = {};
  modalObject: any = {};
  filterObject = {};
  copyFilterObject = {};
  url = '';
  dictArray = [];
  filterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;

  processLoadedElement(object: any) {
    console.log(object);
  }

  get user() {
    return this.$store.state.currentUser;
  }

  async showModal(object: any) {
    console.log('showModal', object);
    this.modalObject = Object.assign({}, object);
    this.modal.showModal();
  }

  async saveModal(e: any) {
    this.modalObject.user_created_id = this.user.id;

    console.log('test', this.modalObject);
    const response = await API.saveEnvCondition(this.modalObject);

    if (response.data.success) {
      this.list.fillObjects();
      helpers.info('Pomyślnie zapisano');
    }
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, e);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  filterDicts(type: number) {
    const newDict = [];
    for (const dict of this.dictArray) {
      if (dict.type === type) {
        newDict.push(dict);
      }
    }
    return newDict;
  }

  filterRoom() {
    for (const param of this.modalParams) {
      if (param.headerName === 'Nazwa i numer pomieszczenia') {
        param.options = this.filterDicts(12);
        const newRoom = [];
        for (const room of param.options) {
          if (this.modalObject.section_id && room.related_id === this.modalObject.section_id) {
            newRoom.push(room);
          }
        }
        param.options = [...newRoom];
        break;
      }
    }
  }
  async created() {
    const response = await API.fetchModel('EnvCondition');
    const allToolGroups = response.data.objs.map(el => {
      return { id: el.id, name: el.name };
    });

    const dictResponse = await API.fetchDicts();
    this.dictArray = dictResponse.data.dicts;
    // -----------------------------------------------------------
    this.modalParams = [
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        action: (e, object) => {
          object.section_id = e.id;
          this.filterRoom();
        },
        load: (obj, param) => {
          return param.options.find(el => {
            return el.id === obj.section_id;
          });
        },
        options: await helpers.getThreeSections(),
        multiSelectTrack: 'value',
        headerName: 'Sekcja',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: this.filterDicts(12),
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('name_and_room_number_id'),
        headerName: 'Nazwa i numer pomieszczenia',
      },
      {
        fieldType: 'input',
        fieldName: 'temperature',
        headerName: 'Temperatura',
      },
      {
        fieldType: 'input',
        fieldName: 'humidity',
        headerName: 'Wilgotność',
      },
    ];
    this.filterParams = [
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: this.filterDicts(3),
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('section_id'),
        headerName: 'Sekcja',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: this.filterDicts(12),
        multiSelectTrack: 'value',
        engine: new StandardMultiselectEngine('name_and_room_number_id'),
        headerName: 'Nazwa i numer pomieszczenia',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: this.filterDicts(12),
        multiSelectTrack: 'value2',
        engine: new StandardMultiselectEngine('name_and_room_number_id'),
        headerName: 'Numer termohigrometru',
      },
      {
        fieldType: 'date',
        value: 'changed_date_time',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.changed_date_time__range = [
            dt.format(consts.DB_DATE_FORMAT),
            dt.add(1, 'day').format(consts.DB_DATE_FORMAT),
          ];
          object.changed_date_time = undefined;
        },
        headerName: 'Data',
      },
      {
        fieldType: 'multiselect',
        options: await helpers.getModelAsArray('UserData').then(result =>
          result.map(el => {
            el.fullName = el.user.first_name + ' ' + el.user.last_name;
            return el;
          })
        ),
        multiSelectTrack: 'fullName',
        action: (e, object) => {
          object.user_created_id = e.user.id;
        },
        headerName: 'Wpisał',
      },
    ];

    this.params = [
      {
        value: '$section__value',
        headerName: 'NAZWA SEKCJI',
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0',
      },
      {
        value: '$name_and_room_number__value',
        headerName: 'NAZWA I NR POMIESZCZENIA',
        columnCss: 'col-3 p-0',
        headerCss: 'col-3  p-0',
      },
      {
        value: '$name_and_room_number__value2',
        headerName: 'NUMER TERMOHIGROMETRU',
        columnCss: 'col-2 p-0',
        headerCss: 'col-2  p-0',
      },
      {
        headerName: 'DATA',
        value: '$changed_date_time',
        evaluate: (obj: any) => {
          return moment(obj.changed_date_time).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0',
      },
      {
        headerName: 'GODZINA',
        value: '$changed_date_time',
        evaluate: (obj: any) => {
          return moment(obj.changed_date_time).format(consts.TIME_FORMAT).toString();
        },
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0',
      },
      {
        value: '$temperature',
        headerName: 'TEMPERATURA',
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0',
      },
      {
        value: '$humidity',
        headerName: 'WILGOTNOŚĆ',
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0',
      },
      {
        value: (object, param) => {
          const user = object.user_created;
          return user.first_name + ' ' + user.last_name;
        },
        headerName: 'WPISAŁ',
        sortField: 'user_created__first_name',
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0',
      },
    ];
  }
}
