






























































import { Component, Vue, Ref, Prop, PropSync } from 'vue-property-decorator';
import moment from 'moment';
import * as consts from '../../consts';
import TimeInput from '../../components/dummy/TimeInput.vue';
import DateInput from '../../components/dummy/DateInput.vue';

@Component({
  components: {
    DateInput,
    TimeInput,
  },
})
export default class RailDeliveryForm extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) enabled;
  @Prop({ type: String, required: false, default: '' }) objectValue;
  @PropSync('mineName', { type: String }) mineNameSync!: string;
  @PropSync('declaredClass', { type: String }) declaredClassSync!: string;
  @PropSync('wagonNumber', { type: String }) wagonNumberSync!: string;
  @PropSync('arrivalDatetime', { type: String }) arrivalDatetimeSync!: string;
  @PropSync('postingDate', { type: String }) postingDateSync!: string;
  @Ref() readonly arrivalDateInput: DateInput;
  @Ref() readonly postingDateInput: DateInput;
  @Ref() readonly arrivalTimeInput: TimeInput;

  arrivalDate: string;
  arrivalTime: string;
  labelMineName: string;
  labelClass: string;
  coal = true;

  setArrivalDateTime() {
    if (this.arrivalDate === undefined) {
      return;
    }
    if (this.arrivalTime === undefined) {
      return (this.arrivalDatetimeSync = this.arrivalDate);
    }
    return (this.arrivalDatetimeSync = `${this.datetimeToDate(this.arrivalDate)} ${this.arrivalTime}`);
  }

  datetimeToDate(datetime: string) {
    const date = moment(datetime, consts.DB_DATE_TIME_FORMAT).format(consts.DB_DATE_FORMAT);
    return date;
  }

  created() {
    if (this.objectValue === consts.Objects.BIOMASA) {
      this.coal = false;
      this.labelMineName = 'Dostawca:';
      this.labelClass = 'Identyfikator próbki:';
    } else {
      this.labelMineName = 'Nazwa kopalni:';
      this.labelClass = 'Klasa deklarowana:';
    }
  }

  mounted() {
    if (this.arrivalDatetimeSync != undefined) {
      const momentDate = moment(this.arrivalDatetimeSync, consts.DB_DATE_TIME_FORMAT);
      const time = momentDate.format(consts.TIME_FORMAT);
      this.arrivalTimeInput.value = time;
      this.arrivalDateInput.dateText = this.arrivalDatetimeSync;
    }
    if (this.postingDateSync != undefined) {
      this.postingDateInput.dateText = this.postingDateSync;
    }
  }
}
