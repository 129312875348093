



















































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import * as API from '../../store/api';
import moment from 'moment';
import * as consts from '../../consts';
import * as types from '../../types';
import TopPanel from '../../components/dummy/TopPanel.vue';
import TimeInput from '../../components/dummy/TimeInput.vue';
import * as helpers from '../../helpers';
import Modal from '../../components/spec/Modal.vue';
import ModalQuestion from '../../components/dummy/ModalQuestion.vue';
import ModalRailDelivery from '../../components/spec/ModalRailDelivery.vue';
import DateInput from '../../components/dummy/DateInput.vue';
import MinimalGenericList from '../..//components/spec/MinimalGenericList.vue';
import RailDeliveryForm from '../../components/spec/RailDeliveryForm.vue';
import GenericForm from '../../components/spec/GenericForm.vue';

const ENEA_CLIENT_ID = 1;

@Component({
  components: {
    TopPanel,
    TimeInput,
    Modal,
    GenericForm,
    DateInput,
    MinimalGenericList,
    ModalRailDelivery,
    RailDeliveryForm,
    ModalQuestion,
  },
})
export default class Register extends Vue {
  @Ref() readonly modal: Modal;
  @Ref() readonly timeModal: Modal;
  @Ref() readonly railDeliveryModal: Modal;
  @Ref() readonly resetTime: TimeInput;
  @Ref() readonly resetDate: DateInput;
  @Ref() readonly questionModal: ModalQuestion;

  user = null;
  chosenRegistration = consts.ClientType.INTERNAL;
  saveKey = 0;
  enabled = true;
  takeVisitDate = '';
  takeDateText = '';
  takeTime = '';
  timeInvalidate = 1;
  description = '';
  ptNumber = '';

  dicts = [];
  activeDictIds = [];

  timeObject: any = {};
  BASIC_PACKAGE_NAME = 'Pakiet podstawowy';

  isPackageAnalysisShown = false;

  numberWithName({ number, name }) {
    return `${number} — [${name}]`;
  }

  /********************* DATE & TIME **********************/
  timeFormParams = [];
  timeObjects = [];
  listDisplay = [];
  get inter() {
    return consts.ClientType.INTERNAL;
  }
  get exter() {
    return consts.ClientType.EXTERNAL;
  }
  get lastDate() {
    if (this.timeObjects.length < 2) {
      return null;
    } else return this.getSortedDates[this.timeObjects.length - 1];
  }

  get firstDate() {
    console.log(this.timeObjects[0]);
    if (this.timeObjects.length === 0) {
      return null;
    }
    return this.getSortedDates[0];
  }

  get getSortedDates() {
    let dts = [];
    this.timeObjects.forEach(el => {
      if (el.date_from) {
        dts.push({ parent: el, date: el.date_from });
      }
      if (el.date_to) {
        dts.push({ parent: el, date: el.date_to });
      }
    });
    dts = dts.sort((a, b) => {
      const diff = moment(a.date, consts.DB_DATE_TIME_FORMAT).diff(moment(b.date, consts.DB_DATE_TIME_FORMAT));
      const secs = moment.duration(diff).asSeconds();
      return secs;
    });
    return dts;
  }

  addTimeObject() {
    if (!this.timeObject.time_from) {
      this.timeObject.time_from = '00:00';
    }
    if (!this.timeObject.time_to) {
      this.timeObject.time_to = '00:00';
    }
    const obj = Object.assign({}, this.timeObject);
    console.log('OBJ =', obj);
    obj.localId =
      this.timeObjects.length === 0
        ? 0
        : Math.max(
            ...this.timeObjects.map(el => {
              return el['id'];
            })
          ) + 1;

    this.timeObjects.push(obj);
    this.timeObject = {};
    this.timeModal.$forceUpdate();

    for (let i = 1; i <= 4; i++) {
      console.log('REF=', i, this.$refs['reset' + i]);
      if (this.$refs['reset' + i]) {
        (this.$refs['reset' + i] as any).reset();
      }
    }

    this.$forceUpdate();
  }

  formatDate(date: string) {
    return moment(date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
  }

  /********************* OBJECT **********************/
  objectKey = 0;
  showAdditional = false;
  selectedObject: any = {};
  get objectValue() {
    return this.selectedObject;
  }

  get objects() {
    return this.dicts ? this.dicts.filter(d => d.type === consts.DictType.OBJECT) : [];
  }

  selectObject(e: KeyboardEvent) {
    console.log(
      'objectSelected',
      e,
      e['id'],
      this.structure,
      this.structure.filter(s => {
        // console.log('S', s.object_id, ' ? ', e['id']);
        return +s.object.id === +e['id'];
      })
    );
    this.selectedObject = e;
    if (consts.Objects.BIOMASA === e['value']) {
      this.packageValue = this.packages.find(o => o.name.toLowerCase() === consts.PackageName.BIOMASA.toLowerCase());
    }
    this.sources = this.structure
      .filter(s => s.object.id === e['id'])
      .map(s => {
        return { id: s.source.id, value: s.source.value, object: e };
      });
    this.sources = [...new Map(this.sources.map(item => [item['value'], item])).values()];
    console.log('SOURCES', this.sources);
    this.selectSource(this.sources[0]);
    this.objectAnalysis = this.analysis.filter(a => a.object === e['id']);
    this.analyseValue = this.objectAnalysis[0];
  }

  /********************* IF BIOMASS INTERNAL - BIOMASS SOURCE **********************/

  biomassSourceValuesList = [
    consts.DocBiomassNames.F11_1,
    consts.DocBiomassNames.F11_2,
    consts.DocBiomassNames.F11_3,
    consts.DocBiomassNames.F11_4,
    consts.DocBiomassNames.F11_5,
    // consts.DocBiomassNames.F11_6, // probki importowane
    consts.DocBiomassNames.F11_7,
    consts.DocBiomassNames.F11_8,
    // consts.DocBiomassNames.F11_9, // probki importowane
    consts.DocBiomassNames.F11_10,
    consts.DocBiomassNames.F11_11,
  ];
  biomassSourceSymbolsList = [
    consts.DocSymbols.F11_1,
    consts.DocSymbols.F11_2,
    consts.DocSymbols.F11_3,
    consts.DocSymbols.F11_4,
    consts.DocSymbols.F11_5,
    // consts.DocSymbols.F11_6,
    consts.DocSymbols.F11_7,
    consts.DocSymbols.F11_8,
    // consts.DocSymbols.F11_9,
    consts.DocSymbols.F11_10,
    consts.DocSymbols.F11_11,
  ];
  get biomassSourceValues() {
    let values = [];
    for (let i = 0; i < this.biomassSourceValuesList.length; i++) {
      values.push({
        id: i + 1,
        value: this.biomassSourceValuesList[i],
        symbol: this.biomassSourceSymbolsList[i],
      });
    }
    values = values.sort((a, b) => a.value.localeCompare(b.value));
    return values;
  }

  selectedBiomassSource: any = {};
  get biomassSourceValue() {
    return this.selectedBiomassSource;
  }
  selectBiomassSource(e: KeyboardEvent) {
    this.selectedBiomassSource = e;
  }

  /********************* SOURCE **********************/
  sources = [];
  selectedSource: any = {};
  get sourceValue() {
    return this.selectedSource;
  }
  selectSource(e: KeyboardEvent) {
    console.log('sourceSelected', e);
    this.selectedSource = e;
    if ([consts.Sources.DOSTAWY_K, consts.Sources.NAWEGL].includes(e['value'])) {
      if (e['value'] === consts.Sources.DOSTAWY_K) {
        if (this.objectValue.value === consts.Objects.BIOMASA) {
          this.packageValue = this.packages.find(
            o => o.name.toLowerCase() === consts.PackageName.BIOMASA.toLowerCase()
          );
        } else {
          this.packageValue = this.packages.find(o => o.name.toLowerCase().includes(consts.PackageName.DOSTAWY_K));
        }
      } else {
        this.packageValue = this.packages.find(o => o.name.toLowerCase().includes(consts.PackageName.NAWEGL));
      }
    }
    console.log(
      'STRUCTURE mapped',
      this.structure.map(s => {
        return [
          s.object,
          s.source,
          s.object_type,
          s.take_method,
          s.take_place,
          s.area_test,
          s.prepare_norm,
          s.recalculate_norm,
        ];
      })
    );
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      this.objectTypes = this.structure
        .filter(s => {
          if (s.source && s.object_type) {
            return s.source.id === e['id'] && s.object.id === e['object']['id'] && s.object_type_id !== undefined;
          } else {
            return false;
          }
        })
        .map(s => {
          return { id: s.object_type.id, value: s.object_type.value, object: e['object'], source: e };
        });
    } else {
      this.objectTypes = this.structure
        .filter(s => {
          if (s.object_type) {
            return s.object.id === e['object']['id'] && s.object_type_id !== undefined;
          } else {
            return false;
          }
        })
        .map(s => {
          return { id: s.object_type.id, value: s.object_type.value, object: e['object'] };
        });
    }
    this.objectTypes = [...new Map(this.objectTypes.map(item => [item['value'], item])).values()];
    this.selectObjectType(this.objectTypes[0]);
    this.fillAreas(e);
    this.fillNorms(e);
  }

  /********************* OBJECT TYPE **********************/
  objectTypes = [];
  selectedObjectType: any = {};
  get objectTypeValue() {
    return this.selectedObjectType;
  }
  selectObjectType(e: KeyboardEvent) {
    console.log('Selected object type', e);
    this.selectedObjectType = e;
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      this.takeMethods = this.structure
        .filter(s => {
          return (
            s.object_type_id === e['id'] &&
            s.source.id === e['source']['id'] &&
            s.object.id === e['object']['id'] &&
            s.take_method !== undefined &&
            s.take_method_id !== undefined
          );
        })
        .map(s => {
          console.log('s2=', s);

          return {
            id: s.take_method_id,
            value: s.take_method.value,
            object: e['object'],
            source: e['source'],
            object_type: e,
          };
        });
      this.takeMethods = [...new Map(this.takeMethods.map(item => [item['value'], item])).values()];
      this.selectedTakeMethod = [];
      this.selectTakeMethod(this.takeMethods[0]);
    }
  }

  /********************* TAKE METHOD **********************/
  takeMethods = [];
  selectedTakeMethod = [];
  get takeMethodValue() {
    return this.selectedTakeMethod;
  }
  selectTakeMethod(e: KeyboardEvent) {
    console.log('select take method', e);
    this.selectedTakeMethod.push(e);
    this.takePlaces = this.structure
      .filter(s => {
        if (s.take_method_id && s.object_type && s.take_place) {
          return (
            s.take_method_id === e['id'] &&
            s.object_type.id === e['object_type']['id'] &&
            s.source.id === e['source']['id'] &&
            s.object.id === e['object']['id'] &&
            s.take_place !== undefined &&
            s.take_place_id !== undefined
          );
        } else {
          return false;
        }
      })
      .map(s => {
        return {
          id: s.take_place_id,
          value: s.take_place.value,
          object: e['object'],
          source: e['source'],
          object_type: e['object_type'],
          object_method: e,
        };
      });
    this.takePlaces = [...new Map(this.takePlaces.map(item => [item['value'], item])).values()];
    this.selectedTakePlaces = [];
    this.selectedTakePlace = {};
    this.selectTakePlaces(this.takePlaces[0]);
    this.selectTakePlace(this.takePlaces[0]);
  }

  /********************* TAKE PLACE **********************/
  takePlaces = [];
  selectedTakePlaces = [];
  selectedTakePlace = {};
  get takePlaceValue() {
    if (this.isFurnaceWaste === true) {
      return this.selectedTakePlace;
    } else {
      return this.selectedTakePlaces;
    }
  }
  selectTakePlaces(takePlace: any) {
    console.log('Select take place', takePlace);
    // this.takePlaces.push(takePlace);
    this.selectedTakePlaces.push(takePlace);
  }
  selectTakePlace(takePlace: any) {
    this.selectedTakePlaces = [];
    console.log('Select take place', takePlace);
    this.selectedTakePlace = takePlace;
    this.selectedTakePlaces.push(takePlace);
  }

  /********************* RAIL DELIVERY **********************/
  mineName = '';
  declaredClass = '';
  arrivalDatetime = '';
  postingDate = '';
  wagonNumber = '';

  get isRailDelivery() {
    return (
      this.chosenRegistration === consts.ClientType.INTERNAL &&
      (this.objectValue.value === consts.Objects.WEGIEL || this.objectValue.value === consts.Objects.BIOMASA) &&
      this.sourceValue.value === consts.Sources.DOSTAWY_K
    );
  }

  get isBiomass() {
    return this.chosenRegistration === consts.ClientType.INTERNAL && this.objectValue.value === consts.Objects.BIOMASA;
  }

  get isFurnaceWaste() {
    return (
      this.chosenRegistration === consts.ClientType.INTERNAL && this.objectValue.value === consts.Objects.ODPAD_PAL
    );
  }

  get isCarDelivery() {
    return this.isBiomass === true && this.sourceValue.value === consts.Sources.DOSTAWY_S;
  }

  get isNumberSamplesManual() {
    return (
      this.sourceValue.value === consts.Sources.NAWEGL &&
      this.selectedTakeMethod.filter(tm => tm.value === consts.TakeMethods.M109).length > 0
    );
  }

  get isAdditional() {
    return this.additionalExamValue.name === 1;
  }

  get areRailDeliveryFieldsEmpty() {
    if (this.objectValue.value === consts.Objects.BIOMASA) {
      return this.mineName === '';
    } else {
      return (
        this.mineName === '' ||
        this.declaredClass === '' ||
        this.arrivalDatetime === '' ||
        this.postingDate === '' ||
        this.wagonNumber === ''
      );
    }
  }

  /********************* AREA **********************/
  selectedArea = {};
  areas = [];
  get areaValue() {
    return this.selectedArea;
  }
  selectArea(e: KeyboardEvent) {
    console.log('selectArea', e);
    this.selectedArea = e;
  }
  fillAreas(e: KeyboardEvent = null) {
    console.log('FillAreas', e);
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      this.areas = this.structure
        .filter(s => s.source.id === e['id'] && s.object.id === e['object']['id'] && s.area_test)
        .map(s => {
          return {
            id: s.area_test.id,
            value: s.area_test.value,
            object: e['object'],
            source: e,
          };
        });
      this.areas = [...new Map(this.areas.map(item => [item['value'], item])).values()];
    } else {
      this.areas = [
        { id: 1, value: 'Regulowany' },
        { id: 2, value: 'NIE-regulowany' },
      ];
    }
    this.selectArea(this.areas[0]);
  }
  /********************* NORMS **********************/
  prepareNorm = {};
  prepareNorms = [];
  recalculateNorm = {};
  recalculateNorms = [];
  fillNorms(e: KeyboardEvent = null) {
    console.log('FillNorms', e);
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      this.prepareNorms = this.structure
        .filter(s => s.source.id === e['id'] && s.object.id === e['object']['id'] && s.prepare_norm)
        .map(s => {
          return {
            id: s.prepare_norm.id,
            value: s.prepare_norm.value,
            object: e['object'],
            source: e,
          };
        });
      this.prepareNorms = [...new Map(this.prepareNorms.map(item => [item['value'], item])).values()];

      this.recalculateNorms = this.structure
        .filter(s => s.source.id === e['id'] && s.object.id === e['object']['id'] && s.recalculate_norm)
        .map(s => {
          return {
            id: s.recalculate_norm.id,
            value: s.recalculate_norm.value,
            object: e['object'],
            source: e,
          };
        });
      this.recalculateNorms = [...new Map(this.recalculateNorms.map(item => [item['value'], item])).values()];
    }
  }

  clientValue = null;
  clients = [];
  analysis = [];
  objectAnalysis = [];
  analyseValue = null;
  choosenAnalysis = [];

  packages = [];
  packageValue = null;
  choosenPackage = null;

  additionalOptions = [
    { name: 0, text: 'NIE' },
    { name: 1, text: 'TAK' },
  ];
  additionalExamValue = this.additionalOptions[0];

  takePersons = [
    { name: 'E', text: 'PRYM-SOFT Sp. z o.o.' },
    { name: 'K', text: 'Klient' },
  ];
  takePerson = this.takePersons[1];

  implementationBaseString = 'Podstawa realizacji';
  takePersonString = 'Pobierający próbki';
  takeNormString = 'Numer protokołu pobierania';
  takePlaceString = 'Miejsce pobrania';
  takeMethodString = 'Metoda pobrania';
  numberSamplesManual = '';

  disabledAnalysis = false;
  BASIC_PACKAGE_ID = 1;
  packageSelected(e: any) {
    this.disabledAnalysis = e.id === this.BASIC_PACKAGE_ID ? true : false;
    console.log(this.disabledAnalysis);
    this.packageValue = this.packages.find(o => o.id === e.id);
  }
  analyseSelected(e: any) {
    this.analyseValue = this.analysis.find(o => o.id === e.id);
  }
  additionalSelected(e: any) {
    this.additionalExamValue = this.additionalOptions.find(o => o.name === e.name);
  }
  takePersonSelected(e: any) {
    this.takePerson = this.takePersons.find(p => p.name === e.name);
  }
  clientSelected(e: any) {
    this.clientValue = this.clients.find(o => o.id === e.id);
  }

  onTakeDateContext(ctx) {
    console.log('DATE onTakeDateContext ctx', ctx);
    if (ctx.selectedDate !== null) {
      const dt = moment(
        ctx.selectedFormatted,
        ctx.selectedFormatted.includes('-') ? consts.DB_DATE_TIME_FORMAT : consts.DATE_FORMAT
      );
      if (dt.isValid()) {
        this.takeVisitDate = dt.format(consts.DB_DATE_TIME_FORMAT);
        this.takeDateText = dt.format(consts.DATE_FORMAT);
      }
    }
  }
  modalChooseTakeTimeOK(time: string) {
    console.log('Take time', time);
    this.takeTime = time;
    this.timeInvalidate++;
  }

  choosePackage() {
    this.choosenPackage = this.packageValue;
    this.isPackageAnalysisShown = false;
  }
  addAnalyse() {
    if (!this.choosenAnalysis.includes(this.analyseValue)) {
      this.choosenAnalysis.push(this.analyseValue);
    }
  }
  showPackageAnalysis() {
    this.isPackageAnalysisShown = !this.isPackageAnalysisShown;
  }
  deselectAnalysis(analyse) {
    this.choosenAnalysis = this.choosenAnalysis.filter(a => a.id != analyse.id);
  }
  deselectPackage() {
    this.choosenPackage = null;
  }

  registerSample() {
    if (this.chosenRegistration === consts.ClientType.INTERNAL && this.timeObjects.length === 0) {
      helpers.error('Należy uzupełnić datę pobrania');
    } else {
      if (this.isRailDelivery === true && this.areRailDeliveryFieldsEmpty === true) {
        return this.questionModal.showModal({});
      }
      if (this.isBiomass === true && this.selectedBiomassSource['id'] === undefined) {
        return helpers.error('Należy uzupełnić pochodzenie próbki biomasy');
      }
      this.save();
    }
  }

  async save() {
    const sample = new types.Sample();
    sample.status_id = consts.SampleStatus.REGISTERED.id;
    sample.description = this.description;
    sample.tests = this.choosenAnalysis;

    if (this.isRailDelivery === false) {
      this.mineName = null;
      this.declaredClass = null;
      this.arrivalDatetime = null;
      this.postingDate = null;
      this.wagonNumber = null;
    }

    sample.posting_date = this.postingDate;
    sample.arrival_datetime = this.arrivalDatetime;
    sample.wagon_number = this.wagonNumber;
    if (this.isBiomass === true && this.sourceValue.value === consts.Sources.DOSTAWY_K) {
      sample.pt_number = this.mineName;
      sample.identifier_number = this.declaredClass;
    } else if (this.isBiomass === true && this.sourceValue.value === consts.Sources.DOSTAWY_S) {
      sample.pt_number = this.ptNumber;
    } else {
      sample.declared_class = this.declaredClass;
      sample.mine_name = this.mineName;
    }

    if (this.choosenPackage) {
      sample.package_name = this.choosenPackage.name;
      sample['package'] = this.choosenPackage;
    }
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      sample.client_id = consts.CLIENT_ID_ELEKTROWNIA;
      sample.client_type = consts.ClientType.INTERNAL;
      sample.sample_object_id = this.objectValue['id'];
      sample.object_type_id = this.objectTypeValue['id'];
      sample.source_id = this.sourceValue['id'];
      sample.take_method_ids = this.selectedTakeMethod;
      sample.area_test_id = this.areaValue['id'];
      sample.additional = this.additionalExamValue.name === 1;
      if (this.additionalExamValue.name === 1) {
        sample.take_person_string = this.takePerson.text;
        sample.take_place_string = this.takePlaceString;
        sample.take_place_ids = null;
      } else {
        sample.take_place_ids = this.selectedTakePlaces;
      }
      sample.number_samples_manual = this.numberSamplesManual;
      if (this.isBiomass === true) {
        sample.biomass_source = this.selectedBiomassSource.symbol;
      }

      console.log(this.prepareNorms, this.recalculateNorms, 'Checking ');
      if (this.prepareNorms.length > 0) {
        sample.prepare_norm_id = this.prepareNorms[0].id;
      }
      if (this.recalculateNorms.length > 0) {
        sample.recalculate_norm_id = this.recalculateNorms[0].id;
      }
    } else {
      sample.client_type = consts.ClientType.EXTERNAL;
      sample.sample_object_id = this.objectValue['id'];
      sample.object_type_id = this.objectTypeValue['id'];
      sample.client_id = this.clientValue.id;
      sample.source_id = null;
      sample.take_method_id = null;
      sample.area_test_id = null;
      sample.take_place_ids = null;
      sample.take_method_ids = null;
      sample.additional = false;
      sample.prepare_norm_id = null;
      sample.recalculate_norm_id = null;
      sample.number_samples_manual = null;

      sample.implementation_base_string = this.implementationBaseString;
      sample.take_person_string = this.takePersonString;
      sample.take_norm_string = this.takeNormString;
      sample.take_place_string = this.takePlaceString;
      sample.take_method_string = this.takeMethodString;
    }

    sample.sample_dates = this.timeObjects;

    console.log('SAMPLE TO SAVE', sample);
    const response = await API.saveSample(sample);
    if (response.status === consts.StatusCodes.OK) {
      if (response.data.error_code === consts.ErrorCodes.DUPLICATE_SAMPLE_NUMBER) {
        helpers.error('Istnieje już próbka o takim numerze, tj. ten sam typ obiektu, miejsce i data pobrania!');
      } else {
        helpers.info('Próbka zarejestrowana!');
        this.$router.push({
          path: '/lab/samplesboard',
          query: { no: response.data.sample.number, listView: 'false' },
        });
      }
    } else {
      helpers.error('Błąd przy rejestracji próbki!');
    }
  }

  async loadDicts() {
    const response = await API.fetchDicts();
    this.dicts = response.data.dicts.filter(d => d.active === true);
    console.log('DICTS', this.dicts);
    this.activeDictIds = this.dicts.map(d => d.id);
  }

  structure = [];

  async loadStructure() {
    const response = await API.fetchModel('Structure');
    this.structure = response.data.objs.filter(s => s.active === true);
    this.structure = this.structure.filter(
      s =>
        s.object &&
        this.activeDictIds.includes(s.object.id) &&
        s.source &&
        this.activeDictIds.includes(s.source.id) &&
        s.object_type &&
        this.activeDictIds.includes(s.object_type.id) &&
        s.take_method &&
        this.activeDictIds.includes(s.take_method.id) &&
        s.take_place &&
        this.activeDictIds.includes(s.take_place.id) &&
        s.prepare_norm &&
        this.activeDictIds.includes(s.prepare_norm.id) &&
        s.recalculate_norm &&
        this.activeDictIds.includes(s.recalculate_norm.id) &&
        s.area_test &&
        this.activeDictIds.includes(s.area_test.id)
    );
    console.log('STRUCTURE', this.structure);
  }

  async loadClients() {
    const response = await API.fetchClients();
    if (response.data.success === true) {
      if (this.chosenRegistration === consts.ClientType.EXTERNAL) {
        this.clients = response.data.clients.filter(c => c.id !== ENEA_CLIENT_ID);
      } else {
        this.clients = response.data.clients.filter(c => c.id === ENEA_CLIENT_ID);
      }
      this.clientValue = this.clients[0];
      console.log('CLIENTS:', this.clients);
    }
  }

  async loadPackages() {
    const response = await API.fetchPackages();
    if (response.data.success === true) {
      this.packages = response.data.packages;
      this.packages = this.packages.filter(p => p.active === true);
    }
  }

  async created() {
    console.log('%c*********************** Register creating ***********************', 'color:red');

    this.listDisplay = [
      {
        value: (obj: any) => {
          const dt = obj.date_from;
          if (!dt) {
            return '';
          }
          return moment(dt, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
        },
        headerName: 'DATA OD',
        columnCss: 'col-3 p-0 text-center',
        headerCss: 'col-3 p-0 text-center',
      },
      {
        value: '$time_from',
        headerName: 'GODZINA OD',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        value: (obj: any) => {
          const dt = obj.date_to;
          if (!dt) {
            return '';
          }
          return moment(dt, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
        },
        headerName: 'DATA DO',
        columnCss: 'col-3 p-0 text-center',
        headerCss: 'col-3 p-0 text-center',
      },
      {
        value: '$time_to',
        headerName: 'GODZINA DO',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'USUŃ',
        fieldType: 'icon',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
        icon: 'times',
        size: 'lg',
        value: 'user',
        css: 'pdf-icon',
        action: async (object: any, e: any) => {
          const index = this.timeObjects.findIndex(el => {
            console.log(el);
            el['id'] === object.id;
          });
          this.timeObjects.splice(object.id, 1);
        },
        visible: (obj: any) => {
          return true;
        },
      },
    ];

    await this.loadDicts();
    await this.loadPackages();
    this.modal.showModal();
    await this.loadStructure();
    this.selectObject(this.objects[0]);

    const response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
      this.analysis = this.analysis.filter(a => a.status === consts.TestStatus.IMPL);
      this.objectAnalysis = this.analysis;
      this.analyseValue = this.objectAnalysis[0];
    }

    await this.loadClients();

    console.log('%c*********************** Register created ***********************', 'color:red');
  }
  get start() {
    console.log('%c---------- Start of Register template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of Register template -----------', 'color:green;font-weight:600');
    return '';
  }

  areaKey = 0;
  @Watch('chosenRegistration')
  onChoosenRegistrationChanged() {
    console.log('choosenregistration changed', this.chosenRegistration);
    if (this.chosenRegistration === consts.ClientType.EXTERNAL) {
      this.fillAreas();
    } else {
      this.selectObject(this.objects[0]);
      this.objectKey++;
      console.log('object key changed');
    }
    this.loadClients();
  }
}
