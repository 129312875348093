























































































































































































































import { ExtendedParam, Dict } from '../../types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as consts from '../../consts';
import moment from 'moment';
import hash from 'object-hash';
import TimeInput from '../../components/dummy/TimeInput.vue';

@Component({
  components: {
    TimeInput,
  },
})
export default class GenericField extends Vue {
  @Prop() readonly param: ExtendedParam;
  @Prop({ type: Object, required: true }) object;
  @Prop() index: number;
  @Prop({ required: false, default: true }) edit: boolean;

  async invokeFunctionAndReload() {
    await this.param.action(this.object);
    this.$forceUpdate();
  }

  getParamValue(param: ExtendedParam, field) {
    if (!this.object) {
      return;
    }
    if (param.evaluate) {
      return param.evaluate(this.object);
    }
    if (!field) {
      field = this.param.value;
    }
    const queue = field.split('__');

    let obj = this.object;
    while (queue.length !== 0 && obj !== '') {
      const str = queue.shift();
      if (obj !== undefined) {
        if (str in obj) {
          obj = obj[str];
        } else {
          obj = '';
        }
      } else {
        obj = '';
      }
    }
    return obj;
  }

  evaluateParamField(command) {
    if (command === undefined) {
      return;
      //console.error("ERROR! Unexpected undefined for param and object", this.param, this.object)
    }
    if (typeof command === typeof this.doNothing) {
      return command(this.object, this.param);
    }
    if (typeof command !== 'string') {
      return command;
    } else if (command[0] === '!') {
      return this.param[command.slice(1, command.length)];
    } else if (command[0] === '$') {
      const pname = command.slice(1, command.length);
      return this.getParamValue(this.param, command.slice(1, command.length));
    } else if (command[0] === '#') {
      const pname = command.slice(1, command.length);
      this.object[pname] = this.getParamValue(this.param, pname);
      return pname;
    } else {
      return command;
    }
  }

  getBadgeValue(param: ExtendedParam) {
    if (!this.object) {
      return;
    }
    const queue = param.badgeVariant.split('__');
    let obj = this.object;
    while (queue.length !== 0) {
      const str = queue.shift();
      obj = obj[str];
    }
    return obj;
  }

  dateText = '';

  doNothing = () => {
    const a = 0;
  };

  onMainDateContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.object[this.param.value] = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = moment(this.object[this.param.value], consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
      if (this.param.action) {
        this.param.action(ctx, this.object, this.param);
      }
    }
  }

  dateTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.object[this.param.value] = today.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = today.format(consts.DATE_FORMAT);
      if (this.param.action) this.param.action(event, this.object, this.param);
    } else {
      this.object[this.param.value] = moment(event.traget.value, consts.DATE_FORMAT).format(consts.DB_DATE_TIME_FORMAT);
      if (this.param.action) this.param.action(event, this.object, this.param);
    }
  }

  valueSelected = {};

  defaultMultiSelect(e: any, param: ExtendedParam) {
    this.valueSelected = e;
    console.log(this.object, 'OBJ CHANGED');
    console.log('param', param);
    if (this.engine) {
      this.engine.action(e, this.object);
    }
    if (param.action) {
      param.action(e, this.object, this.index);
    }
  }

  reset() {
    this.valueSelected = {};
    this.dateText = '';
  }
  engine: any;
  created() {
    if (this.param.fieldType === 'multiselect') {
      if (this.param.engine) {
        this.engine = this.param.engine;
        this.valueSelected = this.engine.load(this.object, this.param);
        if (this.valueSelected) {
          console.log(this.valueSelected, ' LOADED VALUE FOR ' + this.engine.fieldNameWrite);
          this.$forceUpdate();
        }
      } else {
        if (this.param.load) {
          this.valueSelected = this.param.load(this.object, this.param);
        } else {
          this.valueSelected = this.object[this.param.fieldName];
        }
      }
    }
    if (this.param.fieldType === 'date') {
      this.dateText = this.object[this.param.fieldName];
    }
  }
}
