
































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import { ModalEvents } from '../../consts';

@Component
export default class ModalReportAuthorise extends Vue {
  @Prop({ type: String, required: true }) title;
  @Prop({ type: String, required: true }) readonly question;
  @Prop({ type: String, required: false, default: '' }) questionClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'md' }) size;
  @Prop({ type: Boolean, required: false, default: false }) cancelButton;

  private show = false;
  private dataObj = null;

  questionValue = '';

  @Emit()
  modalClose() {
    return 1;
  }

  showModal(dataObj: any) {
    this.dataObj = dataObj;
    this.dataObj.ok = false;
    this.show = true;
  }
  saveModal() {
    console.log('Emit dataObj', this.dataObj);
    this.dataObj.ok = true;
    this.$emit(ModalEvents.OK_EVENT, this.dataObj);
    this.$emit(ModalEvents.CLOSE_EVENT, this.dataObj);
    this.show = false;
  }
  noModal() {
    this.$emit(ModalEvents.CANCEL_EVENT);
    this.$emit(ModalEvents.CLOSE_EVENT, this.dataObj);
    this.hideModal();
  }
  hideModal() {
    this.show = false;
  }
}
