













































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import * as consts from '../../consts';
import { Sample } from '../../types';
import * as API from '../../store/api';
import moment from 'moment';
import sampleModule from '../../store/modules/samples';
import * as helpers from '../../helpers';
import TimeInput from '../../components/dummy/TimeInput.vue';
import Modal from '../../components/spec/Modal.vue';
import DateInput from '../../components/dummy/DateInput.vue';
import MinimalGenericList from '../..//components/spec/MinimalGenericList.vue';
import GenericForm from '../../components/spec/GenericForm.vue';
import RailDeliveryForm from '../../components/spec/RailDeliveryForm.vue';

const ENEA_CLIENT_ID = 1;

@Component({
  components: {
    TimeInput,
    Modal,
    DateInput,
    MinimalGenericList,
    GenericForm,
    RailDeliveryForm,
  },
})
export default class ModalSampleEdit extends Vue {
  @Ref() readonly modal: Modal;
  @Ref() readonly timeModal: Modal;
  @Ref() readonly resetTime: TimeInput;
  @Ref() readonly resetDate: DateInput;
  @Prop({ type: Boolean, required: true }) listView;

  show = false;
  isNew = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;
  sample: Sample = null;
  saving = false;
  INTER = consts.ClientType.INTERNAL;
  EXTER = consts.ClientType.EXTERNAL;
  chosenRegistration = consts.ClientType.INTERNAL;
  saveKey = 0;
  takeVisitDate = '';
  takeDateText = '';
  takeTime = '';
  timeInvalidate = 1;
  areaKey = 0;
  prepareNormsString = '';
  recalculateNormsString = '';
  isExternalCoal = false;
  ptNumber = '';
  prepareNorms = [];
  recalculateNorms = [];
  prepareNormValue = {};
  recalculateNormValue = {};
  spinner = false;
  dicts = [];
  activeDictIds = [];

  timeObject: any = {};
  BASIC_PACKAGE_NAME = 'Pakiet podstawowy';

  isPackageAnalysisShown = false;

  selected = [];
  now = moment().format(consts.DATE_FORMAT);
  nowDB = moment().format(consts.DB_DATE_FORMAT);
  items = [];
  user = { first_name: '', last_name: '' };
  itemsWDK = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<3,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'WDK_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<3,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WDK_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'WDK_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WDK_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'WDK_5',
    },
  ];
  itemsWN = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<2,8 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'WN_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<2,8 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WN_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'WN_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WN_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'WN_5',
    },
  ];
  itemsB = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<60,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'B_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<10,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'B_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<1,0 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'B_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<1,0 mm)',
      sampleAction: 'Podział na 2 pod-próbki o wymaganej masie i mielenie',
      prepareSymbol: 'B_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<1,0 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'B_5',
    },
  ];
  itemsPNISO = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<2,8 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'PNISO_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<2,8 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNISO_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'PNISO_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNISO_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'PNISO_5',
    },
  ];
  itemsPNG = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<3,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'PNG_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<3,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNG_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'PNG_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNG_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'PNG_5',
    },
  ];
  itemsPNGPNISO = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<3,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'PNGPNISO_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<3,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNGPNISO_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'PNGPNISO_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNGPNISO_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'PNGPNISO_5',
    },
  ];

  get isInternal() {
    return this.sample.client_type === consts.ClientType.INTERNAL;
  }

  get isNumberSamplesManual() {
    return (
      this.sourceValue['value'] === consts.Sources.NAWEGL &&
      this.selectedTakeMethod.filter(tm => tm.value === consts.TakeMethods.M109).length > 0
    );
  }

  get isAdditional() {
    return this.sample.additional == true;
  }

  numberWithName({ number, name }) {
    return `${number} — [${name}]`;
  }

  get enabled() {
    return [consts.SampleStatus.REGISTERED.id, consts.SampleStatus.CORRECT.id].includes(+this.selectedStatus['id']);
  }

  get enabledNorm() {
    if (!this.isInternal) {
      return this.enabled;
    } else {
      return false;
    }
  }

  get enabledPrepareChecklist() {
    return (
      [consts.SampleStatus.REGISTERED.id, consts.SampleStatus.CORRECT.id].includes(+this.selectedStatus['id']) ||
      (+this.sample.status === consts.SampleStatus.REGISTERED.id &&
        +this.selectedStatus['id'] === consts.SampleStatus.IN_EXAM.id)
    );
  }

  selectPrepareNorm(e: any) {
    this.prepareNormValue = this.prepareNorms.find(n => n.id === e.id);
    this.recalculateNormValue = this.recalculateNorms.find(n => n.id === this.prepareNormValue['recalculate_norm_id']);
    if (this.prepareNormValue['value'] === this.prepareNorms[0].value) {
      this.items = this.itemsPNG;
    } else if (this.prepareNormValue['value'] === this.prepareNorms[1].value) {
      this.items = this.itemsPNISO;
    } else if (this.prepareNormValue['value'] === this.prepareNorms[2].value) {
      this.items = this.itemsPNGPNISO;
    }
    const user = this.$store.state.currentUser;
    this.items = this.items.map(item => ({
      ...item,
      sampleId: this.sample.id,
      executeUser: user.first_name + ' ' + user.last_name,
      userId: user.id,
      sampleDate: this.now,
      sampleDateDbFormat: this.nowDB,
    }));
  }

  selectRecalculateNorm(e: any) {
    this.recalculateNormValue = this.recalculateNorms.find(n => n.id === e.id);
  }

  async showModal(params: any = null) {
    console.log('MethodModal params showModal', params);
    this.items = [];
    this.prepareNorms = [];
    this.recalculateNorms = [];
    this.spinner = true;
    this.show = true;
    this.sample = params;
    const sampleId = params.id;
    let response = await API.fetchPrepareChecklist(sampleId);
    if (response.data.success) {
      this.selected = response.data.selected;
      this.prepareNormsString = response.data.prepare_norm_string;
      this.recalculateNormsString = response.data.recalculate_norm_string;
    } else {
      this.selected = [];
    }
    const sample_object = this.sample.sample_object.value ? this.sample.sample_object.value : this.sample.sample_object;
    if (sample_object === consts.Objects.WEGIEL && this.sample.client_type === consts.ClientType.EXTERNAL) {
      this.isExternalCoal = true;
      response = await API.fetchDictType(consts.DictType.PREPARE_NORM);
      response.data.objs.forEach(n => {
        if (n.subtype === consts.DictSubtype.COAL_EXTERNAL) {
          this.prepareNorms.push(n);
        }
      });
      this.prepareNorms = this.prepareNorms.map(n => {
        return { id: n.id, value: n.value, recalculate_norm_id: n.related_id };
      });
      this.prepareNormValue = this.prepareNorms.find(n => n.value === this.prepareNormsString)
        ? this.prepareNorms.find(n => n.value === this.prepareNormsString)
        : {};
      if (this.prepareNormValue['value']) {
        if (this.prepareNormValue['value'] === this.prepareNorms[0].value) {
          this.items = this.itemsPNG;
        } else if (this.prepareNormValue['value'] === this.prepareNorms[1].value) {
          this.items = this.itemsPNISO;
        } else if (this.prepareNormValue['value'] === this.prepareNorms[2].value) {
          this.items = this.itemsPNGPNISO;
        }
      }
      response = await API.fetchDictType(consts.DictType.CALC_NORM);
      response.data.objs.forEach(n => {
        if (n.subtype === consts.DictSubtype.COAL_EXTERNAL) {
          this.recalculateNorms.push(n);
        }
      });
      this.recalculateNorms = this.recalculateNorms.map(n => {
        return { id: n.id, value: n.value };
      });
      this.recalculateNormValue = this.recalculateNorms.find(n => n.value === this.recalculateNormsString)
        ? this.recalculateNorms.find(n => n.value === this.recalculateNormsString)
        : {};
    } else {
      this.isExternalCoal = false;
    }
    if (typeof this.sample.status == 'number') {
      this.selectStatus(this.statuses.find(st => st.id === this.sample.status));
    } else {
      this.selectStatus(this.statuses.find(st => st.id === this.sample.status.id));
    }
    this.chosenRegistration = this.sample.client_type;
    await this.loadData();

    response = await API.fetchSample(params['id']);
    if (response.status === consts.StatusCodes.OK) {
      this.sample = response.data.sample;
      console.log('Sample', this.sample);
      console.log('sources', this.sourceValue);
      console.log('obj', this.selectedObject);
    }

    const object = this.objects.find(o => o.id === this.sample.sample_object);
    if (object !== undefined && object !== null) {
      this.selectObject(object);
    }

    const source = this.sources.find(s => s.id === this.sample.source);
    if (source !== undefined && source !== null) {
      this.selectSource(source);
    }

    const biomassSource = this.biomassSourceValues.find(s => s.symbol === this.sample.biomass_source);
    if (source !== undefined && source !== null) {
      this.selectBiomassSource(biomassSource);
    }

    const objectType = this.objectTypes.find(ot => ot.id === this.sample.object_type);
    if (objectType !== undefined && objectType !== null) {
      this.selectObjectType(objectType);
    }

    if (this.isExternalCoal === false) {
      if (
        this.selectedObject['value'] === consts.Objects.WEGIEL &&
        this.sourceValue['value'] === consts.Sources.DOSTAWY_K
      ) {
        this.items = this.itemsWDK;
      }
      if (
        this.selectedObject['value'] === consts.Objects.WEGIEL &&
        this.sourceValue['value'] === consts.Sources.NAWEGL
      ) {
        this.items = this.itemsWN;
      }
      if (this.selectedObject['value'] === consts.Objects.BIOMASA) {
        this.items = this.itemsB;
      }
    }

    this.fillRailDeliveryForm();

    this.timeObjects = this.sample.sample_dates;

    this.selectedTakeMethod = [];
    console.log('Take Method 2', this.sample['take_methods']);

    console.log('this.takeMethods', this.takeMethods);
    this.sample['take_methods'].forEach(tm => {
      const dictTM = this.takeMethods.find(takem => takem.id === tm['id']);
      console.log('tm', tm, dictTM);
      this.selectedTakeMethod.push(dictTM);
    });

    this.selectedClient = this.clients.find(c => c.id === this.sample.client);

    this.loadTakePlaces();

    this.selectedTakePlaces = [];
    let tempArr = [];
    if (typeof this.sample.sample_take_places === 'object') {
      console.log('TakePlace 1', this.sample.sample_take_places);
      tempArr.push(this.sample.sample_take_places);
    } else if (this.sample['take_places']) {
      console.log('TakePlace 2', this.sample.sample_take_places);
      tempArr = this.sample['take_places'];
    }
    console.log('TakePlaces all', this.takePlaces);
    tempArr.forEach(pl => {
      console.log('TakePlace each', pl);
      const dictPL = this.takePlaces.find(takepl => takepl.id === pl['id']);
      this.selectedTakePlaces.push(dictPL);
      this.selectedTakePlace = dictPL;
    });

    const user = this.$store.state.currentUser;
    this.items = this.items.map(item => ({
      ...item,
      sampleId: this.sample.id,
      executeUser: user.first_name + ' ' + user.last_name,
      userId: user.id,
      sampleDate: this.now,
      sampleDateDbFormat: this.nowDB,
    }));

    this.choosenAnalysis = this.sample['tests'];
    this.implementationBaseString = this.sample.implementation_base_string;
    this.takePersonString = this.sample.take_person_string;
    this.takeNormString = this.sample.take_norm_string;
    this.takeMethodString = this.sample.take_method_string;
    this.takePlaceString = this.sample.take_place_string;
    this.spinner = false;
  }
  async saveModal() {
    if (this.isBiomass === true && this.selectedBiomassSource === undefined) {
      ++this.btnSave;
      return helpers.error('Należy uzupełnić pochodzenie próbki biomasy');
    } else {
      this.saving = true;
      this.params['save'] = true;
      if (this.isBiomass !== true) {
        this.sample.biomass_source = null;
      }
      this.params['object'] = this.sample;
      await this.save();
    }
  }
  hideModal() {
    this.params['save'] = this.saving;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
  /********************* DATE & TIME **********************/
  timeFormParams = [];
  timeObjects = [];
  listDisplay = [];

  get lastDate() {
    if (this.timeObjects.length < 2) {
      if (this.timeObjects[0].date_to) {
        return this.timeObjects[0].date_to;
      } else {
        return null;
      }
    } else return this.getSortedDates[this.timeObjects.length - 1];
  }

  get firstDate() {
    if (this.timeObjects.length === 0) return null;
    return this.getSortedDates[0];
  }

  get getSortedDates() {
    let dts = [];
    this.timeObjects.forEach(el => {
      if (el.date_from) {
        dts.push({ parent: el, date: el.date_from });
      }
      if (el.date_to) {
        dts.push({ parent: el, date: el.date_to });
      }
    });
    dts = dts.sort((a, b) => {
      const diff = moment(a.date, consts.DB_DATE_TIME_FORMAT).diff(moment(b.date, consts.DB_DATE_TIME_FORMAT));
      const secs = moment.duration(diff).asSeconds();
      return secs;
    });
    return dts;
  }

  addTimeObject() {
    const obj = Object.assign({}, this.timeObject);
    obj.localId =
      this.timeObjects.length === 0
        ? 0
        : Math.max(
            ...this.timeObjects.map(el => {
              return el.id;
            })
          ) + 1;

    this.timeObjects.push(obj);
    this.timeObject = {};
    this.timeModal.$forceUpdate();

    for (let i = 1; i <= 4; i++) {
      (this.$refs['reset' + i] as any).reset();
    }

    this.$forceUpdate();
  }

  formatDate(date: string) {
    return moment(date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
  }

  /********************* CLIENT **********************/
  clientKey = 0;
  selectedClient = {};
  clients = [];
  get clientValue() {
    return this.selectedClient;
  }
  selectClient(e: KeyboardEvent) {
    this.selectedClient = e;
  }

  /********************* OBJECT **********************/
  objectKey = 0;
  showAdditional = false;
  selectedObject = {};
  get objectValue() {
    return this.selectedObject;
  }
  get objects() {
    return this.dicts ? this.dicts.filter(d => d.type === consts.DictType.OBJECT) : [];
  }
  selectObject(e: KeyboardEvent) {
    this.selectedObject = e;
    this.sources = this.structure
      .filter(s => s.object.id === e['id'])
      .map(s => {
        return { id: s.source.id, value: s.source.value, object: e };
      });
    this.sources = [...new Map(this.sources.map(item => [item['value'], item])).values()];
    this.selectSource(this.sources[0]);
    this.objectAnalysis = this.analysis.filter(a => a.object === e['id']);
    this.analyseValue = this.objectAnalysis[0];
  }

  /********************* IF BIOMASS INTERNAL - BIOMASS SOURCE **********************/

  biomassSourceValuesList = [
    consts.DocBiomassNames.F11_1,
    consts.DocBiomassNames.F11_2,
    consts.DocBiomassNames.F11_3,
    consts.DocBiomassNames.F11_4,
    consts.DocBiomassNames.F11_5,
    consts.DocBiomassNames.F11_6,
    consts.DocBiomassNames.F11_7,
    consts.DocBiomassNames.F11_8,
    consts.DocBiomassNames.F11_9,
    consts.DocBiomassNames.F11_10,
    consts.DocBiomassNames.F11_11,
  ];
  biomassSourceSymbolsList = [
    consts.DocSymbols.F11_1,
    consts.DocSymbols.F11_2,
    consts.DocSymbols.F11_3,
    consts.DocSymbols.F11_4,
    consts.DocSymbols.F11_5,
    consts.DocSymbols.F11_6,
    consts.DocSymbols.F11_7,
    consts.DocSymbols.F11_8,
    consts.DocSymbols.F11_9,
    consts.DocSymbols.F11_10,
    consts.DocSymbols.F11_11,
  ];
  get biomassSourceValues() {
    const values = [];
    for (let i = 0; i < this.biomassSourceValuesList.length; i++) {
      values.push({
        id: i + 1,
        value: this.biomassSourceValuesList[i],
        symbol: this.biomassSourceSymbolsList[i],
      });
    }
    return values;
  }

  selectedBiomassSource = {};
  get biomassSourceValue() {
    return this.selectedBiomassSource;
  }
  selectBiomassSource(e: KeyboardEvent) {
    this.selectedBiomassSource = e;
  }

  /********************* SOURCE **********************/
  sources = [];
  selectedSource = {};
  get sourceValue() {
    return this.selectedSource;
  }
  selectSource(e: KeyboardEvent) {
    this.selectedSource = e;
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      this.objectTypes = this.structure
        .filter(
          s =>
            s.source.id === e['id'] &&
            s.object.id === e['object']['id'] &&
            s.object_type_id != undefined &&
            s.object_type !== undefined
        )
        .map(s => {
          return { id: s.object_type.id, value: s.object_type.value, object: e['object'], source: e };
        });
    } else {
      this.objectTypes = this.structure
        .filter(s => s.object.id === e['object']['id'] && s.object_type_id != undefined && s.object_type !== undefined)
        .map(s => {
          return { id: s.object_type.id, value: s.object_type.value, object: e['object'] };
        });
    }
    this.objectTypes = [...new Map(this.objectTypes.map(item => [item['value'], item])).values()];
    this.selectObjectType(this.objectTypes[0]);
    this.fillAreas(e);
  }

  /********************* OBJECT TYPE **********************/
  objectTypes = [];
  selectedObjectType = {};
  get objectTypeValue() {
    return this.selectedObjectType;
  }
  selectObjectType(e) {
    this.selectedObjectType = e;
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      this.takeMethods = this.structure
        .filter(
          s =>
            s.object_type_id === e['id'] &&
            s.source.id === e['source']['id'] &&
            s.object.id === e['object']['id'] &&
            s.take_method != undefined &&
            s.take_method_id != undefined
        )
        .map(s => {
          return {
            id: s.take_method_id,
            value: s.take_method.value,
            object: e['object'],
            source: e['source'],
            object_type: e,
          };
        });
      this.takeMethods = [...new Map(this.takeMethods.map(item => [item['value'], item])).values()];
    }
  }

  /********************* TAKE METHOD **********************/
  takeMethods = [];
  selectedTakeMethod = [];
  get takeMethodValue() {
    return this.selectedTakeMethod;
  }
  selectTakeMethod(e: KeyboardEvent) {
    console.log('selectTakeMethod', e);
    this.selectedTakeMethod.push(e);
  }

  /********************* TAKE PLACE **********************/
  takePlaces = [];
  selectedTakePlaces = [];
  selectedTakePlace = {};

  loadTakePlaces() {
    console.log('loadTakePlaces', this.selectedTakeMethod, this.objectTypeValue, this.sourceValue, this.objectValue);
    this.takePlaces = this.structure
      .filter(s => {
        return (
          s.object_type &&
          s.object_type.id === this.objectTypeValue['id'] &&
          s.source &&
          s.source.id === this.sourceValue['id'] &&
          s.object &&
          s.object.id === this.objectValue['id'] &&
          s.take_place
        );
      })
      .map(s => {
        return {
          id: s.take_place_id,
          value: s.take_place.value,
          object: this.objectValue,
          source: this.sourceValue,
          object_type: this.objectTypeValue,
          object_methods: this.selectedTakeMethod,
        };
      });
    console.log(
      'loadTakePlaces',
      this.selectedTakeMethod,
      this.objectTypeValue,
      this.sourceValue,
      this.objectValue,
      this.takePlaces
    );

    this.takePlaces = [...new Map(this.takePlaces.map(item => [item['value'], item])).values()];
    this.selectedTakePlaces = [];
    this.selectedTakePlace = {};
  }

  get takePlaceValue() {
    if (this.isFurnaceWaste === true) {
      return this.selectedTakePlace;
    } else {
      return this.selectedTakePlaces;
    }
  }
  selectTakePlaces(takePlace: any) {
    this.selectedTakePlaces.push(takePlace);
  }
  selectTakePlace(takePlace: any) {
    this.selectedTakePlaces = [];
    this.selectedTakePlace = takePlace;
    this.selectedTakePlaces.push(takePlace);
  }
  /********************* RAIL DELIVERY **********************/
  mineName = '';
  declaredClass = '';
  arrivalDatetime = '';
  postingDate = '';
  wagonNumber = '';

  get isBiomass() {
    return (
      this.objectValue['value'] === consts.Objects.BIOMASA && this.sample.client_type === consts.ClientType.INTERNAL
    );
  }

  get isFurnaceWaste() {
    return (
      this.objectValue['value'] === consts.Objects.ODPAD_PAL && this.sample.client_type === consts.ClientType.INTERNAL
    );
  }

  get isRailDelivery() {
    return (
      (this.objectValue['value'] === consts.Objects.WEGIEL || this.objectValue['value'] === consts.Objects.BIOMASA) &&
      this.sourceValue['value'] === consts.Sources.DOSTAWY_K
    );
  }

  get isCarDelivery() {
    return this.isBiomass === true && this.sourceValue['value'] === consts.Sources.DOSTAWY_S;
  }

  fillRailDeliveryForm() {
    this.arrivalDatetime = this.sample.arrival_datetime;
    this.postingDate = this.sample.posting_date;
    this.wagonNumber = this.sample.wagon_number;
    if (this.isBiomass === true && this.sourceValue['value'] === consts.Sources.DOSTAWY_K) {
      this.mineName = this.sample.pt_number;
      this.declaredClass = this.sample.identifier_number;
    } else if (this.isBiomass === true && this.sourceValue['value'] === consts.Sources.DOSTAWY_S) {
      this.ptNumber = this.sample.pt_number;
    } else {
      this.declaredClass = this.sample.declared_class;
      this.mineName = this.sample.mine_name;
    }
  }

  /********************* AREA **********************/
  selectedArea = {};
  areas = [];
  get areaValue() {
    return this.selectedArea;
  }
  selectArea(e: KeyboardEvent) {
    this.selectedArea = e;
  }
  fillAreas(e: KeyboardEvent = null) {
    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      this.areas = this.structure
        .filter(s => s.source.id === e['id'] && s.object.id === e['object']['id'])
        .map(s => {
          return {
            id: s.area_test.id,
            value: s.area_test.value,
            object: e,
          };
        });
      this.areas = [...new Map(this.areas.map(item => [item['value'], item])).values()];
    } else {
      this.areas = [
        { id: 1, value: 'Regulowany' },
        { id: 2, value: 'NIE-regulowany' },
      ];
    }
    this.selectArea(this.areas[0]);
  }

  /********************* STATUS **********************/
  selectedStatus = {};
  get statusValue() {
    return this.selectedStatus;
  }
  get statuses() {
    return consts.SampleStatus.array as Array<any>;
  }
  selectStatus(e: KeyboardEvent) {
    this.selectedStatus = e;
    if (
      +this.sample.status === consts.SampleStatus.REGISTERED.id &&
      this.selectedStatus['id'] === consts.SampleStatus.IN_EXAM.id
    ) {
      helpers.info("Należy uzupełnić zakładkę 'Przygotowanie próbki'");
    }
  }

  packages = [];
  packageValue = null;
  choosenPackage = null;

  implementationBaseString = '';
  takePersonString = '';
  takeNormString = '';
  takePlaceString = '';
  takeMethodString = '';

  disabledAnalysis = false;
  BASIC_PACKAGE_ID = 1;
  packageSelected(e: any) {
    this.disabledAnalysis = e.id === this.BASIC_PACKAGE_ID ? true : false;
    this.packageValue = this.packages.find(o => o.id === e.id);
  }

  onTakeDateContext(ctx) {
    console.log('DATE onTakeDateContext ctx', ctx);
    if (ctx.selectedDate !== null) {
      const dt = moment(
        ctx.selectedFormatted,
        ctx.selectedFormatted.includes('-') ? consts.DB_DATE_TIME_FORMAT : consts.DATE_FORMAT
      );
      if (dt.isValid()) {
        this.takeVisitDate = dt.format(consts.DB_DATE_TIME_FORMAT);
        this.takeDateText = dt.format(consts.DATE_FORMAT);
      }
    }
  }
  modalChooseTakeTimeOK(time: string) {
    console.log('Take time', time);
    this.takeTime = time;
    this.timeInvalidate++;
  }

  choosePackage() {
    this.choosenPackage = this.packageValue;
    this.isPackageAnalysisShown = false;
  }
  analysis = [];
  objectAnalysis = [];
  analyseValue = null;
  choosenAnalysis = [];
  analyseSelected = {};
  selectAnalyse(e: KeyboardEvent) {
    this.analyseValue = e;
  }
  addAnalyse() {
    if (!this.choosenAnalysis.includes(this.analyseValue)) {
      this.choosenAnalysis.push(this.analyseValue);
    }
    this.analyseSelected = null;
  }
  showPackageAnalysis() {
    this.isPackageAnalysisShown = !this.isPackageAnalysisShown;
  }
  deselectAnalysis(analyse) {
    this.choosenAnalysis = this.choosenAnalysis.filter(a => a.id != analyse.id);
  }
  deselectPackage() {
    this.choosenPackage = null;
  }

  get sampleTests() {
    console.log('typeof this.sample.sample_tests ', typeof this.sample.sample_tests, this.sample.sample_tests);
    if (typeof this.sample.sample_tests === 'object') {
      return [this.sample.sample_tests];
    } else {
      return this.sample.sample_tests;
    }
  }

  async save() {
    if (this.isExternalCoal) {
      if (this.prepareNormValue['value']) {
        this.prepareNormsString = this.prepareNormValue['value'];
      }
      if (this.recalculateNormValue['value']) {
        this.recalculateNormsString = this.recalculateNormValue['value'];
      }
    }
    this.sample.tests = this.choosenAnalysis;
    this.sample.client_id = this.clientValue['id'];

    if (this.choosenPackage) {
      this.sample.package_name = this.choosenPackage.name;
      this.sample['package'] = this.choosenPackage;
    }

    if (this.isRailDelivery === false) {
      this.mineName = null;
      this.declaredClass = null;
      this.arrivalDatetime = null;
      this.postingDate = null;
      this.wagonNumber = null;
    }

    this.sample.arrival_datetime = this.arrivalDatetime;
    this.sample.posting_date = this.postingDate;
    this.sample.wagon_number = this.wagonNumber;
    if (this.isBiomass === true && this.sourceValue['value'] === consts.Sources.DOSTAWY_K) {
      this.sample.pt_number = this.mineName;
      this.sample.identifier_number = this.declaredClass;
    } else if (this.isBiomass === true && this.sourceValue['value'] === consts.Sources.DOSTAWY_S) {
      this.sample.pt_number = this.ptNumber;
    } else {
      this.sample.declared_class = this.declaredClass;
      this.sample.mine_name = this.mineName;
    }

    if (this.chosenRegistration === consts.ClientType.INTERNAL) {
      console.log('saving INTERNAL');
      this.sample.client_type = consts.ClientType.INTERNAL;
      this.sample.sample_object_id = this.objectValue['id'];
      this.sample.object_type_id = this.objectTypeValue['id'];
      this.sample.source_id = this.sourceValue['id'];
      this.sample.take_method_id = this.takeMethodValue['id'];
      this.sample.area_test_id = this.areaValue['id'];
      if (this.sample.additional == true) {
        this.sample.take_person_string = this.takePersonString;
        this.sample.take_place_string = this.takePlaceString;
        this.sample.take_place_ids = null;
      } else {
        this.sample.take_place_ids = this.selectedTakePlaces;
      }
      this.sample.take_method_ids = this.selectedTakeMethod;
      if (this.isBiomass === true) {
        this.sample.biomass_source = this.selectedBiomassSource['symbol'];
      }
    } else {
      console.log('saving EXTERNAL');
      this.sample.client_type = consts.ClientType.EXTERNAL;
      this.sample.sample_object_id = this.objectValue['id'];
      this.sample.object_type_id = this.objectTypeValue['id'];

      this.sample.source_id = null;
      this.sample.take_method_id = null;
      this.sample.area_test_id = null;
      this.sample.take_place_ids = null;

      this.sample.implementation_base_string = this.implementationBaseString;
      this.sample.take_person_string = this.takePersonString;
      this.sample.take_norm_string = this.takeNormString;
      this.sample.take_place_string = this.takePlaceString;
      this.sample.take_method_string = this.takeMethodString;
    }
    this.sample.prepare_norm_string = this.prepareNormsString;
    this.sample.recalculate_norm_string = this.recalculateNormsString;
    this.sample.sample_dates = this.timeObjects;

    console.log('STATUS', this.statusValue);
    console.log('SAMPLE TO SAVE', this.sample);

    const response = await API.saveSample(this.sample);
    if (response.status === consts.StatusCodes.OK) {
      if (response.data.error_code === consts.ErrorCodes.DUPLICATE_SAMPLE_NUMBER) {
        ++this.btnSave;
        helpers.error('Istnieje już próbka o takim numerze, tj. ten sam typ obiektu, miejsce i data pobrania!');
      } else {
        const response = await API.updateSampleStatus(this.sample.id, this.statusValue['id']);
        if (response.data.success === true) {
          const selectedItems = this.items.filter(item => this.selected.includes(item.prepareSymbol));
          console.log('CHECKLIST TO SAVE', selectedItems);
          const response_checklist = await API.savePrepareChecklist(
            selectedItems,
            this.sample.id,
            this.prepareNormsString,
            this.recalculateNormsString
          );
          sampleModule.setVersion(response_checklist.data.sample_module_version);
          if (response_checklist.data.success === true) {
            helpers.info('Próbka zapisana!');
            this.$emit(consts.ModalEvents.OK_EVENT, this.params);
            this.show = false;
          } else {
            helpers.error('Błąd przy zapisie checklisty przygotowania próbki');
          }
        } else {
          helpers.error('Błąd przy zapisie statusu i historii zmian statusu próbki!');
        }
      }
    } else {
      helpers.error('Błąd przy zapisie próbki!');
    }
  }

  async loadDicts() {
    const response = await API.fetchDicts();
    this.dicts = response.data.dicts.filter(d => d.active === true);
    console.log('DICTS', this.dicts);
    this.activeDictIds = this.dicts.map(d => d.id);
  }

  structure = [];

  async loadStructure() {
    const response = await API.fetchModel('Structure');
    this.structure = response.data.objs.filter(s => s.active === true);
    this.structure = this.structure.filter(
      s =>
        s.object &&
        this.activeDictIds.includes(s.object.id) &&
        s.source &&
        this.activeDictIds.includes(s.source.id) &&
        s.object_type &&
        this.activeDictIds.includes(s.object_type.id) &&
        s.take_method &&
        this.activeDictIds.includes(s.take_method.id) &&
        s.take_place &&
        this.activeDictIds.includes(s.take_place.id) &&
        s.prepare_norm &&
        this.activeDictIds.includes(s.prepare_norm.id) &&
        s.recalculate_norm &&
        this.activeDictIds.includes(s.recalculate_norm.id) &&
        s.area_test &&
        this.activeDictIds.includes(s.area_test.id)
    );
    console.log('STRUCTURE', this.structure);
  }

  async loadClients() {
    const response = await API.fetchClients();
    if (response.data.success === true) {
      if (this.chosenRegistration === consts.ClientType.EXTERNAL) {
        this.clients = response.data.clients.filter(c => c.id !== ENEA_CLIENT_ID);
      } else {
        this.clients = response.data.clients.filter(c => c.id === ENEA_CLIENT_ID);
      }
      this.selectedClient = this.clients[0];
      console.log('CLIENTS:', this.clients);
    }
  }

  async loadData() {
    console.log('%c*********************** Modal Sample Edit creating ***********************', 'color:red');

    this.listDisplay = [
      {
        value: (obj: any) => {
          const dt = obj.date_from;
          if (!dt) {
            return '';
          }
          return moment(dt, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
        },
        headerName: 'DATA OD',
        columnCss: 'col-3 p-0 text-center',
        headerCss: 'col-3 p-0 text-center',
      },
      {
        value: '$time_from',
        headerName: 'GODZINA OD',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        value: (obj: any) => {
          const dt = obj.date_to;
          if (!dt) {
            return '';
          }
          return moment(dt, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
        },
        headerName: 'DATA DO',
        columnCss: 'col-3 p-0 text-center',
        headerCss: 'col-3 p-0 text-center',
      },
      {
        value: '$time_to',
        headerName: 'GODZINA DO',
        columnCss: 'col-2 p-0 text-center',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'USUŃ',
        fieldType: 'icon',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
        icon: 'times',
        size: 'lg',
        value: 'user',
        css: 'pdf-icon',
        action: async (object: any, e: any) => {
          const index = this.timeObjects.findIndex(el => {
            el.id === object.id;
          });
          this.timeObjects.splice(object.id, 1);
        },
        visible: (obj: any) => {
          return true;
        },
      },
    ];

    this.takeMethods = [];

    await this.loadDicts();
    await this.loadStructure();
    this.selectObject(this.objects[0]);

    let response = await API.fetchPackages();
    if (response.data.success === true) {
      this.packages = response.data.packages;
    }

    response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
      this.analysis = this.analysis.filter(a => a.status === consts.TestStatus.IMPL);
      this.objectAnalysis = this.analysis;
      this.analyseValue = this.objectAnalysis[0];
    }

    await this.loadClients();
  }
}
