
















































import { Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus, BusEvents } from '@/helpers/eventbus';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import GenericList from '@/components/spec/GenericList.vue';
import MethodForm from '@/views/laborant/administration/MethodForm.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as consts from '@/consts';
import * as helpers from '@/helpers';
import { Method, ExtendedParam, StandardMultiselectEngine } from '@/types';

@Component({
  components: {
    TopPanel,
    MethodForm,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class MethodList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly methodModal: Modal;
  params = new Array<ExtendedParam>();
  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  objectCopy = {};
  object = {};
  canUserEditMethod = false;
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<ExtendedParam>();
  @Ref() filterForm: GenericForm;
  @Ref() readonly list: GenericList;
  isEditing = false;
  ifValid = true;
  authTestsIds = [];
  tests = [];

  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }

  get getModalTitle() {
    if (this.isEditing) {
      return 'Edycja metody - ' + this.objectCopy['number'] + ' ' + this.objectCopy['name'];
    } else {
      return 'Nowa metoda';
    }
  }

  get user() {
    return this.$store.state.currentUser;
  }

  get canUserAddTest() {
    return helpers.isUserAuthorisedOfSymbol(consts.UserRightSymbols.TEST_ADD);
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  processLoaded(object: any) {
    //console.log('loading obj....', object);
    object.status = consts.MethodStatus.byId(object.status);
  }

  hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }

  async showMethodModal(object: any = {}) {
    const response = await API.fetchTests();
    if (response.data.success === true) {
      this.tests = response.data.tests;
    }
    this.objectCopy = Object.assign({}, object);
    this.isEditing = Object.entries(object).length !== 0;
    if (this.authTestsIds.includes(object.id) || this.isEditing === false) {
      this.canUserEditMethod = true;
    } else {
      this.canUserEditMethod = false;
    }
    this.methodModal.showModal();
  }

  async saveModal(e: any) {
    this.ifValid = true;
    this.$root.$emit('ifValue', true);
    const testWithIdenticalNumber = this.tests.filter(t => t.number === this.objectCopy['number']);
    if (testWithIdenticalNumber.length > 0 && testWithIdenticalNumber[0].id !== this.objectCopy['id']) {
      this.ifValid = false;
      this.$root.$emit('ifValue', false);
      console.log('emit false');
      helpers.error('Istnieje już metoda o takim numerze!');
    } else {
      let emptySymbol = false;
      const emptySymbolNames = [];
      let notUniqueSymbol = false;
      const notUniqueSymbolNames = [];
      let noTestNoInSymbol = false;
      const noTestSymbolNames = [];
      const symbols = [];
      const stps = this.objectCopy['sampleTestParams'];

      for (let index = 0; index < stps.length; index++) {
        if (stps[index].symbol === '') {
          emptySymbol = true;
          this.ifValid = false;
          this.$root.$emit('ifValue', false);
          console.log('emit false');
          emptySymbolNames.push(stps[index].name);
        }
      }

      for (let index = 0; index < stps.length; index++) {
        if (!stps[index].symbol.includes('$' + this.objectCopy['number'])) {
          noTestNoInSymbol = true;
          this.ifValid = false;
          this.$root.$emit('ifValue', false);
          console.log('emit false');
          noTestSymbolNames.push(stps[index].name);
        }
      }

      stps.forEach(stp => symbols.push(stp.symbol));
      if (this.hasDuplicates(symbols)) {
        notUniqueSymbol = true;
        this.ifValid = false;
        this.$root.$emit('ifValue', false);
        console.log('emit false');
        const uniqueStps = new Set(symbols);
        const duplicates = symbols.filter(symbol => {
          if (uniqueStps.has(symbol)) {
            uniqueStps.delete(symbol);
          } else {
            return symbol;
          }
        });
        duplicates.forEach(duplicate => {
          for (let index = 0; index < stps.length; index++) {
            if (stps[index].symbol === duplicate) {
              notUniqueSymbolNames.push(stps[index].name);
            }
          }
        });
      }

      if (emptySymbol === true) {
        helpers.error('Należy uzupełnić symbol dla: ' + emptySymbolNames.join(', ') + '.');
      } else if (notUniqueSymbol === true) {
        helpers.error('Należy zmienić symbol na unikalny dla: ' + notUniqueSymbolNames.join(', ') + '.');
      } else if (noTestNoInSymbol === true) {
        helpers.error('Należy uzupełnić symbol o $ i numer metody dla: ' + noTestSymbolNames.join(', ') + '.');
      } else {
        this.objectCopy['status'] = 0;
        this.objectCopy['price'] = 0;
        this.objectCopy['sampleTestParams'] = this.objectCopy['sampleTestParams'].map(stp => ({
          ...stp,
          META: { MODEL_NAME: 'TestParam' },
        }));
        this.objectCopy['testToolGroups'] = this.objectCopy['testToolGroups'].map(ttg => ({
          ...ttg,
          testId: this.objectCopy['id'],
        }));
        console.log(this.objectCopy, 'objectCopy');
        const obj = this.objectCopy as any;
        console.log(this.objectCopy, 'Zapisywanie metody');
        const response = await API.saveMethod(this.objectCopy);
        if (response.data.success) {
          helpers.info('Pomyślnie zapisano metodę');
          this.list.fillObjects();
          this.$root.$emit('ifValue', true);
          // console.log('emit true after save')
        }
        this.objectCopy = {};
      }
    }
  }

  hideModal() {
    EventBus.$emit(BusEvents.CLEAR);
  }

  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    console.log(this.objects, 'fetched objects');
    const response = await API.fetchUserMethodAuths({ user_id: this.user.id, auth_type: consts.TestAuthTypesSym.EDIT });
    this.authTestsIds = response.data.method_auths.map(ati => ati.test_id);

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'number',
        headerName: 'Numer',
      },
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa metody',
      },
      {
        fieldType: 'input',
        fieldName: 'norm',
        headerName: 'Norma',
      },
      {
        fieldType: 'multiselect',
        value: '$section_id',
        headerName: 'Sekcja',
        action: (e: any, obj: any) => {
          obj.section_id = e.id;
        },
        options: await helpers.getDictAsArray(3),
        multiSelectTrack: 'value',
      },
      {
        headerName: 'Status',
        fieldType: 'multiselect',
        options: consts.MethodStatus.array.map(el => {
          return { id: el.id, name: el.name };
        }),
        fieldName: 'status',
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('status', 'id'),
      },
      {
        fieldType: 'multiselect',
        fieldName: 'actual',
        options: [
          { name: 'TAK', id: 0 },
          { name: 'NIE', id: 1 },
          { name: 'Wycofana bez zastąpienia', id: 2 },
        ],
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('actual', 'id'),
        headerName: 'Metoda aktualna',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'accreditation',
        options: [{ name: 'BRAK WYBORU' }, { name: 'N.AKREDYTOWANA', id: 0 }, { name: 'AKREDYTOWANA', id: 1 }],
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('accreditation', 'id'),
        headerName: 'Akredytowana(?)',
      },
    ];

    this.params = [
      {
        value: '$number',
        headerName: 'NUMER',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        value: 'name',
        fieldType: 'ntexts',
        headerName: 'NAZWA METODY',
        href: {
          getValues: (obj: any) => {
            return [
              {
                text: obj.name,
                css: 'font-14 text-left',
              },
            ];
          },
        },
        headerCss: 'col-3 p-0',
      },
      {
        value: '$norm',
        headerName: 'NORMA',
        headerCss: 'col-2 p-0',
      },
      {
        headerName: 'SEKCJA',
        value: '$section__value',
        headerCss: 'col-1 p-0',
      },
      {
        fieldType: 'badge',
        value: '$status__name',
        sortField: 'status',
        badgeVariant: '$status__badge',
        headerName: 'STATUS',
      },

      {
        fieldType: 'boolean',
        value: 'accreditation',
        headerName: 'AKR',
      },
      {
        headerName: 'AKT',
        sortField: 'actual',
        value: (object: Method) => {
          const arr = ['TAK', 'NIE', 'Wycofana bez zastąpienia'];
          return arr[object.actual];
        },
      },
      {
        headerName: 'OPERACJE',

        fieldType: 'ntexts',
        href: {
          getValues: (obj: Method) => {
            if (this.authTestsIds.includes(obj.id)) {
              if (!obj) return;
              const opers = obj.status.opers;
              return opers.map(el => {
                return { text: el.name, css: 'font-12 clickable-text', oper: el };
              });
            }
          },
          click: async (obj: Method, href: any) => {
            if (this.authTestsIds.includes(obj.id)) {
              console.log(obj, href, 'METODA');
              const operation = href['oper'] as consts.MethodOperationType;
              const transform_type = {
                RVT: consts.MethodStatus.WORKING.id,
                RJC: consts.MethodStatus.CANCELED.id,
                NEW: consts.MethodStatus.WORKING.id,
              };
              obj.statusId = transform_type[operation.symbol];
              obj.status = consts.MethodStatus.byId(obj.statusId);
              const tempObj = Object.assign({}, obj);

              tempObj.status = obj.status.id as any;

              const response = await helpers.saveObjectModel(tempObj, 'Test');
              if (response) {
                helpers.info('Wprowadzono zmianę statusu');
              } else {
                helpers.error('Wystąpił nieoczekiwany błąd');
              }
            }
          },
        },
        value: 'method_group__name',
      },
    ];

    this.modalParams = [
      {
        fieldType: 'input',
        value: 'invent_number',
        headerName: 'Numer inwenteryzacyjny',
      },
      {
        fieldType: 'input',
        value: 'name',
        headerName: 'Nazwa',
      },
      {
        fieldType: 'input',
        value: 'localization_name',
        headerName: 'Lokalizacja',
      },
    ];
    //console.log(this.objects, 'fetched objects');
  }

  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      setTimeout(() => {
        this.filterObject = { ...this.$route.query };
      }, 1000);
    }
  }
}
