























































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import * as consts from '../../consts';
import { Sample } from '../../types';
import * as API from '../../store/api';
import moment from 'moment';
import * as helpers from '../../helpers';
import * as types from '../../types';
import TimeInput from '../../components/dummy/TimeInput.vue';
import Modal from '../../components/spec/Modal.vue';
import DateInput from '../../components/dummy/DateInput.vue';
import MinimalGenericList from '../..//components/spec/MinimalGenericList.vue';
import GenericForm from '../../components/spec/GenericForm.vue';

const INTERNAL = 0;
const EXTERNAL = 1;

@Component({
  components: {
    TimeInput,
    Modal,
    DateInput,
    MinimalGenericList,
    GenericForm,
  },
})
export default class ModalSamplePrepare extends Vue {
  @Ref() readonly modal: Modal;
  @Ref() readonly timeModal: Modal;
  @Ref() readonly resetTime: TimeInput;
  @Ref() readonly resetDate: DateInput;

  enabled = true;
  show = false;
  isNew = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;
  sample: Sample = null;
  saving = false;
  INTER = INTERNAL;
  EXTER = EXTERNAL;
  chosenRegistration = INTERNAL;
  saveKey = 0;
  takeVisitDate = '';
  takeDateText = '';
  takeTime = '';
  timeInvalidate = 1;
  areaKey = 0;
  prepareNormsString = '';
  recalculateNormsString = '';
  isExternalCoal = false;
  prepareNorms = [];
  recalculateNorms = [];
  prepareNormValue = {};
  recalculateNormValue = {};
  timeObject: any = {};
  BASIC_PACKAGE_NAME = 'Pakiet podstawowy';

  selected = [];
  now = moment().format(consts.DATE_FORMAT);
  nowDB = moment().format(consts.DB_DATE_FORMAT);

  items = [];
  user = { first_name: '', last_name: '' };
  itemsWDK = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<3,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'WDK_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<3,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WDK_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'WDK_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WDK_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'WDK_5',
    },
  ];
  itemsWN = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<3,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'WN_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<3,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WN_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'WN_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'WN_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'WN_5',
    },
  ];
  itemsB = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<60,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'B_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<10,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'B_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<1,0 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'B_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<1,0 mm)',
      sampleAction: 'Podział na 2 pod-próbki o wymaganej masie i mielenie',
      prepareSymbol: 'B_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<1,0 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'B_5',
    },
  ];
  itemsPNISO = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<2,8 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'PNISO_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<2,8 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNISO_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'PNISO_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNISO_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'PNISO_5',
    },
  ];
  itemsPNG = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<3,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'PNG_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<3,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNG_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'PNG_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNG_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,2 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'PNG_5',
    },
  ];
  itemsPNGPNISO = [
    {
      text: 'wykonano',
      sampleType: 'Próbka laboratoryjna (<3,0 mm)',
      sampleAction: 'Wydzielenie 2 próbek o wymaganej masie',
      prepareSymbol: 'PNGPNISO_1',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do oznaczania wilgoci całkowitej (<3,0 mm)',
      sampleAction: 'Wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNGPNISO_2',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Suszenie do stałej masy',
      prepareSymbol: 'PNGPNISO_3',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Mielenie i wydzielenie 1 próbki o wymaganej masie',
      prepareSymbol: 'PNGPNISO_4',
    },
    {
      text: 'wykonano',
      sampleType: 'Próbka do badań (<0,212 mm)',
      sampleAction: 'Przekazanie próbki do SPiO',
      prepareSymbol: 'PNGPNISO_5',
    },
  ];

  isPackageAnalysisShown = false;

  selectPrepareNorm(e: any) {
    this.prepareNormValue = this.prepareNorms.find(n => n.id === e.id);
    this.recalculateNormValue = this.recalculateNorms.find(n => n.id === this.prepareNormValue['recalculate_norm_id']);
    if (this.prepareNormValue['value'] === this.prepareNorms[0].value) {
      this.items = this.itemsPNG;
    } else if (this.prepareNormValue['value'] === this.prepareNorms[1].value) {
      this.items = this.itemsPNISO;
    } else if (this.prepareNormValue['value'] === this.prepareNorms[2].value) {
      this.items = this.itemsPNGPNISO;
    }
    const user = this.$store.state.currentUser;
    this.items = this.items.map(item => ({
      ...item,
      sampleId: this.sample.id,
      executeUser: user.first_name + ' ' + user.last_name,
      userId: user.id,
      sampleDate: this.now,
      sampleDateDbFormat: this.nowDB,
    }));
  }

  selectRecalculateNorm(e: any) {
    this.recalculateNormValue = this.recalculateNorms.find(n => n.id === e.id);
  }

  async showModal(params: { sample: types.Sample; lastMove: any }) {
    console.log('MethodModal params showModal', params);
    this.items = [];
    this.prepareNorms = [];
    this.recalculateNorms = [];
    this.sample = params.sample;
    this.params = params;
    const sampleId = this.sample.id;
    let response = await API.fetchPrepareChecklist(sampleId);
    if (response.data.success) {
      this.selected = response.data.selected;
      this.prepareNormsString = response.data.prepare_norm_string;
      this.recalculateNormsString = response.data.recalculate_norm_string;
    } else {
      this.selected = [];
    }
    if (this.sample.sample_object === consts.Objects.WEGIEL && this.sample.client_type === consts.ClientType.EXTERNAL) {
      this.isExternalCoal = true;
      response = await API.fetchDictType(consts.DictType.PREPARE_NORM);

      response.data.objs.forEach(n => {
        if (n.subtype === consts.DictSubtype.COAL_EXTERNAL) {
          this.prepareNorms.push(n);
        }
      });
      this.prepareNorms = this.prepareNorms.map(n => {
        return { id: n.id, value: n.value, recalculate_norm_id: n.related_id };
      });
      this.prepareNormValue = this.prepareNorms.find(n => n.value === this.prepareNormsString)
        ? this.prepareNorms.find(n => n.value === this.prepareNormsString)
        : {};
      if (this.prepareNormValue['value'] === this.prepareNorms[0].value) {
        this.items = this.itemsPNG;
      } else if (this.prepareNormValue['value'] === this.prepareNorms[1].value) {
        this.items = this.itemsPNISO;
      } else if (this.prepareNormValue['value'] === this.prepareNorms[2].value) {
        this.items = this.itemsPNGPNISO;
      }

      response = await API.fetchDictType(consts.DictType.CALC_NORM);

      response.data.objs.forEach(n => {
        if (n.subtype === consts.DictSubtype.COAL_EXTERNAL) {
          this.recalculateNorms.push(n);
        }
      });
      this.recalculateNorms = this.recalculateNorms.map(n => {
        return { id: n.id, value: n.value };
      });
      this.recalculateNormValue = this.recalculateNorms.find(n => n.value === this.recalculateNormsString)
        ? this.recalculateNorms.find(n => n.value === this.recalculateNormsString)
        : {};
    } else {
      this.isExternalCoal = false;
      if (this.sample.sample_object === consts.Objects.WEGIEL && this.sample.source === consts.Sources.DOSTAWY_K) {
        this.items = this.itemsWDK;
      }
      if (this.sample.sample_object === consts.Objects.WEGIEL && this.sample.source === consts.Sources.NAWEGL) {
        this.items = this.itemsWN;
      }
      if (this.sample.sample_object === consts.Objects.BIOMASA) {
        this.items = this.itemsB;
      }
    }
    const user = this.$store.state.currentUser;
    this.items = this.items.map(item => ({
      ...item,
      sampleId: this.sample.id,
      executeUser: user.first_name + ' ' + user.last_name,
      userId: user.id,
      sampleDate: this.now,
      sampleDateDbFormat: this.nowDB,
    }));
    if (this.sample.client_type === consts.ClientType.INTERNAL) {
      this.enabled = false;
    }
    this.show = true;
  }
  async saveModal() {
    this.saving = true;
    this.params['save'] = true;
    await this.save();
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.enabled = true;
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.saving;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.enabled = true;
    this.show = false;
  }

  async save() {
    if (this.isExternalCoal) {
      if (this.prepareNormValue['value']) {
        this.prepareNormsString = this.prepareNormValue['value'];
      }
      if (this.recalculateNormValue['value']) {
        this.recalculateNormsString = this.recalculateNormValue['value'];
      }
    }
    const selectedItems = this.items.filter(item => this.selected.includes(item.prepareSymbol));
    console.log('CHECKLIST TO SAVE', selectedItems);
    const response = await API.savePrepareChecklist(
      selectedItems,
      this.sample.id,
      this.prepareNormsString,
      this.recalculateNormsString
    );
    if (response.data.success === true) {
      helpers.info('Czynności przygotowania zapisane!');
    } else {
      helpers.error('Błąd przy zapisie czynności przygotowania!');
    }
  }
}
