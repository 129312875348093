import store from '../store/index';

export function getSetting(settings, symbol, defaultValue) {
  let settingsValue = defaultValue;
  const user = store.state.currentUser;
  let user_id = null;
  if ('id' in user) {
    user_id = user['id'];
  }
  console.log('getSetting UserID', user_id);
  let setting = null;

  setting = settings.find(s => s.symbol === symbol && s.user_id === user_id);
  if (!setting) {
    setting = settings.find(s => s.symbol === symbol);
  }

  if (setting) {
    settingsValue = setting.value;
    //console.log('settingValue from settings', settingsValue);
  }
  //console.log('Setting', symbol, setting, 'read, value:', settingsValue);
  return settingsValue;
}
