










































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import MinimalGenericList from '../../../components/spec/MinimalGenericList.vue';
import * as helpers from '../../../helpers';
import Modal from '../../../components/spec/Modal.vue';
import FileManager from '../../../components/spec/FileManager.vue';
import * as API from '@/store/api';
import * as consts from '../../../consts';
import { Training, TrainingPersonel } from '@/types';
import { User, ExtendedParam, StandardMultiselectEngine } from '../../../types';
import { Guid } from 'guid-typescript';

@Component({
  components: {
    TopPanel,
    GenericList,
    FileManager,
    GenericForm,
    Header,
    Modal,
    Footer,
    MinimalGenericList,
  },
})
export default class TrainingList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly mainList: GenericList;
  @Ref() readonly fileManager: FileManager;
  @Ref() readonly modal: Modal;
  @Ref() readonly trainingPersonelForm: GenericForm;

  listDisplayParams = [];
  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  object = {};

  training: Training = new Training();

  trainingPart: any = {};
  trainingParams = new Array<any>();
  trainedParams = new Array<any>();
  participentsParams = new Array<any>();

  filterObject = {};
  guidValue = '';
  modalTitle = '';

  copyFilterObject = {};
  url = '';

  mainSectionGuid = Guid.create();
  trainingPersonelGuid: Guid;

  filterParams = new Array<ExtendedParam>();

  buttonText = '+ Dodaj';
  currFiles = [];

  formKey = 0;

  @Ref() filterForm: GenericForm;

  addParticipent() {
    const trainingPersonel = Object.assign(new TrainingPersonel(), this.trainingPart) as TrainingPersonel;

    const guid = Guid.create();
    trainingPersonel.guid = guid.toString();

    this.training.training_personel.push(trainingPersonel);

    this.trainingPart = {};
    this.formKey++;

    //TODO for 2.0 version, attachments to each personel training process. ; )
    //this.trainingPart = new trainingPersonel();
    //this.trainingPersonelGuid = Guid.create();
  }

  processLoadedElement(object: any) {
    console.log(object, 'Loaded training...');
  }

  async showModal(object: any) {
    this.mainSectionGuid = Guid.create();

    this.trainingPart = new TrainingPersonel();
    if (!object.id) {
      this.modalTitle = 'Dodaj zrealizowane szkolenie';
      this.training = new Training();
      this.currFiles = [];
    } else {
      try {
        const response = await API.getTraining(object.id);
        this.training = Object.assign(new Training(), response.data.object);
      } catch (e) {
        helpers.error('Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu');
        return;
      }

      this.modalTitle = 'Edycja szkolenia';

      this.currFiles = this.training.training_files.map(el => el.file);
    }
    console.log('Loaded Training', this.training);
    this.modal.showModal();
  }
  async saveModal() {
    try {
      await this.fileManager.deleteMarkedFiles();
      this.training.guid = this.mainSectionGuid.toString();
      const response = await API.saveTraining(this.training);
      if (response.data.success) {
        helpers.info('Pomyślnie zapisano!');
      }
    } catch (e) {
      helpers.error('Wystąpił nieoczekiwany błąd podczas zapisu');
    }

    this.mainList.fillObjects();
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }
  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, e);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    this.trainedParams = [
      {
        headerName: 'Imię i nazwisko osoby przeszkolonej',
        value: '$personel__fullName',
        headerCss: 'col-8',
      },
      {
        headerName: 'Data',
        headerCss: 'col-2 text-center',
        value: (object: TrainingPersonel, param: any) => {
          const dt = moment(object.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
          return dt;
        },
      },
      {
        headerName: '-',
        value: 'Usuń',

        columnCss: 'col-1 clickable-text',
        headerCss: 'col-1',
        action: (object: TrainingPersonel, param: any) => {
          this.training.training_personel = this.training.training_personel.filter(el => {
            if (object.id) return el.id != object.id;
            else return el.guid != object.guid;
          });
        },
      },
    ];
    this.trainingParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szkolenia',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'user_oranganiser',
        options: await helpers.getModelAsArray('UserData').then(result =>
          result.map(el => {
            el.fullName = el.user.first_name + ' ' + el.user.last_name;
            return el;
          })
        ),
        multiSelectTrack: 'fullName',
        engine: new StandardMultiselectEngine('user_organiser_id'),
        headerName: 'Organizator',
      },
      {
        fieldType: 'input',
        fieldName: 'description',
        headerName: 'Opis',
      },
      {
        fieldType: 'date',
        headerName: 'Data',
        fieldCss: 'w-50',
        fieldName: 'date',
        value: 'empty',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.date = dt.format(consts.DB_DATE_FORMAT);
          delete object['empty'];
        },
      },
      {
        fieldType: 'multiselect',
        engine: new StandardMultiselectEngine('type'),
        fieldName: 'type',
        options: [
          { name: 'Zewnętrzne', id: 1 },
          { name: 'Wewnętrzne', id: 0 },
        ],
        css: 'w-25',
        multiSelectTrack: 'name',
        headerName: 'Rodzaj',
      },
    ];

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szkolenia',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: [
          { name: 'ZEWNĘTRZNE', id: 1 },
          { name: 'WEWNĘTRZNE', id: 0 },
        ],
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('type', 'id'),
        headerName: 'Rodzaj',
      },
    ];

    this.listDisplayParams = [
      {
        value: '$name',
        headerName: 'NAZWA',
        columnCss: 'col-6 p-0 text-left font-16',
        headerCss: 'col-6  p-0 text-center',
      },
      {
        fieldType: 'badge',
        badgeVariant: (object, param) => {
          const id = object.type;
          const variants = ['success', 'info'];
          return variants[id];
        },
        value: (object, param) => {
          const id = object.type;
          const texts = ['WEWNĘTRZNE', 'ZEWNĘTRZNE'];
          return texts[id];
        },
        headerName: 'RODZAJ',
        sortField: 'type',
        headerCss: 'col-3 p-0',
      },
      {
        fieldType: 'icon',
        headerName: 'ZAŁĄCZNIK',
        icon: object => {
          return 'file-pdf';
        },
        size: 'lg',
        key: 0,
        css: 'pdf-icon',
        visible: (object: any) => {
          return true;
        },
        action: async (object: any, e: any) => {
          this.url = process.env.VUE_APP_API_URL + 'media/' + object.document.path;
          await this.$forceUpdate();
          document.getElementById('download').click();
        },
      },
      {
        headerName: 'Operacje',
        fieldType: 'ntexts',
        href: {
          getValues: (training: Training) => {
            return { text: 'Usuń', css: 'font-12 clickable-text', oper: training };
          },
          click: async (obj: Training, href: any) => {
            const response = await API.deleteTraining(obj.id);
            if (response) {
              helpers.info('Szkolenie usunięte');
            } else {
              helpers.error('Wystąpił nieoczekiwany błąd.');
            }
          },
        },
      },
    ];
    this.participentsParams = [
      {
        fieldType: 'multiselect',
        action: (e: User, object: TrainingPersonel) => {
          object.personel = e;
          object.personel_id = e.id;
        },
        options: await helpers.getModelAsArray('UserData').then(result =>
          result.map(el => {
            el.fullName = el.user.first_name + ' ' + el.user.last_name;
            return el;
          })
        ),
        multiSelectTrack: 'fullName',
        engine: new StandardMultiselectEngine('user_organiser_id'),
        headerName: 'Dostępny personel',
      },
      {
        fieldType: 'date',
        headerName: 'Data',
        fieldCss: 'w-50',
        fieldName: 'date',
        value: 'empty',
        action: (e: any, object: TrainingPersonel) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          object.date = dt.format(consts.DB_DATE_FORMAT);
          delete object['empty'];
        },
      },
    ];
  }
}
