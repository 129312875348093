

































































































































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

import TopPanel from '../../components/dummy/TopPanel.vue';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import ModalExamFilter from '../../components/spec/ModalExamFilter.vue';
import GenericForm from '../../components/spec/GenericForm.vue';
import GenericField from '../../components/spec/GenericField.vue';

import * as consts from '../../consts';
import { Exam, ExtendedParam, FilterData } from '../../types';
import * as API from '../../store/api';

@Component({
  components: {
    TopPanel,
    GenericForm,
    Header,
    Footer,
    ModalExamFilter,
    GenericField,
  },
})
export default class GenericList extends Vue {
  @Prop() readonly title: string;
  @Prop({ default: false }) readonly hideHeader: boolean;
  @Prop({ default: false }) readonly hideTopPanel: boolean;
  @Prop() readonly objects: object[];
  @Prop() readonly paramsDisplay: ExtendedParam[];
  @Prop() readonly defaultSortColumn: string;
  @Prop() readonly buttonTitle: string;
  @Prop() readonly processLoadedElement: any;
  @Prop() readonly proxyAPI: any;
  @Prop({ default: false }) checkable: boolean;
  @Prop({ default: true }) authorised: boolean;
  @Prop() readonly model: string;
  @Prop({ default: false }) readonly fetchShallowData: boolean;
  @Prop({ default: null }) readonly onChecked: any;
  @Prop({ default: '' }) readonly rowCss: any;
  @Prop({ default: true }) loadOnCreate: boolean;
  @Prop({ default: true }) ifFilter: boolean;

  @Prop({
    default() {
      return {};
    },
  })
  readonly filterParams: object;
  btnPrevVisible = false;
  btnNextVisible = true;
  objectSelected: object = {};
  objectsVisible = [];
  checkedAll = false;

  pageSize = 20;
  ENTITY_PROCESS = 'ENTITY_PROCESS';
  pageNo = 1;
  allPagesCount = 0;
  sortParams = { field: 'id', dir: consts.SortDir.ASC };
  spinner = false;

  paginate(array: object[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    let index = 1;
    array.forEach(e => {
      e['index'] = index++;
    });
    return array.slice(rowFrom, rowTo);
  }

  get getColumnStyle() {
    return (param: ExtendedParam) => {
      if (param.columnCss) return param.columnCss;
      if (param.headerCss) return param.headerCss;
      return 'col-1 p-0 text-center';
    };
  }

  checkAll() {
    console.log('checkALL', this.checkedAll);
    //this.checkedAll = !this.checkedAll;
    //this.trackedSamples = [];
    for (const object of this.objectsVisible) {
      object.$checked = this.checkedAll;
    }
    this.emitCheckedAll(this.checkedAll);
  }

  emitCheckedAll(checkedAll) {
    this.$emit(consts.ListEvents.CHECKED_ALL, checkedAll);
  }

  emitFilter() {
    this.$emit(consts.ListEvents.FILTER);
  }

  sortPage(sortField = 'id') {
    console.log(this.sortParams, 'TEST');
    sortField = sortField.replaceAll('$', '');
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = consts.SortDir.ASC;
    } else {
      this.sortParams.dir = this.sortParams.dir === consts.SortDir.ASC ? consts.SortDir.DESC : consts.SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.sortParams.field = this.sortParams.field.replaceAll('$', '');
    this.fillObjects();
  }

  sortObejcts(array: object[], field: string, dir: consts.SortDir): object[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      default:
        console.log('Sorting field number');
        return array.sort((a, b) => {
          if (dir === consts.SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          }
        });
    }
  }

  pageSizeChanged(pageSize: number) {
    console.log('pageSizeChanged method', pageSize);
    this.fillObjects(pageSize);
  }

  excludeConds = ['gte', 'lte', 'gt', 'lt'];
  async fillObjects(pageSize = this.pageSize) {
    console.log('################################## fillObjects', this.filterParams);
    this.spinner = true;

    const processedFilterParam = {};
    for (const [key, value] of Object.entries(this.filterParams)) {
      if (key === 'META') {
        continue;
      }
      let properKey = key.replaceAll('$', '');
      if (typeof value === typeof '') {
        if (!this.excludeConds.some(v => key.includes(v))) {
          properKey += '__icontains';
        }
      }
      processedFilterParam[properKey] = value;
    }
    console.log('SORT PARAMS', this.sortParams);
    let response;
    if (this.proxyAPI) {
      const filterData = new FilterData(this.model, this.pageNo, pageSize, processedFilterParam, this.sortParams);
      response = await this.proxyAPI(filterData);
    } else {
      response = await API.fetchFilterdModel(
        this.model,
        processedFilterParam,
        this.sortParams,
        this.pageNo,
        pageSize,
        this.fetchShallowData
      );
    }
    this.objectsVisible = [];
    response.data.objs.forEach(element => {
      if (this.processLoadedElement) this.processLoadedElement(element);
      this.objectsVisible.push(element);
    });
    this.allPagesCount = response.data.pageCount;
    this.objectsVisible = response.data.objs;
    this.spinner = false;
  }

  evaluateRowCss(command, object_row, param_row) {
    if (command === undefined) {
      return;
    }
    if (typeof command === typeof this.evaluateRowCss) {
      return command(object_row, param_row);
    } else {
      return command;
    }
  }

  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillObjects();
  }

  nextPage() {
    this.pageNo++;
    this.fillObjects();
  }

  emitEntity(obj: object) {
    this.$emit(this.ENTITY_PROCESS, obj);
  }

  filterPage() {
    console.log('FilterModal');
    //this.modalExamFilter.showModal();
  }

  get getNextVisible() {
    return this.pageNo !== this.allPagesCount;
  }
  get getPrevVisible() {
    return this.pageNo !== 1;
  }

  sortExam(array: Exam[], field: string, dir: consts.SortDir): Exam[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      default:
        console.log('Sorting');
        return array.sort((a, b) => {
          if (dir === consts.SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          } else {
            return a[field] > b[field] ? 1 : -1;
          }
        });
    }
  }

  async created() {
    console.log('%c*********************** GenericList creating start ***********************', 'color:blue');

    if (this.defaultSortColumn) {
      this.sortParams.field = this.defaultSortColumn;
    }

    this.objectsVisible = this.objects.slice();
    if (this.loadOnCreate === true) {
      await this.fillObjects();
    }
    this.$parent.$on(consts.ListEvents.PAGE_SIZE, this.pageSizeChanged);
    console.log('%c*********************** GenericList creating end ***********************', 'color:blue');
  }
  @Watch('objects')
  onObjectsChanged() {
    console.log('changed', this.objects);
    this.objectsVisible = this.objects.slice();
    this.fillObjects();
  }
  @Watch('filterParams')
  onParamsChanged() {
    console.log('filterParams changed FillObjects', this.filterParams);
    this.fillObjects();
  }
}
