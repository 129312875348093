






























































import moment from 'moment';
import { Component, Ref, Vue } from 'vue-property-decorator';
import Footer from '../../../components/dummy/Footer.vue';
import Header from '../../../components/dummy/Header.vue';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import Modal from '../../../components/spec/Modal.vue';
import * as consts from '../../../consts';
import * as helpers from '../../../helpers';
import store from '../../../store';
import * as API from '../../../store/api';
import * as types from '../../../types';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class ResultCreatorList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly list: GenericList;
  params = new Array<types.ExtendedParam>();
  @Ref() readonly modal: Modal;
  objects = [];
  modalParams = new Array<types.ExtendedParam>();
  show = true;
  object = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<types.ExtendedParam>();
  @Ref() filterForm: GenericForm;
  allDicts = [];
  trackedSamples = [];
  templateSelected: any = {};
  checkedAll = false;
  F11subtypeFilters = {};
  biomassSource: types.DocTemplate = new types.DocTemplate();
  biomassSources = [
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_1,
      symbol: consts.DocSymbols.F11_1,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_2,
      symbol: consts.DocSymbols.F11_2,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_3,
      symbol: consts.DocSymbols.F11_3,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_4,
      symbol: consts.DocSymbols.F11_4,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_5,
      symbol: consts.DocSymbols.F11_5,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_6,
      symbol: consts.DocSymbols.F11_6,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_7,
      symbol: consts.DocSymbols.F11_7,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_8,
      symbol: consts.DocSymbols.F11_8,
      accreditation: true,
    },
    {
      name: consts.DocBiomassNames.F11 + consts.DocBiomassNames.F11_9,
      symbol: consts.DocSymbols.F11_9,
      accreditation: true,
    },
  ];

  async proxyFilter(filterData: types.FilterData) {
    filterData.additionalData = {
      accreditation: this.templateSelected.accreditation,
      symbol: this.templateSelected.symbol,
      filters: this.filters,
    };
    return await API.fetchFilteredSamplesResultCreator(filterData);
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    this.trackedSamples = [];
    for (const object of this.list.objectsVisible) {
      object.$checked = this.checkedAll;
      if (this.checkedAll) {
        this.addCheckedSample(object);
      }
    }
  }

  selectTemplate(e: types.DocTemplate) {
    console.log('selectTemplate', e);

    this.templateSelected = Object.assign(new types.DocTemplate(), e);
    this.filterObject = this.templateSelected.getAsFilterData();

    console.log(this.filterObject, 'loaded Filter object');

    this.list.checkedAll = false;
    console.log('selectTemplate::Filter Object', this.filterObject);
  }

  allTemplates: Array<types.DocTemplate> = [];

  changeCheckedAllSample(checkedAll) {
    console.log('CHECKED ALL', checkedAll);
    this.trackedSamples = [];
    for (const object of this.list.objectsVisible) {
      if (checkedAll) {
        this.addCheckedSample(object);
      }
    }
  }

  samplesOverCap() {
    return (
      this.templateSelected.maximum_sample_count &&
      this.trackedSamples.length == this.templateSelected.maximum_sample_count
    );
  }
  // TO-DO
  // ograniczyc ilosc probek zgodnie z konfiguracja DocTemplates
  addCheckedSample(sample: any) {
    console.log('AddCheckedSample', sample, this.trackedSamples);
    const foundIndex = this.trackedSamples.findIndex(el => {
      console.log(el.id, sample.id);
      return el.id === sample.id;
    });
    if (foundIndex === -1) {
      console.log('tracking.... ', sample);
      if (this.samplesOverCap()) {
        console.log('SampleOverCap');
        const shiftedSample = this.trackedSamples.shift();
        shiftedSample.$checked = false;
        this.list.$forceUpdate();
      }

      this.trackedSamples.push(sample);
      sample.$checked = true;
      console.log('Sample checked', sample, sample.id, 'trackedSample', this.trackedSamples);
    } else {
      console.log('untracking', sample);
      this.trackedSamples.splice(foundIndex, 1);
      sample.$checked = false;
    }
  }

  processLoaded(object: any) {
    if (this.trackedSamples.includes(object.id)) {
      object.$checked = true;
    } else {
      object.$checked = false;
    }
  }

  generateResultsDoc(sample) {
    console.log('generateResultsDoc:', this.trackedSamples);

    if (this.trackedSamples.length === 0) {
      helpers.warn('Musisz wybrać próbki do generacji sprawozdania');
      return;
    }
    store.commit('setResultsDocSampleIds', {
      ids: this.trackedSamples.map(el => el.id),
      template: this.templateSelected,
    });
    this.$router.push({ name: 'resultsDocPreview' });
  }

  /* FILTROWANIE */
  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  sendFilterQuery(e: any) {
    console.log('OK', this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }
  showModal(object: any) {
    this.object = object;
    this.modal.showModal();
  }

  get filters() {
    // obiekt
    const oOdpadPal = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT, consts.Objects.ODPAD_PAL);
    const oWegielKam = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT, consts.Objects.WEGIEL);
    const oBiomasa = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT, consts.Objects.BIOMASA);
    // pochodzenie
    const sDK = helpers.getDictValue(this.allDicts, consts.DictType.SOURCE, consts.Sources.DOSTAWY_K);
    const sNAW = helpers.getDictValue(this.allDicts, consts.DictType.SOURCE, consts.Sources.NAWEGL);
    // typ obiektu
    const otPopiol = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT_TYPE, consts.ObjectTypes.POPIOL);
    const otZuzel = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT_TYPE, consts.ObjectTypes.ZUZEL);
    const otPopiolLotny = helpers.getDictValue(
      this.allDicts,
      consts.DictType.OBJECT_TYPE,
      consts.ObjectTypes.POPIOL_LOTNY
    );
    const otPopiolDenny = helpers.getDictValue(
      this.allDicts,
      consts.DictType.OBJECT_TYPE,
      consts.ObjectTypes.POPIOL_DENNY
    );
    const otMieszanka = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT_TYPE, consts.ObjectTypes.MIESZANKA);
    const otPopiolLotnyWspolspalanie = helpers.getDictValue(
      this.allDicts,
      consts.DictType.OBJECT_TYPE,
      consts.ObjectTypes.P_LOTNY_WSPOLSPALANIE
    );
    // miejsce pobrania
    const tpBlokIX = helpers.getDictValue(this.allDicts, consts.DictType.TAKE_PLACE, consts.TakePlace.BLOK_IX);
    const tpSredniaBloki = helpers.getDictValue(
      this.allDicts,
      consts.DictType.TAKE_PLACE,
      consts.TakePlace.SREDNIA_Z_BLOKOW
    );
    const tpWW = [
      helpers.getDictValue(this.allDicts, consts.DictType.TAKE_PLACE, consts.TakePlace.WW1).id,
      helpers.getDictValue(this.allDicts, consts.DictType.TAKE_PLACE, consts.TakePlace.WW2).id,
    ];
    const TakePlaces = consts.TakePlaces;
    let tpTakePlaces = [
      TakePlaces.BLOK_1,
      TakePlaces.BLOK_2,
      TakePlaces.BLOK_3,
      TakePlaces.BLOK_4,
      TakePlaces.BLOK_5,
      TakePlaces.BLOK_6,
      TakePlaces.BLOK_7,
    ];
    tpTakePlaces = tpTakePlaces.map(tp => helpers.getDictValue(this.allDicts, consts.DictType.TAKE_PLACE, tp).id);

    if (this.templateSelected.symbol === consts.DocSymbols.F03) {
      return {
        sample_object_id: oOdpadPal.id,
        object_type_id__in: [otPopiol.id, otZuzel.id],
        take_place_id__in: tpTakePlaces,
        test_number: consts.Tests.STRATY_PRZY_PRAZENIU.number,
        client_type_id: consts.ClientType.INTERNAL,
      };
    } else if (this.templateSelected.symbol === consts.DocSymbols.F04) {
      return {
        sample_object_id: oOdpadPal.id,
        object_type_id__in: [otPopiol.id, otZuzel.id],
        take_place_id: tpSredniaBloki.id,
        test_number: consts.Tests.OZNACZANIE_WEGLA_ANALIZATOREM.number,
        client_type_id: consts.ClientType.INTERNAL,
      };
    } else if (this.templateSelected.symbol === consts.DocSymbols.F05) {
      return {
        sample_object_id: oOdpadPal.id,
        object_type_id__in: [otPopiolLotny.id, otPopiolDenny.id],
        take_place_id: tpBlokIX.id,
        test_number: consts.Tests.STRATY_PRZY_PRAZENIU.number,
        client_type_id: consts.ClientType.INTERNAL,
      };
    } else if (this.templateSelected.symbol === consts.DocSymbols.F06) {
      return {
        sample_object_id: oWegielKam.id,
        source_id: sDK.id,
        take_place_ids: tpWW,
        client_type_id: consts.ClientType.INTERNAL,
      };
    } else if (this.templateSelected.symbol === consts.DocSymbols.F10) {
      return {
        sample_object_id: oWegielKam.id,
        source_id: sNAW.id,
        client_type_id: consts.ClientType.INTERNAL,
      };
    } else if (this.templateSelected.symbol.substring(0, 3) === consts.DocSymbols.F11) {
      return {
        sample_object_id: oBiomasa.id,
        client_type_id: consts.ClientType.INTERNAL,
        test_number__in: [
          consts.Tests.WILGOC_CALK.number,
          consts.Tests.CIEPLO_SPALANIA.number,
          consts.Tests.WILGOC_POPIOL_BIOMASA_A.number,
          consts.Tests.WILGOC_POPIOL_BIOMASA_B.number,
          consts.Tests.SIARKA_CALK.number,
          consts.Tests.WODOR.number,
        ],
        biomass_source: this.templateSelected.symbol,
      };
    } else if (this.templateSelected.symbol === consts.DocSymbols.F12) {
      return {
        sample_object_id: oBiomasa.id,
        client_type_id: consts.ClientType.INTERNAL,
        test_number: consts.Tests.ANALIZA_SITOWA.number,
      };
    } else if (this.templateSelected.symbol === consts.DocSymbols.F13) {
      return {
        sample_object_id: oOdpadPal.id,
        object_type_id: otPopiolLotny.id,
        client_type_id: consts.ClientType.EXTERNAL,
        test_number: consts.Tests.OZNACZANIE_MIALKOSCI.number,
      };
    } else if ([consts.DocSymbols.F15, consts.DocSymbols.F16].includes(this.templateSelected.symbol)) {
      return {
        sample_object_id: oOdpadPal.id,
        object_type_id__in: [otPopiolLotnyWspolspalanie.id, otMieszanka.id],
        test_number: consts.Tests.STEZENIA_PROMIENIOTWORCZYCH.number,
      };
    } else {
      return '';
    }
  }

  async openFilterModal() {
    await this.modalFilter.showModal();
  }

  useHardCoded = false;

  async created() {
    console.log('%c---------------------------- Creator created start ---------------------', 'color:red');
    const response = await API.fetchDicts();
    this.allDicts = response.data.dicts;
    console.log('Created::allDicts', this.allDicts);

    const sourceNawegl = helpers.getDictValue(this.allDicts, consts.DictType.SOURCE, consts.Sources.NAWEGL);
    const typeBiomasa = helpers.getDictValue(this.allDicts, consts.DictType.OBJECT, consts.Objects.BIOMASA);

    if (!this.useHardCoded) {
      const array = await helpers.getModelAsArray('DocTemplate');
      this.allTemplates = array;
      const F11template = this.allTemplates.find(t => t.symbol === consts.DocSymbols.F11);
      for (let i = 0; i < this.biomassSources.length; i++) {
        this.biomassSource = Object.assign(new types.DocTemplate(), F11template, {
          name: this.biomassSources[i].name,
          symbol: this.biomassSources[i].symbol,
        });
        this.allTemplates.push(this.biomassSource);
      }
      const indexOfF11 = this.allTemplates.findIndex(t => {
        return t.symbol === consts.DocSymbols.F11;
      });
      this.allTemplates.splice(indexOfF11, 1);
      this.allTemplates = this.allTemplates.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.allTemplates = [];
    }
    console.log('created all templates', this.allTemplates);
    this.selectTemplate(this.allTemplates[0]);

    this.filterParams = [
      {
        fieldType: 'multiselect',
        value: '$sample_object_id',
        headerName: 'Obiekt',
        options: await helpers.getDictAsArray(consts.DictType.OBJECT),
        engine: new types.StandardMultiselectEngine('sample_object_id'),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$object_type_id',
        headerName: 'Typ obiektu',
        action: (e: any, obj: any) => {
          obj.object_type_id = e.id;
        },
        options: await helpers.getDictAsArray(consts.DictType.OBJECT_TYPE),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Metoda analizy',
        action: (e: any, obj: any) => {
          obj.object_type = e.id;
        },
        options: await helpers.getDictAsArray(2),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Miejsce pobrania',
        action: (e: any, obj: any) => {
          obj.object_type = e.value;
        },
        options: await helpers.getDictAsArray(3),
        multiSelectTrack: 'value',
      },

      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Metoda pobrania',
        action: (e: any, obj: any) => {
          obj.take_method = e.value;
        },
        options: [{ value: 'Ręczna' }, { value: 'Automatyczna' }],
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$number',
        headerName: 'Pochodzenie',
        action: (e: any, obj: any) => {
          obj.source_id = e.id;
        },
        options: await helpers.getDictAsArray(5),
        multiSelectTrack: 'value',
      },

      {
        fieldType: 'date',
        value: 'date',
        headerName: 'Data rejestracji od',
        fieldCss: 'w-50',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          dt.set({ hour: 0, minute: 0, second: 0 });
          object.create_date_time__gte = dt.format(consts.DB_DATE_TIME_FORMAT);
          object.date = undefined;
        },
      },

      {
        fieldType: 'date',
        value: 'date',
        headerName: 'Data rejestracji do',
        fieldCss: 'w-50',
        action: (e: any, object: any) => {
          const dt = moment(e.selectedFormatted, consts.DATE_FORMAT);
          dt.set({ hour: 23, minute: 59, second: 59 });
          console.log('DATE REJ', dt);
          object.create_date_time__lte = dt.format(consts.DB_DATE_TIME_FORMAT);
          object.date = undefined;
        },
      },
    ];

    this.params = [
      {
        headerName: 'NUMER PRÓBKI',
        value: '$number',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'OBIEKT',
        value: '$sample_object__value',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'TYP OBIEKTU',
        value: '$object_type__value',
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'POCHODZENIE',
        value: '$source__value',
      },
      {
        headerName: 'DATA REJESTRACJI',
        value: '$create_date_time',
        evaluate: (obj: any) => {
          return moment(obj.create_date_time).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'DATA POBRANIA',
        value: '$last_take_date',
        sortField: 'last_take_date',
        evaluate: (obj: any) => {
          if (obj.last_take_date === '') {
            return 'N/A';
          } else {
            return moment(obj.last_take_date).format(consts.DATE_FORMAT).toString();
          }
        },
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'DATA KOŃCA BADAŃ',
        value: '$exam_end_date',
        sortField: 'exam_end_date',
        evaluate: (obj: any) => {
          return moment(obj.exam_end_date).format(consts.DATE_FORMAT).toString();
        },
        columnCss: 'col-1 p-0 text-center',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerCss: 'col-2',
        headerName: 'NAZWA KLIENTA',
        value: '$client__name',
      },
      {
        headerName: 'OPERACJE',

        fieldType: 'ntexts',
        href: {
          getValues: () => {
            return [
              // TO-DO
              { text: '-' },
              //{ text: 'Karta badań', css: 'font-12 clickable-text', oper: 1 },
              //{ text: 'Edycja próbki', css: 'font-12 clickable-text', oper: 2 },
            ];
          },
          click: async (obj: types.Sample, href: any) => {
            //const operation = href['oper'] as consts.;
            const tempObj = Object.assign({}, obj);
          },
        },
        value: 'method_group__name',
      },
    ];

    this.modalParams = [
      {
        headerName: 'Imię',
        fieldType: 'input',
        value: 'firstname',
      },
      {
        fieldType: 'input',
        headerName: 'Nazwisko',
        value: 'lastname',
      },
      {
        headerName: 'Pozycja',
        fieldType: 'input',
        value: 'position',
      },
    ];

    console.log(this.objects, 'fetched objects');
    console.log('%c---------------------------- Creator created end---------------------', 'color:red');
  }
}
