









































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Method, File } from '../../types';
import * as API from '../../store/api';
import ModalQuestion from '../dummy/ModalQuestion.vue';

@Component({
  components: {
    ModalQuestion,
  },
})
export default class FileManager extends Vue {
  revalidate = 0;
  @Prop({ required: false, default: -1, type: Number }) maxFileNumber;
  @Prop({ required: false, default: false, type: Boolean }) warning;
  @Prop({ required: false, default: 'Załączniki:', type: String }) headerText;
  @Prop({ required: false, default: '*', type: String }) allowedExtensions;
  @Prop({ required: true })
  saveData: any;
  @Prop({ required: true }) files;
  @Prop({ default: true }) authorised: boolean;
  @Prop({ required: false, default: false }) ifSign: boolean;
  @Ref() readonly questionModal: ModalQuestion;
  fileArray: File[] = [];
  deletedFiles: File[] = [];
  fileToRemove = new File();

  addAttachment() {
    document.getElementById('fileBuffer').click();
    if (this.ifSign === true) {
      this.$emit('newFile');
    }
  }

  get canAddMoreFiles() {
    if (this.maxFileNumber === -1) {
      return true;
    } else if (this.maxFileNumber > this.fileArray.length) {
      return true;
    } else {
      return false;
    }
  }

  remove(file) {
    this.fileToRemove = file;
    if (this.warning === true) {
      return this.questionModal.showModal({});
    } else {
      this.removeFile();
    }
  }

  removeFile() {
    console.log('deleting file...', this.fileToRemove.id);
    this.deletedFiles.push(this.fileToRemove);
    this.fileArray = this.files.filter(el => el.id !== this.fileToRemove.id);
  }

  async deleteMarkedFiles() {
    this.deletedFiles.forEach(async el => {
      API.removeFile(el.id);
    });
  }

  get getCurrentFiles() {
    return this.fileArray;
  }

  get mediaURL() {
    return process.env.VUE_APP_API_URL + 'media';
  }
  async sendFile(e) {
    console.log('Sending files', e.target.files);
    const form = new FormData();
    form.append('file', e.target.files[0]);
    form.append('guid', this.saveData.type === 'TMP' ? this.saveData.guid : '');
    form.append('data', JSON.stringify(this.saveData));
    form.append('filename', e.target.files[0].name);

    const response = await API.saveFile(form);
    if (response.data.success) {
      this.fileArray.push(response.data.file);
    }
  }

  @Watch('files')
  fileWatcher() {
    this.fileArray = this.files;
    this.deletedFiles = [];
  }

  mounted() {
    this.fileArray = this.files;
  }
}
