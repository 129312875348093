






































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import * as API from '../../../store/api';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import Modal from '../../../components/spec/Modal.vue';
import * as consts from '../../../consts';
import * as helpers from '@/helpers';
import * as types from '../../../types';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
  },
})
export default class PackageList extends Vue {
  @Ref() readonly modalFilter: Modal;
  params = new Array<types.ExtendedParam>();
  @Ref() readonly packageModal: Modal;
  @Ref() readonly list: GenericList;
  objects = [];
  modalParams = new Array<types.ExtendedParam>();
  show = true;
  object = {};
  filterObject = {};
  copyFilterObject = {};
  filterParams = new Array<types.ExtendedParam>();
  @Ref() filterForm: GenericForm;
  analyseKey = 0;

  badgeActive = {
    name: 'Aktywny',
    variant: 'success',
  };

  badgeNotActive = {
    name: 'Nieaktywny',
    variant: 'danger',
  };

  numberWithName({ number, name }) {
    return `${number} — [${name}]`;
  }

  processLoaded(object: any) {
    object.badge = object.active ? this.badgeActive : this.badgeNotActive;
    console.log(object, 'obj');
  }

  async deletePackage(object) {
    const response = await API.deleteObjectModelById(object['id'], 'Package');
    if (response.data.success) {
      helpers.info('Pomyślnie usunięto');
      this.list.fillObjects();
    } else {
      helpers.error('Nieoczekiwany błąd serwera');
    }
  }

  async showPackageModal(object: any = {}) {
    console.log('showPackageModal');
    this.object = Object.assign({}, object);
    if (object && 'id' in object) {
      console.log('object', object);
      const response = await API.fetchPackegeTests(object['id']);
      console.log('RESP', response);
      if (response.data.success === true) {
        this.analyseKey++;
        this.choosenAnalysis = response.data.tests;
      }
    } else {
      this.choosenAnalysis = [];
    }
    this.packageModal.showModal();
  }
  async savePackage() {
    console.log('savePackage', this.object, this.choosenAnalysis);
    this.object['tests'] = this.choosenAnalysis;
    const response = await API.savePackage(this.object);
    if (response.data.success) {
      helpers.info('Pomyślnie zapisano');
      this.list.fillObjects();
    } else {
      helpers.error('Nie wprowadzono poprawnych danych');
    }
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
    });
  }

  sendFilterQuery(e: any) {
    console.log('sendFilterQuery OK', this.copyFilterObject);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  analysis = [];
  choosenAnalysis = [];
  get analyseValue() {
    return this.analyseSelected;
  }
  analyseSelected = {};
  selectAnalyse(e: KeyboardEvent) {
    this.analyseSelected = e;
  }

  addAnalyse() {
    if (!this.choosenAnalysis.includes(this.analyseSelected)) {
      this.choosenAnalysis.push(this.analyseSelected);
    }
  }

  deselectAnalysis(analyse) {
    this.choosenAnalysis = this.choosenAnalysis.filter(a => a.id != analyse.id);
  }

  async created() {
    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa pakietu',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'active',
        options: [
          { name: 'TAK', id: 1 },
          { name: 'NIE', id: 0 },
        ],
        multiSelectTrack: 'name',
        action: (e: any, object: any) => {
          object.active = e.id;
        },
        headerName: 'Aktywny',
      },
    ];

    this.params = [
      {
        headerName: 'NAZWA PAKIETU',
        value: '$name',
        columnCss: 'col-8 p-0 text-left',
        headerCss: 'col-8 p-0 text-center',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'boolean',
        value: 'active',
        columnCss: 'col-2 p-0 text-center font-16',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'AKCJE',
        fieldType: 'bdropdown',
        getValues: (obj: any) => {
          return [
            {
              css: 'font-12',
              text: 'Usuń',
              action: pckg => {
                this.deletePackage(pckg);
              },
            },
          ];
        },
        value: '',
      },
    ];

    this.modalParams = [
      {
        headerName: 'Nazwa pakietu',
        fieldType: 'input',
        fieldName: 'name',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'checkbox',
        fieldName: 'active',
        mainCss: 'mt-2',
      },
      {
        headerName: '',
        fieldType: 'hr',
        fieldName: '',
        mainCss: 'my-3 light-gray',
      },
    ];

    const response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
      this.analysis = this.analysis.filter(a => a.status === consts.TestStatus.IMPL);
      this.analyseSelected = this.analysis[0];
    }
  }
}
