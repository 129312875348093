






import Vue from 'vue';
import Component from 'vue-class-component';
import MainMenu from '../../components/dummy/MainMenu.vue';
import * as API from '../../store/api';
import * as consts from '../../consts';
import * as helpers from '../../helpers';
import store from '../../store';
import { User } from '@/types';

@Component({
  components: { MainMenu },
})
export default class LaborantMenu extends Vue {
  wide: boolean;
  user: User = new User();
  viewRights = [];
  items = [];

  constructor() {
    super();
    this.wide = false;
  }

  get height() {
    return 'height: ' + window.innerHeight + 'px';
  }

  canUserViewPersonnel() {
    return helpers.isUserAuthorisedOfSymbol('PERSONNEL_VIEW');
  }

  async created() {
    this.items = [
      {
        name: 'dashboard',
        title: 'PULPIT',
        icon: 'home',
        link: 'dashboard',
        module: 'lab',
      },
      {
        name: 'register',
        title: 'DODAJ PRÓBKĘ',
        icon: 'plus',
        link: 'register',
        module: 'lab',
        style: 'font-weight: 700;',
      },
      {
        name: 'samplesBoard',
        title: 'TABLICA PRÓBEK',
        icon: 'diagnoses',
        link: 'samplesboard',
        module: 'lab',
      },
      {
        name: 'readingsBoard',
        title: 'TABLICA ODCZYTÓW',
        icon: 'diagnoses',
        link: 'readingsboard',
        module: 'lab',
      },
      {
        name: 'docs',
        title: 'DOKUMENTY',
        icon: 'file-pdf',
        items: [
          {
            name: 'result_docs',
            title: 'Kreator sprawozdań',
            module: 'lab',
          },
          {
            name: 'reports_xls',
            title: 'Kreator raportów',
            module: 'lab',
          },
          {
            name: 'reports',
            title: 'Sprawozdania',
            module: 'lab',
          },
          {
            title: 'Szablony sprawozdań',
            name: 'doctemplate',
            module: 'lab',
          },
          {
            title: 'Szablony raportów',
            name: 'reports_xls_template',
            module: 'lab',
            superuser: true,
          },
          {
            name: 'internal_docs',
            title: 'Dokumenty wewnętrzne',
            module: 'lab',
          },
        ],
      },
      {
        name: 'administer',
        title: 'SYSTEM ZARZĄDZANIA',
        icon: 'user-cog',
        items: [
          {
            name: 'methodlist',
            title: 'Metody badawcze',
            module: 'lab',
            view_symbol: consts.UserRightSymbols.TEST_VIEW,
          },
          {
            name: 'personellist',
            title: 'Personel',
            module: 'lab',
            view_symbol: consts.UserRightSymbols.PERSONNEL_VIEW,
          },
          {
            name: 'toollist',
            title: 'Urządzenia',
            module: 'lab',
            view_symbol: consts.UserRightSymbols.TOOL_VIEW,
          },
          {
            name: 'readingsdefinitionlist',
            title: 'Definicja odczytów',
            module: 'lab',
            view_symbol: consts.UserRightSymbols.READINGS_DEFINITION_VIEW,
          },
          {
            name: 'trainings',
            title: 'Szkolenia',
            module: 'lab',
            view_symbol: consts.UserRightSymbols.TRAINING_VIEW,
          },
          {
            name: 'envconditionlist',
            title: 'Warunki środowiskowe',
            module: 'lab',
            view_symbol: consts.UserRightSymbols.ENVCONDITION_VIEW,
          },
        ],
      },
      /*
        OBJECT = 1,
  METHOD = 2,
  SECTION = 3,
  OBJECT_TYPE = 4,
  MATERIAL = 5,
  SOURCE = 6,
  TAKE_METHOD = 7,
  TAKE_PLACE = 8,
  PREPARE_NORM = 9,
  CALC_NORM = 10,
  AREA = 11,
  ROOM = 12,
      */
      {
        name: 'dictionaries',
        title: 'SŁOWNIKI',
        icon: 'list-alt',
        items: [
          {
            name: 'clientlist',
            title: 'Klienci',
            module: 'lab',
          },
          {
            name: 'packagelist',
            title: 'Pakiety',
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.METHOD,
            title: 'Metody analiz',
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.SECTION,
            title: 'Sekcje',
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.ROOM,
            title: consts.DictTypeTitles[consts.DictType.ROOM],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.OBJECT,
            title: consts.DictTypeTitles[consts.DictType.OBJECT],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.OBJECT_TYPE,
            title: consts.DictTypeTitles[consts.DictType.OBJECT_TYPE],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.SOURCE,
            title: consts.DictTypeTitles[consts.DictType.SOURCE],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.TAKE_METHOD,
            title: consts.DictTypeTitles[consts.DictType.TAKE_METHOD],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.TAKE_PLACE,
            title: consts.DictTypeTitles[consts.DictType.TAKE_PLACE],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.PREPARE_NORM,
            title: consts.DictTypeTitles[consts.DictType.PREPARE_NORM],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.CALC_NORM,
            title: consts.DictTypeTitles[consts.DictType.CALC_NORM],
            module: 'lab',
          },
          {
            name: 'dicts/' + consts.DictType.AREA,
            title: consts.DictTypeTitles[consts.DictType.AREA],
            module: 'lab',
          },
          {
            name: 'structure',
            title: 'STRUKTURA',
            module: 'lab',
          },
          {
            name: 'admin',
            title: 'Administrator',
            module: 'lab',
            superuser: true,
          },
        ],
      },
    ];
    console.log('items administer items', this.items[5].items);
    this.user = this.$store.state.currentUser;
    const response = await API.getOneUserGroups(this.user.id);
    const userGroups = response.data.groups;
    this.user.groups = userGroups;
    const userWithGroups = this.user as User;
    this.$store.commit('setCurrentUser', userWithGroups);
    if (this.canUserViewPersonnel()) {
      this.viewRights.push(consts.UserRightSymbols.PERSONNEL_VIEW);
    }
    console.log('view rights', this.viewRights);
    const administerItems = this.items.filter(item => item.name === 'administer')[0].items;
    if (!this.viewRights.includes(consts.UserRightSymbols.PERSONNEL_VIEW)) {
      for (let i = 0; i < administerItems.length; i++) {
        if (administerItems[i]['view_symbol'] === consts.UserRightSymbols.PERSONNEL_VIEW) {
          administerItems.splice(i, 1);
        }
      }
      this.items.forEach(item => {
        if (item.name === 'administer') {
          item.items = administerItems;
        }
      });
    }
    console.log('items administer items', this.items[5].items);
  }
}
