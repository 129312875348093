var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-3"},[(_vm.coal)?_c('date-input',{ref:"postingDateInput",staticClass:"col-6",attrs:{"title":'Data nadania:',"enabled":_vm.enabled,"date":_vm.postingDateSync},on:{"date_changed":function (value) {
        _vm.postingDateSync = _vm.datetimeToDate(value);
        _vm.$forceUpdate();
      }}}):_vm._e(),_c('div',{staticClass:"col-6"},[_c('b-form-group',{attrs:{"label":_vm.labelMineName,"label-for":"input-mine-name"}},[_c('b-input',{staticClass:"input-border",attrs:{"disabled":!_vm.enabled,"placeholder":"<wpisz>"},model:{value:(_vm.mineNameSync),callback:function ($$v) {_vm.mineNameSync=$$v},expression:"mineNameSync"}})],1)],1),(_vm.coal)?_c('date-input',{ref:"arrivalDateInput",staticClass:"col-6 mt-2",attrs:{"title":'Data przybycia:',"enabled":_vm.enabled,"date":_vm.arrivalDatetimeSync},on:{"date_changed":function (value) {
        _vm.arrivalDate = value;
        _vm.setArrivalDateTime();
      }}}):_vm._e(),(_vm.coal)?_c('time-input',{ref:"arrivalTimeInput",staticClass:"col-6 mt-2",attrs:{"title":'Godzina przybycia:',"enabled":_vm.enabled,"time":_vm.arrivalDatetimeSync},on:{"time_changed":function (value) {
        _vm.arrivalTime = value;
        _vm.setArrivalDateTime();
      }}}):_vm._e(),_c('div',{class:_vm.coal ? 'col-6 mt-2' : 'col-6'},[_c('b-form-group',{attrs:{"label":_vm.labelClass,"label-for":"input-declared-class"}},[_c('b-input',{staticClass:"input-border",attrs:{"disabled":!_vm.enabled,"placeholder":"<wpisz>"},model:{value:(_vm.declaredClassSync),callback:function ($$v) {_vm.declaredClassSync=$$v},expression:"declaredClassSync"}})],1)],1),(_vm.coal)?_c('div',{staticClass:"col-6 mt-2"},[_c('b-form-group',{attrs:{"label":"Numer wagonu:","label-for":"input-wagon-number"}},[_c('b-input',{staticClass:"input-border",attrs:{"disabled":!_vm.enabled,"placeholder":"<wpisz>"},model:{value:(_vm.wagonNumberSync),callback:function ($$v) {_vm.wagonNumberSync=$$v},expression:"wagonNumberSync"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }