









































import moment from 'moment';
import { Component, Ref, Vue, Prop } from 'vue-property-decorator';
import { DATE_TIME_FORMAT } from '../../consts';
import * as API from '../../store/api';
import Modal from './Modal.vue';
import * as helpers from '../../helpers';
import * as consts from '../../consts';
import { round } from 'mathjs';
import * as settings from '../../helpers/settings';

const BLOCK_BIO_NUMBER = 9;

@Component({
  components: {
    Modal,
  },
})
export default class ImportReadingsCSV extends Vue {
  @Prop(Array) takePlaces: [];
  @Prop(Boolean) btnDisabled: true;
  @Ref() readonly modal: Modal;

  settings = { regex: '', separator: '', shiftHour: 0, shiftMinute: 0, dateFormatString: '' };
  uploading = false;
  analysis = [];
  allLinesCount = 0;
  counter = 0;
  failedCounter = 0;
  modalMessage = '';
  dates = [];
  dots = '';
  dotsInterval;
  terminate = false;
  regex = /,\r\n|,\n/;
  dictToMap = [];
  SRAllObjectTypes17 = [];
  SRAllObjectTypes9 = [];
  importSRMappingParam = [];

  disabledOkButton = true;

  bloki = [
    ['I', 1],
    ['II', 2],
    ['III', 3],
    ['IV', 4],
    ['V', 5],
    ['VI', 6],
    ['VII', 7],
    ['IX', 9],
  ];

  /*
  Kondensat = ['T1', 'ypkk', 'yzkk', 'O2', 'Na', 'pH1'];
  WZzaZZ = ['ypkk2', 'ykk2', 'O2', 'pH2', 'T2'];
  WZzaXW = ['T3', 'pH3'];
  WodaKotlowa = ['SiO2', 'Cl', 'T4', 'pH4', 'ypkk4', 'yzkk4'];
  PnPW = ['T5', 'ypkk5', 'SiO2(2)'];
  Destylat = ['T6', 'ydest.'];
  Generator = ['czyst. H2'];
  PŚ = ['ypkk6', 'SiO2'];
*/

  test() {
    const csv = `;;;;kondensat;;;;;;WZ za ZZ;;;;;WZ za XW;;woda kotłowa;;;;;;PN/PW;;;Destylat;;Generator;PŚ;
Nr bloku;Data;Moc bloku;Ciśnienie;T1;ypkk;yzkk;O2;Na;pH1;ypkk2;ykk2;O2;pH2;T2;T3;pH3;SiO2;Cl;T4;pH4;ypkk4;yzkk4;T5;ypkk5;SiO2(2);T6;ydest.;czyst. H2;ypkk6;SiO2;;
I;29-wrz-21 13:25:58;135;13,9;26,3;2,6;0,2;88,9;12,1;9,10;0,89;0,27;10,99;9,01;26,60;29,10;9,15;223;117;29,1;9,18;8,44;Tag not found;29;0,18;(PN);38,75;2,22;99,15;;
II;29-wrz-21 13:26:41;225;15,3;25,4;2,8;0,4;2,0;17,4;8,96;3,14;0,36;6,20;8,88;26,10;28,70;9,24;85;106;30,6;9,17;5,96;5,82;29,3;0,20;(PN);39,24;2,66;98,00;;
III;28-wrz-21 07:11:20;0;0,1;24,4;10,1;2,0;Bad Input;0,6;7,21;2,79;1,47;Bad Input;Bad Input;27,60;24,60;9,86;-3;48;24,9;9,24;5,82;4,35;50,7;1,99;(PN);22,76;Bad Input;Bad Input;;
IV;29-wrz-21 13:26:41;215;15,3;22,4;2,3;0,6;56,5;3,6;8,73;1,81;0,54;10,02;8,78;24,80;26,50;8,91;Bad Input;Bad Input;25,6;9,04;5,33;0,71;26,0;0,30;(PN);39,76;2,57;97,83;;
V;29-wrz-21 13:18:01;222;14,7;26,1;2,5;0,3;113,4;0,2;8,84;3,49;0,14;Bad Input;9,09;26,00;28,65;9,16;63;107;28,7;9,08;4,83;1,91;28,1;0,34;(PN);39,82;1,41;98,46;;
VI;29-wrz-21 13:25:56;199;14,8;24,0;3,3;0,4;36,2;1,5;9,05;2,21;0,33;9,53;9,19;23,90;26,40;9,30;122;106;27,8;9,09;8,58;7,99;27,9;0,17;(PN);39,93;3,04;97,90;;
VII;29-wrz-21 13:26:25;199;14,7;22,2;2,7;0,3;135,0;0,2;8,99;3,10;0,16;6,35;9,11;25,60;25,40;9,11;95;54;25,7;9,36;11,39;4,47;25,1;0,15;(PN);39,17;2,52;98,00;;
IX;29-wrz-21 13:26:13;220;12,9;25,3;3,2;0,2;131,2;3,8;9,14;2,30;0,15;2,53;8,90;24,30;;9,26;;;;;;;(PW);0,15;6,81;39,94;2,56;98,20;0,16;6,86
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
`;
    this.parseCSVLines(csv);
  }

  get findBlockNo() {
    return (csvValue: string): number => {
      for (let i = 0; i < this.bloki.length; i++) {
        if (this.bloki[i][0] === csvValue) {
          return parseInt(this.bloki[i][1].toString());
        }
      }
      return 0;
    };
  }

  get shiftHour() {
    return (tpp, dt) => {
      const shiftHours = [];
      console.log('Serching hours..', tpp, dt);
      (tpp['shifts'] as []).forEach(shift => {
        if (
          shiftHours.find(
            sh =>
              sh.year === shift['year'] &&
              sh.month === shift['month'] &&
              sh.day === shift['day'] &&
              sh.hour === 7 &&
              sh.minute === 0
          ) === undefined
        ) {
          console.log('shift hours undefined', shift['day'], dt.date());
          if (shift['year'] === dt.year() && shift['month'] === dt.month() + 1 && shift['day'] === dt.date()) {
            shiftHours.push({
              month: shift['month'],
              year: shift['year'],
              day: shift['day'],
              hour: shift['hour'],
              minute: shift['minute'],
            });
          }
        }
      });
      console.log('ShiftHours', shiftHours);
      let foundYear = 0;
      let foundMonth = 0;
      let foundDay = 0;
      let foundHour = 0;
      let foundMinute = 0;
      for (let s = shiftHours.length - 1; s >= 0; s--) {
        console.log('ShiftHours', shiftHours, ' s', s, 'hour:', dt.hour(), 'minute:', dt.minutes());
        if (shiftHours[s]['hour'] <= dt.hour() && shiftHours[s]['minute'] <= dt.minutes()) {
          foundYear = shiftHours[s]['year'];
          foundMonth = shiftHours[s]['month'];
          foundDay = shiftHours[s]['day'];
          foundHour = shiftHours[s]['hour'];
          foundMinute = shiftHours[s]['minute'];
          break;
        }
      }
      const foundTime = {
        foundYear: foundYear,
        foundMonth: foundMonth,
        foundDay: foundDay,
        foundHour: foundHour,
        foundMinute: foundMinute,
      };
      console.log('FoundTime', foundTime);
      return foundTime;
    };
  }

  get findParamObject() {
    return (blockNo, dt, objectName) => {
      //console.log('findParm', blockNo, dt.hour(), dt.minutes(), objectName, this.takePlaces);
      //console.log('findParm', blockNo, this.settings.shiftHour, this.settings.shiftMinute, objectName);
      const tpp = this.takePlaces.find(tp => parseInt(tp['symbol']) === blockNo);
      if (tpp !== undefined) {
        //console.log('findParamObject Found TPP', tpp);

        //const foundTime = this.shiftHour(tpp, dt);
        const shift = (tpp['shifts'] as []).find(s => {
          //console.log('SHIFT: ', s);
          return (
            s['year'] === dt.year() &&
            s['month'] === dt.month() + 1 &&
            s['day'] === dt.date() &&
            s['hour'] === this.settings.shiftHour &&
            s['minute'] === this.settings.shiftMinute
          );
        });

        //console.log('findParamObject Found Shift', shift, objectName);
        //(shift['object_types'] as []).forEach(objectType => {
        //console.log('ObjectType: ', objectType['object_type_name']);
        //});
        return (shift['object_types'] as []).find(o => o['object_type_name'] === objectName);
      } else {
        console.log('Couldnt find TPP', blockNo);
        return null;
      }
    };
  }

  dbSettings = [];

  loadAllSettings() {
    const rgx = settings.getSetting(this.dbSettings, consts.Settings.SR_IMPORT_REGEX, ';\r\n|;\n');
    const separator = settings.getSetting(this.dbSettings, consts.Settings.SR_IMPORT_SEPARATOR, ',');

    const t1Start: string = settings.getSetting(this.dbSettings, consts.Settings.SR_START_SHIFT_1_HOUR, '06:00');
    const t1End: string = settings.getSetting(this.dbSettings, consts.Settings.SR_END_SHIFT_1_HOUR, '14:00');

    const t2Start: string = settings.getSetting(this.dbSettings, consts.Settings.SR_START_SHIFT_2_HOUR, '14:00');
    const t2End: string = settings.getSetting(this.dbSettings, consts.Settings.SR_END_SHIFT_2_HOUR, '20:00');

    const t3Start: string = settings.getSetting(this.dbSettings, consts.Settings.SR_START_SHIFT_3_HOUR, '20:00');
    const t3End: string = settings.getSetting(this.dbSettings, consts.Settings.SR_END_SHIFT_3_HOUR, '06:00');

    const t1: string = settings.getSetting(this.dbSettings, consts.Settings.SR_SHIFT_1_HOUR, '07:00');
    const t2: string = settings.getSetting(this.dbSettings, consts.Settings.SR_SHIFT_2_HOUR, '15:00');
    const t3: string = settings.getSetting(this.dbSettings, consts.Settings.SR_SHIFT_3_HOUR, '22:00');

    const pairsToMap = settings
      .getSetting(this.dbSettings, consts.Settings.SR_MAP_IMPORT_VALUE, 'Tag not found=;Bad Input=')
      .split(';');

    const objectTypeAndParams17 = settings
      .getSetting(
        this.dbSettings,
        consts.Settings.SR_OBJECT_TYPE_AND_PARAMS_17,
        'KONDENSAT,T,ypkk,yzkk,O2,Na,pH;WODA ZASILAJĄCA ZA ZWZ,ypkk,yzkk,O2,pH,T;WODA ZASILAJĄCA ZA XW,T,pH;WODA ZASILAJĄCA PRZED ECO,;WODA KOTŁOWA,SiO2,Cl,T,pH,ypkk,yzkk;PARA NASYCONA,T,yzkk,SiO2;DESTYLAT,T,ypkk;WODÓR W GEN.,czyst. H2;PARA ŚWIEŻA,,;PARA WTÓRNA,,'
      )
      .split(';');

    const objectTypeAndParams9 = settings
      .getSetting(
        this.dbSettings,
        consts.Settings.SR_OBJECT_TYPE_AND_PARAMS_9,
        'KONDENSAT,T,ypkk,yzkk,O2,Na,pH;WODA ZASILAJĄCA ZA ZWZ,ypkk,yzkk,O2,pH,T;WODA ZASILAJĄCA ZA XW,,;WODA ZASILAJĄCA PRZED ECO,pH;WODA KOTŁOWA,SiO2,Cl,T,pH,ypkk,yzkk;PARA NASYCONA,,,;DESTYLAT,T,ypkk;WODÓR W GEN.,czyst. H2;PARA ŚWIEŻA,yzkk,SiO2;PARA WTÓRNA,yzkk,SiO2'
      )
      .split(';');

    const mappingParam = settings
      .getSetting(
        this.dbSettings,
        consts.Settings.SR_MAPPING_PARAM,
        'y₂₅pkk,ypkk;y₂₅zkk,yzkk;TPom,T;O₂,O2;NH₄,NH4;SIO₂,SiO2;H₂,czyst. H2'
      )
      .split(';');

    const hoursRange = [
      { hour: t1, from: t1Start, to: t1End },
      { hour: t2, from: t2Start, to: t2End },
      { hour: t3, from: t3Start, to: t3End },
    ];
    const shiftTime = helpers.getHourBasedOnPeriods(moment(), hoursRange);
    const dateFormatString = settings.getSetting(
      this.dbSettings,
      consts.Settings.SR_IMPORT_DATE_FORMAT,
      'YYYY-MM-DD HH:mm:ss'
    );

    const shiftHour = parseInt(shiftTime.split(':')[0]);
    const shiftMinute = parseInt(shiftTime.split(':')[1]);

    this.dictToMap = pairsToMap.map(pair => {
      if (pair.split('=')[1] !== undefined) {
        return { string: pair.split('=')[0], value: pair.split('=')[1] };
      } else {
        return { string: pair.split('=')[0], value: '' };
      }
    });

    this.SRAllObjectTypes17 = objectTypeAndParams17;
    for (let i = 0; i < this.SRAllObjectTypes17.length; i++) {
      this.SRAllObjectTypes17[i] = this.SRAllObjectTypes17[i].split(',');
    }
    this.SRAllObjectTypes9 = objectTypeAndParams9;
    for (let i = 0; i < this.SRAllObjectTypes9.length; i++) {
      this.SRAllObjectTypes9[i] = this.SRAllObjectTypes9[i].split(',');
    }

    this.importSRMappingParam = mappingParam.map(param => {
      return { dbSymbol: param.split(',')[0], csvSymbol: param.split(',')[1] };
    });

    this.settings = {
      regex: rgx,
      separator: separator,
      shiftHour: shiftHour,
      shiftMinute: shiftMinute,
      dateFormatString: dateFormatString,
    };
    console.log('Settings:', rgx, separator, shiftHour, shiftMinute, dateFormatString);
  }

  toFloat(inValue) {
    return inValue.replace(',', '.');
  }

  async parseCSVLines(csv) {
    const settings = this.loadAllSettings();

    console.log('Shift TIME', this.settings.shiftHour, this.settings.shiftMinute);
    const allLines = csv.split(new RegExp(this.settings.regex));
    console.log('allLines', allLines);

    this.allLinesCount = allLines.length - 1;
    for (let i = 1; i < allLines.length; i++) {
      console.log('\nLINE[', i, ']:', allLines[i]);
      this.counter++;
      if (allLines[i].split(this.settings.separator)[0] !== '') {
        console.log(
          '#####################################################################################################'
        );
        console.log(
          '################################################ [' +
            i +
            '] ' +
            allLines[i].split(this.settings.separator)[0] +
            ' ##################################################################'
        );
        console.log(
          '####################################################################################################'
        );
      }
      if (this.terminate) {
        this.terminate = false;
        this.uploading = false;
        break;
      }

      let line = allLines[i];
      if (line === '') {
        continue;
      }
      line = line.replace(/"/g, '');

      const sampleCsvParamHeader = allLines[1].split(this.settings.separator);
      const sampleCsvRow = line.split(this.settings.separator);
      console.log('ROW[' + i + ']:', sampleCsvRow);

      moment.locale('pl');

      const dt = moment(sampleCsvRow[1], this.settings.dateFormatString);
      const blockNo = this.findBlockNo(sampleCsvRow[0]);

      // Mapping
      //for (let j = 0; j < consts.ImportObjectTypeMappingArray.length; j++) {
      //csv = csv.replace(consts.ImportObjectTypeMappingArray[j][0], consts.ImportObjectTypeMappingArray[j][1]);
      //}

      if (sampleCsvRow[0] !== '') {
        console.log('Blok:', blockNo, 'Data:', dt.format(DATE_TIME_FORMAT));

        console.log(
          'Lookin for take place',
          blockNo,
          this.takePlaces.length,
          this.settings.shiftHour,
          this.settings.shiftMinute
        );

        // ITER BLOKI
        const tpp = this.takePlaces.find(tp => parseInt(tp['symbol']) === blockNo);
        if (tpp !== undefined) {
          const shift = (tpp['shifts'] as []).find(s => {
            console.log('Checking Shift', s['year'], s['month'], s['day'], s['hour'], s['minute']);
            return (
              s['year'] === dt.year() &&
              s['month'] === dt.month() + 1 &&
              s['day'] === dt.date() &&
              s['hour'] === this.settings.shiftHour &&
              s['minute'] === this.settings.shiftMinute
            );
          }) as {};
          if (shift !== undefined) {
            //console.log('Shift found', shift, tpp['shifts'], shift['id']);
            // SHIFT DATA saving
            console.log('Shift found', shift['id']);
            const power = round(this.toFloat(sampleCsvRow[2]));
            const pressure = round(this.toFloat(sampleCsvRow[3]), 1);
            let resp = await API.setShiftValue({
              id: shift['id'],
              power: power,
              pressure: pressure,
              user: this.currentUser,
            }).catch(function (error) {
              console.log(error);
              helpers.error(
                'Wystąpił błąd podczas aktualizacji zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' + error
              );
              return;
            });
            shift['power'] = power;
            shift['pressure'] = pressure;

            // PARAMS DATA saving
            let indx = 4;
            let objectTypeName = '';
            let paramObject = null;
            let dbParamSymbolText = null;
            let paramSymbol = '';
            let allObjectTypes = this.SRAllObjectTypes17;
            if (blockNo === BLOCK_BIO_NUMBER) {
              allObjectTypes = this.SRAllObjectTypes9;
              console.log('Blok BIO');
            }
            // ITER OBJECT TYPES
            for (let objType = 0; objType < allObjectTypes.length; objType++) {
              objectTypeName = allObjectTypes[objType][0];
              paramObject = this.findParamObject(blockNo, dt, objectTypeName); // szukam typu obiektu np. KONDENSAT dla daty, godziny, bloku, i nazwy typu obiektu
              if (!paramObject) {
                console.log('--brak ', objectTypeName);
                indx += allObjectTypes[objType].length - 1;
                continue;
              }
              console.log(paramObject['id'], paramObject['object_type_name']);
              for (let p = 1; p < allObjectTypes[objType].length; p++) {
                console.log('PARAM ', p, sampleCsvParamHeader[indx], indx, allObjectTypes[objType][p]);
                if (allObjectTypes[objType][p].length === 0) {
                  console.log('Empty param, omitting..');
                  indx++;
                  continue;
                }
                dbParamSymbolText = this.importSRMappingParam.find(pp => pp.csvSymbol === allObjectTypes[objType][p]);
                if (dbParamSymbolText !== undefined) {
                  paramSymbol = dbParamSymbolText['dbSymbol'];
                } else {
                  paramSymbol = allObjectTypes[objType][p];
                }
                if (paramObject !== undefined && 'params' in paramObject) {
                  console.log('paramObject.params', paramObject.params);
                  let mappedValue = '';
                  const param = paramObject.params.find(p => p.symbol_text === paramSymbol);
                  for (let index = 0; index < this.dictToMap.length; index++) {
                    if (sampleCsvRow[indx].includes(this.dictToMap[index].string)) {
                      mappedValue = this.dictToMap[index].value;
                      break;
                    } else {
                      mappedValue = sampleCsvRow[indx];
                    }
                  }
                  if (param !== undefined) {
                    resp = await API.setParamValue({
                      id: param.id,
                      value: mappedValue,
                      user: this.currentUser,
                    }).catch(function (error) {
                      console.log(error);
                      helpers.error(
                        'Wystąpił błąd podczas aktualizacji parametru, prosimy o zgłoszenie błędu do administratora.\n\n' +
                          error
                      );
                      return;
                    });
                    param.value = mappedValue;
                  }

                  console.log(
                    '    ',
                    paramSymbol,
                    ' = ',
                    sampleCsvRow[indx],
                    ' dbParam:',
                    dbParamSymbolText
                    //'PARAM:',
                    //param
                  );
                }
                indx++;
              }
            }
          } else {
            console.log('Shift not found');
          }
        }
      }
    }
    console.log('Finished...');
    this.uploading = false;
    this.disabledOkButton = false;
    clearInterval(this.dotsInterval);
  }

  get currentUser() {
    return this.$store.state.currentUser;
  }

  async loadInitialData() {
    let response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
    }
    response = await API.fetchSettings();
    this.dbSettings = response.data.settings;
    //console.log('settings', this.settings);
  }

  async importFileChange(e) {
    await this.loadInitialData();

    console.log('Import start', e.target.files[0]);
    this.uploading = true;
    const reader = new FileReader();
    this.modal.showModal();

    reader.onload = async function (theFile) {
      console.log('theFile', theFile);
      this.parseCSVLines(reader.result.toString());
    }.bind(this);

    reader.readAsText(e.target.files[0], 'UTF-8');
  }
  stopClick() {
    this.disabledOkButton = false;
    this.terminate = true;
  }
}
