





































































import { Component, Vue, Ref } from 'vue-property-decorator';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import MinimalGenericList from '../../../components/spec/MinimalGenericList.vue';
import * as helpers from '../../../helpers';
import Modal from '../../../components/spec/Modal.vue';
import FileManager from '../../../components/spec/FileManager.vue';
import LabeledInput from '../../../views/FormComponents/LabeledInput.vue';
import Checkbox from '../../../views/FormComponents/Checkbox.vue';
import * as API from '../../../store/api';
import * as consts from '../../../consts';
import { ExtendedParam, StandardMultiselectEngine, DocTemplate } from '../../../types';
import { Guid } from 'guid-typescript';

@Component({
  components: {
    TopPanel,
    GenericList,
    FileManager,
    GenericForm,
    Header,
    Modal,
    Checkbox,
    Footer,
    MinimalGenericList,
    LabeledInput,
  },
})
export default class DocTemplateList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly mainList: GenericList;
  @Ref() readonly fileManager: FileManager;
  params = [];
  @Ref() readonly modal: Modal;
  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  object = {};
  docTemplate = new DocTemplate();

  docTemplateParams = new Array<any>();
  docTemplatedParams = new Array<any>();
  filterObject = {};
  guidValue = '';
  modalTitle = '';
  copyFilterObject = {};
  url = '';
  guid: any;
  filterParams = new Array<ExtendedParam>();

  currFiles = [];

  @Ref() filterForm: GenericForm;

  processLoadedElement(object: any) {
    console.log(object, 'Loaded templates...');
  }

  async showModal(object: DocTemplate) {
    this.docTemplate = Object.assign(new DocTemplate(), object);
    this.guid = Guid.create();
    this.guidValue = this.guid.value;
    if (!this.docTemplate.id) {
      this.docTemplate = new DocTemplate();
      this.modalTitle = 'Dodaj nowy szablon';
      this.currFiles = [];
    } else {
      this.modalTitle = 'Edycja szablonu';
      this.docTemplate.limited_sample_count = !!this.docTemplate.maximum_sample_count;
      if (this.docTemplate.attachement) {
        this.currFiles = [this.docTemplate.attachement];
      } else {
        this.currFiles = [];
      }
    }

    this.modal.showModal();
  }
  async saveModal(e: any) {
    console.log('saving', this.docTemplate);

    await this.fileManager.deleteMarkedFiles();

    if (this.docTemplate.attachement && this.fileManager.fileArray.length !== 0) {
      this.docTemplate.attachement = this.fileManager.fileArray[0];
      this.docTemplate.attachement_id = this.docTemplate.attachement.id;
    } else {
      this.docTemplate.attachement = null;
      this.docTemplate.attachement_id = null;
    }

    if (this.guidValue) {
      this.docTemplate.guid = this.guidValue;
    }

    const response = await API.saveDocTemplate(this.docTemplate);
    if (response.data.symbol_exists) {
      helpers.error('Istnieje już dokuemnt, który ma przypisany taki sam symbol');
    } else {
      helpers.requestSucess();
      this.mainList.fillObjects();
    }
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }
  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, e);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    const objects = await helpers.getDictAsArray(consts.DictType.OBJECT);
    const sources = await helpers.getDictAsArray(consts.DictType.SOURCE);
    const objectTypes = await helpers.getDictAsArray(consts.DictType.OBJECT_TYPE);

    [objects, sources, objectTypes].forEach(el => {
      el.unshift({
        id: null,
        value: 'DOWOLNE',
      });
    });

    this.docTemplateParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szablonu',
      },
      {
        fieldType: 'input',
        fieldName: 'symbol',
        headerName: 'Symbol',
      },
      {
        fieldType: 'input',
        fieldName: 'version',
        headerName: 'Wersja dokumentu',
      },
      {
        fieldType: 'multiselect',
        engine: new StandardMultiselectEngine('client_type', 'client_type'),
        headerName: 'Typ klienta',
        multiSelectTrack: 'name',
        options: [
          {
            name: 'DOWOLNY',
            client_type: consts.docTemplateClientType.ANY,
          },
          {
            name: 'Zewnętrzny',
            client_type: consts.docTemplateClientType.EXTERNAL_CLIENT,
          },
          {
            name: 'Wewnętrzny',
            client_type: consts.docTemplateClientType.INTERNAL_CLIENT,
          },
        ],
      },
      {
        fieldType: 'checkbox',
        fieldName: 'accreditation',
        headerName: 'Akredytowany',
      },
      {
        fieldType: 'multiselect',
        value: '$object_id',
        headerName: 'Obiekt',
        engine: new StandardMultiselectEngine('object_id', 'id'),
        options: objects,
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$source_id',
        headerName: 'Pochodzenie',
        engine: new StandardMultiselectEngine('source_id', 'id'),
        options: sources,
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$object_type_id',
        headerName: 'Typ obiektu',
        engine: new StandardMultiselectEngine('object_type_id', 'id'),
        options: objectTypes,
        multiSelectTrack: 'value',
      },
    ];

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szablonu',
      },
      {
        fieldType: 'multiselect',
        value: '$object_id',
        headerName: 'Obiekt',
        engine: new StandardMultiselectEngine('object_id', 'id'),
        options: await helpers.getDictAsArray(1),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$source_id',
        headerName: 'Pochodzenie',
        engine: new StandardMultiselectEngine('source_id', 'id'),
        options: await helpers.getDictAsArray(6),
        multiSelectTrack: 'value',
      },
      {
        fieldType: 'multiselect',
        value: '$object_type_id',
        headerName: 'Typ obiektu',
        engine: new StandardMultiselectEngine('object_type_id', 'id'),
        options: await helpers.getDictAsArray(4),
        multiSelectTrack: 'value',
      },
    ];

    //------------wyswietlane---------------
    this.params = [
      {
        value: '$name',
        headerName: 'Nazwa',
        columnCss: 'col-3 p-0 text-left font-16',
        headerCss: 'col-3  p-0 text-center',
      },
      {
        value: '$symbol',
        headerName: 'Symbol',
        sortField: 'type',
        headerCss: 'col-1 p-0',
      },
      {
        fieldType: 'icon',
        headerName: 'ZAŁĄCZNIK',
        headerCss: 'col-1 p-0',
        icon: object => {
          return 'file-pdf';
        },
        size: 'lg',
        key: 0,
        css: 'word-icon',
        visible: (object: any) => {
          return true;
        },
        action: async (object: any, e: any) => {
          this.url = process.env.VUE_APP_API_URL + 'media/' + object.attachement.path;
          await this.$forceUpdate();
          document.getElementById('download').click();
        },
      },
      {
        value: '$version',
        headerName: 'Wersja dokumentu',
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0 text-center',
      },
      {
        value: (template, param) => {
          if (!template.object) return 'DOWOLNY';
          return template.object.value;
        },
        headerName: 'Obiekt',
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0 text-center',
      },
      {
        value: (template, param) => {
          if (!template.source) return 'DOWOLNE';
          else return template.source.value;
        },
        headerName: 'Pochodzenie',
        columnCss: 'col-1 p-0',
        headerCss: 'col-1  p-0 text-center',
      },
      {
        value: (template, param) => {
          if (!template.object_type) {
            return 'DOWOLNY';
          } else return template.object_type.value;
        },
        headerName: 'Typ obiektu',
        columnCss: 'col-2 p-0',
        headerCss: 'col-2  p-0 text-center',
      },
      {
        headerName: 'Operacje',
        fieldType: 'rec',
        hideHeader: true,
        childParams: [
          {
            hideHeader: true,
            value: 'Aktywne',
            fieldCss: 'clickable-text font-12',
            action: async (obj, param) => {
              obj.active = true;
              const repsonse = await helpers.saveObjectModel(obj, 'DocTemplate');
              if (repsonse.data.success) {
                helpers.info('Pomyślnie aktywowano metodę.');
              }
            },
          },
          {
            hideHeader: true,
            value: 'Niekatywne',
            fieldCss: 'clickable-text font-12',
            action: async (obj, param) => {
              obj.active = false;
              const repsonse = await helpers.saveObjectModel(obj, 'DocTemplate');
              if (repsonse.data.success) {
                helpers.info('Pomyślnie zdezaktywowano metodę.');
              }
            },
          },
        ],
      },
    ];
    //-------------------------------------
  }
}
