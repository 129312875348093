












































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import ExamCardHeader from '../../../components/spec/ExamCardHeader.vue';
import ModalResultCard from '../../../components/spec/ModalResultCard.vue';
import Footer from '../../../components/dummy/Footer.vue';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import * as API from '../../../store/api';
import * as consts from '../../../consts';
import SuperSubScriptDisplayer from '../../../components/SuperSubScriptDisplayer.vue';

const SAMPLES_BOARD = 'TABLICA PRÓBEK';
const READINGS_BOARD = 'TABLICA ODCZYTÓW';

@Component({
  components: { TopPanel, ExamCardHeader, Footer, VueHtml2pdf, ModalResultCard, SuperSubScriptDisplayer },
})
export default class ExamCard extends Vue {
  @Ref() readonly protocolpdf: VueHtml2pdf;
  @Ref() readonly modalResultCard: ModalResultCard;

  currStageIndex = 1;
  exam_id = -1;
  exam = null;
  sample = null;
  canUserDoExam = false;
  tests = [];
  currentTest = null;
  changeKey = 0;
  sampleNorm = {};
  stateNorm = {};
  sampleNormId = null;
  stateNormId = null;
  sampleNormValue = '';
  stateNormValue = '';
  structures = [];
  dicts = [];
  source = null;
  enabled = true;
  board = SAMPLES_BOARD;
  labsys = false;

  get start() {
    console.log('%c---------- Start of Exam Card template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of Exam Card template -----------', 'color:green;font-weight:600');
    return '';
  }

  goToBoard() {
    if (this.board === READINGS_BOARD) {
      this.$router.push({
        path: '/lab/readingsboard',
      });
    } else {
      this.$router.push({
        path: '/lab/samplesboard',
        query: { no: this.sample.number, listView: 'false' },
      });
    }
  }

  finalResult(test) {
    console.log('TEST finalResult', test);
    const finalParam = test.sampleTestParams.find(stp => stp.is_final_result);
    return finalParam;
  }
  isOneFinalResult(test) {
    return this.getTestFinalParams(test).length <= 1;
  }
  getTestFinalParams(test) {
    const finalParams = test.sampleTestParams.filter(stp => {
      return stp.is_final_result;
    });
    return finalParams.sort((a, b) => {
      return parseInt(a.index) - parseInt(b.index);
    });
  }
  get normInternal() {
    return this.sample.client_type === consts.ClientType.INTERNAL;
  }
  get normExternal() {
    return this.sample.client_type === consts.ClientType.EXTERNAL;
  }
  async resultCardShow(test: any) {
    test.dropped = false;
    this.currentTest = test;
    console.log('CURRTEST', this.currentTest);
    let response = await API.fetchTest({ number: test.number });
    response = await API.fetchUserMethodAuths({
      test_id: response.data.test.id,
      auth_type: consts.TestAuthTypesSym.EXAM,
    });
    const authUsersIds = response.data.method_auths.map(aui => aui.user_id);
    const user = this.$store.state.currentUser;
    if (authUsersIds.includes(user.id)) {
      this.canUserDoExam = true;
    } else {
      this.canUserDoExam = false;
    }
    const params = new Object();
    params['currentTest'] = this.currentTest;
    params['sample'] = this.sample;
    params['canUserDoExam'] = this.canUserDoExam;
    this.modalResultCard.showModal(params);
  }
  async modalResultCardOK(params: any) {
    console.log('modalOK', params, params.savedTest, this.tests);
    const ind = this.tests.findIndex(t => t.id === params.savedTest.id);
    this.tests[ind] = params['savedTest'];
    console.log('HIDE OK', params['savedTest']);
    this.changeKey++;
    this.$forceUpdate();
  }
  async modalResultCardCancel(params: object) {
    console.log('modalCancel');
    const ind = this.tests.findIndex(t => t.id === params['savedTest'].id);
    this.tests[ind] = params['savedTest'];
    console.log('HIDE CANCEL', params['savedTest']);
    this.changeKey++;
    this.$forceUpdate();
  }
  dropRow(test) {
    const drp = test.dropped;
    test.dropped = !drp;
    this.tests.find(t => t.id === test.id).dropped = !drp;
    console.log('dropped', this.tests);
    console.log(test);
    this.$forceUpdate();
  }
  get status() {
    return consts.SampleStatus.byId(this.sample.status);
  }

  get sampleMethods() {
    let methods = '';
    // if (this.sample.client_type !== consts.ClientType.INTERNAL || this.sample.take_method_id === undefined) {
    if (this.sample.client_type !== consts.ClientType.INTERNAL) {
      return this.sample.take_method_string;
    } else {
      if ('take_methods' in this.sample) {
        for (let i = 0; i < this.sample['take_methods'].length; i++) {
          const m = this.sample['take_methods'][i];
          if (i !== 0) {
            methods += ', ';
          }
          methods += m['take_method__value'];
        }
      }
    }
    return methods;
  }

  get samplePlaces() {
    let places = '';
    // if (this.sample.client_type !== consts.ClientType.INTERNAL || this.sample.take_place_id === undefined) {
    if (this.sample.client_type !== consts.ClientType.INTERNAL) {
      return this.sample.take_place_string;
    } else {
      if ('take_places' in this.sample) {
        for (let i = 0; i < this.sample['take_places'].length; i++) {
          const m = this.sample['take_places'][i];
          if (i !== 0) {
            places += ', ';
          }
          places += m['take_place__value'];
        }
      }
    }
    return places;
  }

  get sampleArea() {
    return this.sample.sample_area;
  }

  async created() {
    console.log('######### ID', this.$route.params['id']);
    const id = this.$route.params['id'];
    if (this.$route.params['readings'] === 'true') {
      this.board = READINGS_BOARD;
    }
    const response = await API.fetchSampleAndTests(parseInt(id));
    if (response.data.success) {
      this.sample = response.data.sample;
      this.tests = response.data.sample_tests;
      this.tests.forEach(t => {
        t.dropped = false;
      });
      this.currentTest = this.tests[0];
      this.sample['tests'] = this.tests;
      console.log('################## SAMPLE', this.sample);
      console.log('################## TESTS', this.tests);
      this.labsys = [consts.DocSymbols.F11_6, consts.DocSymbols.F11_9].includes(this.sample.biomass_source);
    }
  }
}
