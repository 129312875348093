




























































































































































































































































































































































































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import * as API from '../../store/api';
import * as consts from '../../consts';
import * as types from '../../types';
import * as helpers from '../../helpers';
import TopPanel from '../../components/dummy/TopPanel.vue';
import ProfileMenu from '../../components/dummy/ProfileMenu.vue';
import Modal from '../../components/spec/Modal.vue';
import FileManager from '../../components/spec/FileManager.vue';
import AdditionalSampleModal from '../../components/spec/AdditionalSampleModal.vue';
import moment from 'moment';
import ImportReadingsCSV from '../../components/spec/ImportReadingsCSV.vue';
import * as settings from '../../helpers/settings';
import { Guid } from 'guid-typescript';

const defaultColWidth = 100;
const OPER_EXAM_CARD = 1;
const SAMPLE_EXTENDED = 2;

@Component({
  components: {
    TopPanel,
    ProfileMenu,
    Modal,
    FileManager,
    AdditionalSampleModal,
    ImportReadingsCSV,
  },
})
export default class ReadingsBoard extends Vue {
  @Ref() readonly acceptModal: Modal;
  @Ref() readonly acceptXLSModal: Modal;
  @Ref() readonly sampleCreateModal: Modal;
  @Ref() readonly operModal: Modal;
  @Ref() readonly uploadModal: Modal;
  @Ref() readonly additionalSampleModal: AdditionalSampleModal;
  @Ref() readonly fileManager: FileManager;

  takePlace10Name = consts.BLOK_POZA;
  paramWidth = 100;
  time = null;
  spinner = false;
  spinner1 = false;
  spinner10 = false;

  spinnerMessage = 'Ładuje dane...';

  colors = [];
  placeIndex = 1;
  operation = 0;
  sampleExtendedInputVisible = false;
  sampleExtendedValue = '';
  sampleExtendedKey = 0;
  additionalSampleKey = 0;
  //shiftHours = [];
  ifValid = true;
  fileBloki = {};
  guidValue = '';
  guid: any;
  currFiles = [];

  insertDate = moment().format(consts.DB_DATE_FORMAT);
  selectedPlaces = [1, 2, 3, 4, 5, 6, 7, 9, 10];
  places = [
    {
      text: 'Blok 1',
      value: 1,
    },
    {
      text: 'Blok 2',
      value: 2,
    },
    {
      text: 'Blok 3',
      value: 3,
    },
    {
      text: 'Blok 4',
      value: 4,
    },
    {
      text: 'Blok 5',
      value: 5,
    },
    {
      text: 'Blok 6',
      value: 6,
    },
    {
      text: 'Blok 7',
      value: 7,
    },
    {
      text: 'Blok 9',
      value: 9,
    },
  ];

  blockPOZA = {
    take_place_name: consts.BLOK_POZA,
    value: 10,
  };

  hoverDate = null;
  hoverTime = null;

  async removeShift(params) {
    console.log('Params', params);
    await API.deleteShift(params.param.shift)
      .then(response => {
        if (response.status === 201) {
          helpers.info('Zmiana usunięta');
          this.takePlaces.forEach(tp => {
            tp.shifts = tp.shifts.filter(s => s.id !== params.param.shift);
          });
        }
      })
      .catch(response => {
        if (response.status >= 301) {
          helpers.error('Błąd przy komunikacji z serwerem: ');
        }
      });
  }

  get mediaURL() {
    return process.env.VUE_APP_API_URL + 'media';
  }

  get blokiXLSX() {
    const blokiYear = this.insertDate.substring(2, 4);
    return `${this.mediaURL}/ReadingsBoard/bloki${blokiYear}.xlsx`;
  }

  async getXLSX() {
    const resp = await API.checkExportXLS();
    if (resp.data.status !== consts.PROCESS_STATUS_FREE) {
      helpers.warn('Plik jest w trakcie generowania, spróbuj za chwilę, wykonywana akcja: ' + resp.data.progress);
    } else {
      document.getElementById('downloadXLSX').click();
    }
  }

  async startExportXLSX() {
    const resp = await API.checkExportXLS();
    if (resp.data.status !== consts.PROCESS_STATUS_FREE) {
      helpers.warn('Plik jest w trakcie generowania, spróbuj za chwilę, wykonywana akcja: ' + resp.data.progress);
    } else {
      this.acceptXLSModal.showModal();
    }
  }

  async exportXLSX() {
    console.log('Export');
    const resp = await API.exportXLS({ user: this.currentUser });
    if (resp.data.status === 0) {
      helpers.info('Proces eksportu danych do pliku XLS rozpoczęty...');
    } else {
      helpers.warn('UWAGA: Proces eksportu danych w trakcie, wykonywana akcja: ' + resp.data.progress);
    }
  }

  async fileModal() {
    this.currFiles = [];
    this.guid = Guid.create();
    this.guidValue = this.guid.value;
    const resp = await API.fetchFile('ReadingsBoard');
    if (resp.data.success === true && resp.data.file) {
      this.currFiles.push(resp.data.file);
    }
    this.uploadModal.showModal();
  }

  async saveXLSX(e: any) {
    this.ifValid = true;
    this.$root.$emit('ifValue', true);
    if (this.fileManager.fileArray.length !== 0) {
      if (this.fileManager.fileArray[0].name !== `bloki${this.insertDate.substring(2, 4)}.xlsx`) {
        this.ifValid = false;
        this.$root.$emit('ifValue', false);
        helpers.error(`Wymagana nazwa pliku to: bloki${this.insertDate.substring(2, 4)}.xlsx`);
      } else {
        await this.save();
      }
    } else {
      await this.save();
    }
  }

  async save() {
    console.log('saving');
    await this.fileManager.deleteMarkedFiles();
    helpers.requestSucess();
    this.ifValid = true;
    this.$root.$emit('ifValue', true);
  }

  get getColor() {
    return (index, isParam = false, isReading = false) => {
      if (this.colors.length > index) {
        if (isParam === true && isReading === false) {
          return 'rgb(255,175,140)';
        }
        return this.colors[index];
      } else {
        return 'rgb(255,255,255)';
      }
    };
  }

  additionalSample() {
    this.additionalSampleModal.showModal();
  }

  async addAdditionalSample(params) {
    this.spinner = true;
    console.log('ADD MOdal ', params);
    await this.loadAdditionalSamples();
    this.spinner = false;
  }

  showAcceptModal() {
    this.acceptModal.showModal();
  }

  setHoverDateTime(date, time) {
    this.hoverDate = date;
    this.hoverTime = time;
  }

  singleClick() {
    this.time = null;
    this.time = setTimeout(() => {
      console.log('single click ing');
    }, 300);
  }
  doubleClick(place, objectType, param, day, hour) {
    console.log(place, objectType, param.id, day, hour);
    let p = {};
    const params = this.getObjectTypeParams(9, place.take_place_name, objectType.object_type_name, day, hour);
    if (params.length > 0) {
      p = params.find(p => p.symbol === param.symbol && p.is_reading === param.is_reading);
    }
    console.log('Param clicked', p);
    if (!param.is_reading) {
      this.operModal.showModal({ param: p, place: place });
    }
  }

  async operSelect(params) {
    console.log('operSelect params', params, params.param.sample);
    if (this.operation === OPER_EXAM_CARD) {
      this.$router.push({
        path: 'lab/samplesboard/examcard/',
        name: 'examcard',
        params: {
          id: params.param.sample,
          readings: 'true',
        },
      });
    } else if (this.operation === SAMPLE_EXTENDED) {
      this.sampleExtendedInputVisible = false;
      const resp = await API.setParamExtendedValue({
        id: params.param['id'],
        description: params.param['description'],
        user: this.currentUser,
      })
        .catch(function (error) {
          console.log(error);
          helpers.error(
            'Wystąpił błąd podczas aktualizacji parametru, prosimy o zgłoszenie błędu do administratora.\n\n' + error
          );
        })
        .then(function () {
          helpers.info('Opis próbki rozszerzonj dodany...', 3000, 'bottomright');
        });
    }
  }
  sampleCard() {
    this.operation = OPER_EXAM_CARD;
    this.operModal.saveModal();
  }
  sampleExtended() {
    this.sampleExtendedKey++;
    console.log('sampleExtended', this.sampleExtendedInputVisible);
    if (this.sampleExtendedInputVisible === true) {
      console.log('sampleExtended ', SAMPLE_EXTENDED);
      this.operation = SAMPLE_EXTENDED;
      this.operModal.saveModal();
      this.sampleExtendedInputVisible = false;
    } else {
      this.sampleExtendedInputVisible = true;
    }
  }

  get sectionWidth() {
    return (place: any, objectType, day = '', hour = '') => {
      if (day === '' || hour === '') {
        if (this.takePlaces.length > 0 && this.takePlaces[0].shifts.length > 0) {
          day = this.takePlaces[0].shifts[0].date;
          hour = this.takePlaces[0].shifts[0].time;
        } else {
          return defaultColWidth * 5;
        }
      }

      const choosenPlace = this.takePlaces?.find(p => p.take_place_name === place.take_place_name);
      const choosenShift = choosenPlace?.shifts.find(s => s.date === day && s.time === hour);
      const choosenObjectType = choosenShift?.object_types.find(
        r => r.object_type_name === objectType.object_type_name
      );
      if (!choosenPlace || !choosenShift || !choosenObjectType) {
        return defaultColWidth * 5;
      }
      return choosenObjectType.params.length * this.paramWidth;
    };
  }

  get sectionsWidth() {
    return (place: any, day = '', hour = '') => {
      const choosenTakePlace = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      if (day === '' || hour === '') {
        if (this.takePlaces.length > 0 && choosenTakePlace && choosenTakePlace.shifts.length > 0) {
          day = choosenTakePlace.shifts[0].date;
          hour = choosenTakePlace.shifts[0].time;
        } else {
          return defaultColWidth * 4;
        }
      }
      let count = 0;

      //console.log('sectionsWidth: choosenPlace', choosenTakePlace, day, hour);
      const choosenShift = choosenTakePlace.shifts.find(s => s.date === day && s.time === hour);
      choosenShift.object_types.forEach(rd => {
        count += rd.params.length;
      });
      return count * this.paramWidth;
    };
  }

  loadMore() {
    alert('Jeszcze nie zaimplementowane');
  }

  get currentUser() {
    return this.$store.state.currentUser;
  }

  async initiateDay(force = false) {
    this.spinner = true;
    this.spinnerMessage = 'Sprawdzanie zmian...';
    const dt = moment(this.insertDate, consts.DB_DATE_FORMAT).format(consts.DB_DATE_FORMAT);
    const resp = await API.initiateDay({
      date: dt,
      places: this.selectedPlaces.filter(p => p !== 10),
      user: this.currentUser,
      force: force,
    });
    if (resp && resp.data.shift_inserted > 0) {
      //helpers.info(resp.data.shift_inserted / this.takePlaces.length + ' zmiany zainicjowane');
      helpers.info('Dzień ' + dt + ' zainicjowany', 3000, 'bottomRight');
    }
    await this.loadShifts();
    await this.loadAdditionalSamples();
    this.spinner = false;
    this.placeIndex++;
    return resp;
  }

  get inputPowerValue() {
    return (place, day, hour) => {
      const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      const sh = tp.shifts.find(s => s.date === day && s.time === hour);
      return sh ? sh.power : null;
    };
  }

  get inputPressureValue() {
    return (place, day, hour) => {
      const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      const sh = tp.shifts.find(s => s.date === day && s.time === hour);
      return sh ? sh.pressure : null;
    };
  }

  get inputInputPersonValue() {
    return (place, day, hour) => {
      const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      const sh = tp.shifts.find(s => s.date === day && s.time === hour);
      return sh ? sh.input_person : null;
    };
  }

  get inputCommentValue() {
    return (place, day, hour) => {
      const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      const sh = tp.shifts.find(s => s.date === day && s.time === hour);
      return sh ? sh.comment : null;
    };
  }

  get inputTakePlace10() {
    return (place, day, hour) => {
      const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      //console.log('TP', tp.take_place_name);
      const sh = tp.shifts.find(s => s.date === day && s.time === hour);
      //console.log('PLACE', place, day, hour, sh);
      return sh ? sh.take_place_10 : '';
    };
  }

  get inputInputPersonPlace10Value() {
    return (place, day, hour) => {
      const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      const sh = tp.shifts.find(s => s.date === day && s.time === hour);
      return sh ? sh.input_person : '';
    };
  }

  get inputCommentPlace10Value() {
    return (place, day, hour) => {
      const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
      const sh = tp.shifts.find(s => s.date === day && s.time === hour);
      return sh ? sh.comment : null;
    };
  }

  get inputValue() {
    return (place, objectType, param, day, hour, tag) => {
      let retVal = null;
      if (place.take_place_name === 'Blok 1' && tag === 2 && objectType.object_type_name === 'KONDENSAT') {
        //console.log('TAG', tag, '  ', place.take_place_name, objectType.object_type_name, day, hour, param);
      }
      const params = this.getObjectTypeParams(10, place.take_place_name, objectType.object_type_name, day, hour);
      if (params.length > 0) {
        const pars = params.filter(p => p.symbol === param.symbol);

        if (pars.length === 0) {
          if (place.take_place_name === 'Blok 1' && tag === 2 && objectType.object_type_name === 'KONDENSAT') {
            /*console.log(
              '    EMPTY PARS:',
              tag,
              '  ',
              place.take_place_name,
              objectType.object_type_name,
              day,
              hour,
              params
            );*/
          }
          return null;
        }

        pars.forEach(prs => {
          if (place.take_place_name === 'Blok 1' && tag === 2 && objectType.object_type_name === 'KONDENSAT') {
            /*
            console.log(
              '    PRS',
              tag,
              '  ',
              prs.symbol,
              'is_reading',
              prs.is_reading,
              param.is_reading,
              prs.id,
              param.id,
              params
            );*/
          }
          if (
            prs.is_reading === param.is_reading &&
            prs.test_id === param.test_id &&
            prs.symbol_text === param.symbol_text
          ) {
            if (!prs['value']) {
              prs['value'] = '';
            }
            if (place.take_place_name === 'Blok 1' && tag === 2 && objectType.object_type_name === 'KONDENSAT') {
              //console.log('        Returning value [', prs['value'], ']');
            }
            retVal = prs['value'];
          }
        });
      } else {
        console.log('No params', place.take_place_name, objectType.object_type_name, day, hour, param);
        if (place.take_place_name === 'Blok 1' && tag === 2 && objectType.object_type_name === 'KONDENSAT') {
          //console.log('    EMPTY PARAMS:', tag, '  ', place.take_place_name, objectType.object_type_name, day, hour);
        }
        retVal = null;
      }
      return retVal;
    };
  }

  async inputChange(e, place, objectType, param, day, hour) {
    const params = this.getObjectTypeParams(11, place.take_place_name, objectType.object_type_name, day, hour);
    console.log('VALUE:', place.take_place_name, objectType.object_type_name, day, hour, e.target.value, param, params);
    const p = params.find(p => p.id === param.id && p.is_reading === param.is_reading);
    const prevValue = p['value'];
    if (!p.is_reading) {
      if (p.sample === null) {
        this.sampleCreate({ place: place, objectType: objectType, param: p, day: day, hour: hour });
      }
    }
    p['value'] = e.target.value;
    const resp = API.setParamValue({ id: p['id'], value: p['value'], user: this.currentUser }).catch(function (error) {
      console.log(error);
      p['value'] = prevValue;
      helpers.error(
        'Wystąpił błąd podczas aktualizacji parametru, prosimy o zgłoszenie błędu do administratora.\n\n' + error
      );
    });
  }

  async sampleCreate(params: any) {
    let resp = await API.fetchDicts();
    const dicts = resp.data.dicts;
    console.log('Adding sample', params);
    const sample = new types.Sample();
    sample.take_date = moment().format(consts.DB_DATE_FORMAT);
    sample.take_time = '';

    sample.sample_object_id = dicts.find(
      d => d.type === consts.DictType.OBJECT && d.value === consts.ObjectsStr.WODY_TECH
    ).id;
    sample.object_type_id = dicts.find(
      d => d.type === consts.DictType.OBJECT_TYPE && d.value === params.objectType.object_type_name
    ).id;
    sample.pt_number = '';
    sample.source_id = dicts.find(d => d.type === consts.DictType.SOURCE && d.value === consts.SourcesStr.SR).id;
    sample.take_method_id = dicts.find(
      d => d.type === consts.DictType.TAKE_METHOD && d.value === consts.TakeMethods.SR
    ).id;
    sample.take_place_ids.push({
      id: dicts.find(d => d.type === consts.DictType.TAKE_PLACE && d.value === params.place.take_place_name).id,
    });

    sample.area_test_id = null;
    sample.prepare_norm_id = null;
    sample.recalculate_norm_id = null;

    sample.tonnage = null;
    sample.vehicle_count = null;
    sample.additional = false;
    sample.package_name = '';
    sample.description = 'Próbka sekcji ruchowej';
    sample.status_id = 2;
    sample.sample_dates = [];

    sample.sample_dates.push({ date_from: moment().format(consts.DB_DATE_TIME_FORMAT), time_from: '00:00' });

    resp = await API.fetchTest({ id: params.param.test });
    sample.tests.push(resp.data.test);

    sample.implementation_base_string = '';
    const user = this.$store.state.currentUser;
    sample.take_person_string = user.first_name + ' ' + user.last_name;

    sample.take_norm_string = '';
    sample.take_place_string = '';
    sample.take_method_string = '';
    sample.client_type = consts.ClientType.INTERNAL;
    sample.client_id = consts.Clients.ENEA;
    sample['param'] = params.param;

    console.log('SAMPLE:', sample);
    let sampleId = 0;
    sampleId = await API.saveSample(sample)
      .then(response => {
        helpers.info('Próbka utworzona !', 2000, 'bottomRight');
        console.log('saveSample', response);
        return response.data.sample.id;
      })
      .catch(function (error) {
        helpers.error('Błąd przy tworzeniu próbki. Skontaktuj sie z administratorem systemu!');
        console.log('save Sample Error', error);
        return 0;
      });
    if (params.place.take_place_name === consts.BLOK_POZA) {
      this.savePersonPlace10(this.currentUser.last_name, params.place.take_place_name, params.day, params.hour);
    } else {
      this.savePerson(this.currentUser.last_name, params.place.take_place_name, params.day, params.hour);
    }
    if (sampleId !== 0) {
      await API.connectReadingWithSample({ sample_id: sampleId, param_id: params.param.id });
      params.param.sample = sampleId;
    } else {
      console.log('Error connectReadingWithSample', params.param);
    }
  }

  inputPowerChange(e, place, day, hour) {
    console.log('Power VALUE:', place.take_place_name, day, hour, e.target.value);
    const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
    const sh = tp.shifts.find(s => s.date === day && s.time === hour);
    const prevPower = sh.power;
    sh.power = e.target.value;
    sh.input_person = this.currentUser.last_name;
    const resp = API.setShiftValue({
      id: sh.id,
      power: sh.power,
      input_person: sh.input_person,
      user: this.currentUser,
    }).catch(function (error) {
      console.log(error);
      sh.power = prevPower;
      helpers.error(
        'Wystąpił błąd podczas aktualizacji mocy dla zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' + error
      );
    });
  }

  inputPressureChange(e, place, day, hour) {
    console.log('Pressure VALUE:', place.take_place_name, day, hour, e.target);
    const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
    const sh = tp.shifts.find(s => s.date === day && s.time === hour);
    const prevPressure = sh.pressure;
    sh.pressure = e.target.value;
    sh.input_person = this.currentUser.last_name;
    const resp = API.setShiftValue({
      id: sh.id,
      pressure: sh.pressure,
      input_person: sh.input_person,
      user: this.currentUser,
    }).catch(function (error) {
      console.log(error);
      sh.pressure = prevPressure;
      helpers.error(
        'Wystąpił błąd podczas aktualizacji ciśnienia dla zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' +
          error
      );
    });
  }

  savePerson(last_name, take_place_name, day, hour) {
    console.log('Input Person VALUE:', take_place_name, day, hour, last_name);
    const tp = this.takePlaces.find(tp => tp.take_place_name === take_place_name);
    const sh = tp.shifts.find(s => s.date === day && s.time === hour);
    const prevPerson = sh.input_person;
    sh.input_person = last_name;
    const resp = API.setShiftValue({ id: sh.id, input_person: sh.input_person, user: this.currentUser }).catch(
      function (error) {
        console.log(error);
        sh.input_person = prevPerson;
        helpers.error(
          'Wystąpił błąd podczas aktualizacji osoby wprowadzającej dla zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' +
            error
        );
      }
    );
  }

  inputInputPersonChange(e, place, day, hour) {
    this.savePerson(e.target.value, place.take_place_name, day, hour);
  }

  inputCommentChange(e, place, day, hour) {
    console.log('Comment VALUE:', place.take_place_name, day, hour, e.target.value);
    const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
    const sh = tp.shifts.find(s => s.date === day && s.time === hour);
    const prevComment = sh.comment;
    sh.comment = e.target.value;
    sh.input_person = this.currentUser.last_name;
    const resp = API.setShiftValue({
      id: sh.id,
      comment: sh.comment,
      input_person: sh.input_person,
      user: this.currentUser,
    }).catch(function (error) {
      console.log(error);
      sh.comment = prevComment;
      helpers.error(
        'Wystąpił błąd podczas aktualizacji komentarza dla zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' +
          error
      );
    });
  }

  inputTakePlace10Change(e, place, day, hour) {
    console.log('Pressure VALUE:', place.take_place_name, day, hour, e.target);
    const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
    const sh = tp.shifts.find(s => s.date === day && s.time === hour);
    const prevTakePlace10 = sh.take_place_10;
    sh.take_place_10 = e.target.value;
    const resp = API.setShiftValue({ id: sh.id, take_place_10: sh.take_place_10, user: this.currentUser }).catch(
      function (error) {
        console.log(error);
        sh.take_place_10 = prevTakePlace10;
        helpers.error(
          'Wystąpił błąd podczas aktualizacji miejsca pobrania dla zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' +
            error
        );
      }
    );
  }

  savePersonPlace10(last_name, take_place_name, day, hour) {
    console.log('Input Person VALUE:', take_place_name, day, hour, last_name);
    const tp = this.takePlaces.find(tp => tp.take_place_name === take_place_name);
    const sh = tp.shifts.find(s => s.date === day && s.time === hour);
    const prevPerson = sh.input_person;
    sh.input_person = last_name;
    const resp = API.setShiftValue({ id: sh.id, input_person: sh.input_person, user: this.currentUser }).catch(
      function (error) {
        console.log(error);
        sh.input_person = prevPerson;
        helpers.error(
          'Wystąpił błąd podczas aktualizacji osoby wprowadzającej dla zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' +
            error
        );
      }
    );
  }

  inputInputPersonPlace10Change(e, place, day, hour) {
    this.savePersonPlace10(e.target.value, place.take_place_name, day, hour);
  }

  inputCommentPlace10Change(e, place, day, hour) {
    console.log('Comment VALUE:', place.take_place_name, day, hour, e.target.value);
    const tp = this.takePlaces.find(tp => tp.take_place_name === place.take_place_name);
    const sh = tp.shifts.find(s => s.date === day && s.time === hour);
    const prevComment = sh.comment;
    sh.comment = e.target.value;
    sh.input_person = this.currentUser.last_name;
    const resp = API.setShiftValue({
      id: sh.id,
      comment: sh.comment,
      input_person: sh.input_person,
      user: this.currentUser,
    }).catch(function (error) {
      console.log(error);
      sh.comment = prevComment;
      helpers.error(
        'Wystąpił błąd podczas aktualizacji komentarza dla zmiany, prosimy o zgłoszenie błędu do administratora.\n\n' +
          error
      );
    });
  }

  get getObjectType() {
    return (takePlaceName: string, objectTypeName: string, day = '', hour = '') => {
      const choosenPlace = this.takePlaces.find(p => p.take_place_name === takePlaceName);
      if (day === '' || hour === '') {
        if (this.takePlaces.length > 0 && choosenPlace.shifts.length > 0) {
          day = choosenPlace.shifts[0].date;
          hour = choosenPlace.shifts[0].time;
        } else {
          return undefined;
        }
      }
      const choosenShift = choosenPlace.shifts.find(s => s.date === day && s.time === hour);
      //console.log('X', takePlaceName, objectTypeName, this.takePlaces, a);
      return choosenShift.object_types.find(r => r.object_type_name === objectTypeName);
    };
  }
  get getObjectTypeParams() {
    return (tag: number, takePlaceName: string, objectTypeName: string, day = '', hour = '') => {
      const choosenPlace = this.takePlaces.find(p => p.take_place_name === takePlaceName);
      if (tag === 3 || tag === 11) {
        //console.log('getObjectTypeParams  X', tag, takePlaceName, objectTypeName, 'dt=', day, 'time=', hour);
      }
      if (day === '' || hour === '') {
        if (this.takePlaces.length > 0 && choosenPlace.shifts.length > 0) {
          day = choosenPlace.shifts[0].date;
          hour = choosenPlace.shifts[0].time;
        } else {
          return [];
        }
      }

      const choosenShift = choosenPlace.shifts.find(s => s.date === day && s.time === hour);
      if (tag === 3 || tag === 11) {
        //console.log('ChoosenShift', tag, choosenShift);
      }
      if (choosenShift) {
        const choosenObjectType = choosenShift.object_types.find(r => r.object_type_name === objectTypeName);
        if (tag === 3 || tag === 11) {
          //console.log('choosenObjectType=', tag, choosenObjectType);
        }
        return choosenObjectType['params'];
      } else {
        return [];
      }
    };
  }
  get objectTypes() {
    return (place: any, day = '', hour = '') => {
      const choosenPlace = this.takePlaces.find(p => p.take_place_name === place.take_place_name);
      if (day === '' || hour === '') {
        if (this.takePlaces.length > 0 && choosenPlace && choosenPlace.shifts.length > 0) {
          day = choosenPlace.shifts[0].date;
          hour = choosenPlace.shifts[0].time;
        } else {
          return [];
        }
      }
      const choosenShift = choosenPlace.shifts.find(s => s.date === day && s.time === hour);
      const ot = choosenShift.object_types.map(r => {
        return { object_type_name: r.object_type_name, object_type_id: r.object_type_id };
      });
      return [...new Map(ot.map(item => [item['object_type_name'], item])).values()];
    };
  }
  get uniqueTakePlaces() {
    console.log('GET UNIQUE PLACES');
    const blocks = this.takePlaces.map(r => {
      return { take_place_name: r.take_place_name, take_place_id: r.take_place_id, symbol: r.symbol };
    });
    return [...new Map(blocks.map(item => [item['take_place_name'], item])).values()];
  }

  dtFrom = moment().format(consts.DB_DATE_FORMAT);
  dtTo = moment().format(consts.DB_DATE_FORMAT);
  get getDays() {
    return place => {
      let shifts = [];

      const placeObj = this.takePlaces.find(tp => tp.symbol === place.symbol);
      console.log('getDays', placeObj);

      if (placeObj.symbol !== this.blockPOZA.value.toString()) {
        shifts = [...shifts, ...placeObj.shifts];
      }

      const shiftDates = [];
      shifts.forEach(sh => {
        if (!shiftDates.includes(sh.date)) {
          shiftDates.push(sh.date);
        }
      });

      const retDates = shiftDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      console.log('RET DATES', retDates);
      return retDates;
    };
  }

  //shHours = [];
  get shiftsHours() {
    return (place, shiftDate) => {
      const hours = [];
      const placeObj = this.takePlaces.find(tp => tp.symbol === place.symbol);
      //this.shHours = [];
      //console.log('TAKE PL', tp.value);
      placeObj.shifts.forEach(sh => {
        //console.log('SHIFT', sh.time, sh.date, shiftDate);
        if (sh.date === shiftDate) {
          //console.log('Date OK, checking array', sh.time);
          if (!hours.includes(sh.time)) {
            hours.push(sh.time);
            //this.shHours.push(sh.time);
          }
        }
      });
      console.log('shiftsHours %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%', shiftDate, hours);
      const hoursSorted = hours.sort();
      return hoursSorted;
    };
  }

  get get10BlockDays() {
    const tp10 = this.takePlaces.find(tp => tp.take_place_name === consts.BLOK_POZA);
    const shiftDates = [];
    if (tp10 && tp10.shifts) {
      tp10.shifts.forEach(sh => {
        if (!shiftDates.includes(sh.date)) {
          shiftDates.push(sh.date);
        }
      });
      return shiftDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    } else {
      return [];
    }
  }

  get get10Hours() {
    return day => {
      const tp10 = this.takePlaces.find(tp => tp.take_place_name === consts.BLOK_POZA);
      const hours = [];
      if (tp10 && tp10.shifts) {
        tp10.shifts.forEach(shift => {
          if (shift.date === day) {
            hours.push(shift.time);
          }
        });
      }
      return hours;
    };
  }

  takePlaces = [];
  async loadShifts() {
    this.spinnerMessage = 'Ładowanie odczytów i próbek dla zmian...';
    const resp = await API.fetchReadings({ date_from: this.dtFrom, date_to: this.dtTo, force: true });
    if (resp.data.success) {
      this.takePlaces = resp.data.take_places;
    }
    return resp;
  }

  sampleDtFrom = moment().format(consts.DB_DATE_FORMAT);
  sampleDtTo = moment().format(consts.DB_DATE_FORMAT);
  async loadAdditionalSamples() {
    const resp = await API.fetchAdditionalSamples({ date_from: this.sampleDtFrom, date_to: this.sampleDtTo });
    if (resp.data.success) {
      const tp10 = this.takePlaces.find(tp => tp.take_place_name === consts.BLOK_POZA);
      //console.log('TP10', tp10);
      if (!tp10) {
        //console.log('TP10 push', resp.data.take_places[0]);
        if (resp.data.take_places[0].shifts.length > 0) {
          this.takePlaces.push(resp.data.take_places[0]);
          //console.log('TP10 push takePlaces', resp.data.take_places);
        }
      } else {
        //console.log('TP10 add shifts', resp.data.take_places[0]);
        if (resp.data.take_places.length > 0) {
          tp10.shifts = [];
          resp.data.take_places[0].shifts.forEach(shift => {
            tp10.shifts.push(shift);
          });
        }
      }
    }
    //console.log('After loadAdditionalSamples', this.takePlaces);
    return resp;
  }

  getAllShiftsForPlace(takePlaceName) {
    //console.log('getAllShiftsForPlace', takePlaceName);
    let shifts = [];
    this.takePlaces.forEach(tp => {
      if (tp.take_place_name === takePlaceName) {
        shifts = [...shifts, ...tp.shifts];
      }
    });
    return shifts;
  }

  async loadOneShiftBefore() {
    this.spinner1 = true;
    let shiftsDates = [];
    let lastDt = null;
    if (this.takePlaces.length > 0) {
      shiftsDates = this.getAllShiftsForPlace(this.takePlaces[0].take_place_name);
      //console.log('shiftDates', shiftsDates);
      if (shiftsDates.length > 0) {
        lastDt = new Date(shiftsDates[0].date);
      }
    } else {
      lastDt = new Date(moment().format(consts.DB_DATE_FORMAT));
    }
    const prevDt = moment(lastDt).subtract(1, 'days').format(consts.DB_DATE_FORMAT);
    console.log('last dt', lastDt, 'prev dt', prevDt);

    const prevDtStr = moment(prevDt).format(consts.DB_DATE_FORMAT);
    console.log(
      '--------------------------------------------- LOADING ',
      prevDtStr,
      ' ----------------------------------------------'
    );

    const resp = await API.fetchReadings({
      date_from: prevDtStr,
      date_to: prevDtStr,
      force: true,
    });
    //console.log('RES: ', resp.data, prevDtStr);
    this.dtFrom = prevDtStr;
    let shiftRead = 0;
    if (resp.data.success) {
      this.takePlaces.forEach(tp => {
        resp.data.take_places.forEach(db_tp => {
          //console.log('db_tp', db_tp.take_place_name, tp.take_place_name);
          if (db_tp.take_place_name === tp.take_place_name) {
            let indx = 0;
            db_tp.shifts.forEach(dbShift => {
              const tpShifts = this.getAllShiftsForPlace(tp.take_place_name);
              if (!tpShifts.find(s => s.date === dbShift.date && s.time === dbShift.time)) {
                console.log('SHIFT loaded', dbShift.date, dbShift.hour, ' INDX', indx);
                tp.shifts.splice(indx, 0, dbShift);
                //tp.shifts = [dbShift, ...tp.shifts];
                shiftRead++;
              }
              if (indx === 0) {
                indx++;
              }
            });
          }
        });
      });
    }
    this.spinner1 = false;
    console.log('shifts_read', shiftRead, (shiftRead + 10) % 10);
    if (shiftRead === 0) {
      helpers.info('Brak zmian do wczytania');
    } else if ((shiftRead + 10) % 10 > 4) {
      helpers.info('Wczytano ' + shiftRead + ' zmian');
    } else {
      helpers.info('Wczytano ' + shiftRead + ' zmiany');
    }
  }

  async loadManyShiftsBefore() {
    this.spinner10 = true;
    let shiftsDates = [];
    let lastDt = null;
    if (this.takePlaces.length > 0) {
      shiftsDates = this.getAllShiftsForPlace(this.takePlaces[0].take_place_name);
      // console.log('shift dates', shiftsDates);
      if (shiftsDates.length > 0) {
        lastDt = new Date(shiftsDates[0].date);
        // console.log('shift date', shiftsDates[0]);
      }
    } else {
      lastDt = new Date(moment().format(consts.DB_DATE_FORMAT));
    }
    const prevDt = moment(lastDt).subtract(10, 'days').format(consts.DB_DATE_FORMAT);
    console.log('last dt', lastDt, 'prev dt', prevDt);

    const prevDtStr = moment(prevDt).format(consts.DB_DATE_FORMAT);
    const lastDtStr = moment(lastDt).format(consts.DB_DATE_FORMAT);

    const resp = await API.fetchReadings({
      date_from: prevDtStr,
      date_to: lastDtStr,
      force: true,
    });
    console.log('RES: ', resp.data, prevDtStr, lastDtStr);
    this.dtFrom = prevDtStr;
    let shiftRead = 0;
    if (resp.data.success) {
      this.takePlaces.forEach(tp => {
        resp.data.take_places.forEach(db_tp => {
          if (db_tp.take_place_name === tp.take_place_name) {
            db_tp.shifts.forEach(dbShift => {
              const tpShifts = this.getAllShiftsForPlace(tp.take_place_name);
              if (!tpShifts.find(s => s.date === dbShift.date && s.time === dbShift.time)) {
                tp.shifts = [dbShift, ...tp.shifts];
                shiftRead++;
              }
            });
          }
        });
      });
    }
    this.spinner10 = false;
    console.log('shifts_read', shiftRead, (shiftRead + 10) % 10);
    if (shiftRead === 0) {
      helpers.info('Brak zmian do wczytania');
    } else if ((shiftRead + 10) % 10 > 4) {
      helpers.info('Wczytano ' + shiftRead + ' zmian');
    } else {
      helpers.info('Wczytano ' + shiftRead + ' zmiany');
    }
  }

  dbSettings = [];

  async created() {
    console.log('%c*********************** Readings creating ***********************', 'color:red');
    this.spinner = true;
    let resp = null;
    resp = await this.initiateDay();
    this.spinner = false;
    console.log('this.$refs', this.$refs);
    this.$refs.tab0[0].activate();
    const response = await API.fetchSettings();
    this.dbSettings = response.data.settings;
    this.colors = settings.getSetting(this.dbSettings, consts.Settings.SR_COLORS, 'rgb(255,255,255)').split(';');

    console.log('%c*********************** Readings created ***********************', 'color:red');
  }
  get start() {
    console.log('%c---------- Start of Readings template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of Readings template -----------', 'color:green;font-weight:600');
    return '';
  }
  @Watch('takePlaces', { immediate: true, deep: true })
  onTakePlacesChanged(val, newVal) {
    console.log(
      '---------------------------------------------------- TAKE PLACES CHANGED ---------------------------------------'
    );
  }
}
