















































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import MinimalGenericList from '../../../components/spec/MinimalGenericList.vue';
import * as helpers from '../../../helpers';
import Modal from '../../../components/spec/Modal.vue';
import FileManager from '../../../components/spec/FileManager.vue';
import LabeledInput from '../../FormComponents/LabeledInput.vue';
import Checkbox from '../../FormComponents/Checkbox.vue';
import * as API from '../../../store/api';
import * as consts from '../../../consts';
import { ExtendedParam, StandardMultiselectEngine, ReportXls } from '../../../types';
import { Guid } from 'guid-typescript';

@Component({
  components: {
    TopPanel,
    GenericList,
    FileManager,
    GenericForm,
    Header,
    Modal,
    Checkbox,
    Footer,
    MinimalGenericList,
    LabeledInput,
  },
})
export default class ReportXLSTemplateList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly mainList: GenericList;
  @Ref() readonly fileManager: FileManager;
  params = [];
  @Ref() readonly modal: Modal;
  objects = [];
  modalParams = new Array<ExtendedParam>();
  show = true;
  object = {};
  xlsTemplate = new ReportXls();

  xlsTemplateParams = new Array<any>();
  xlsTemplatesParams = new Array<any>();
  filterObject = {};
  guidValue = '';
  modalTitle = '';
  copyFilterObject = {};
  url = '';
  guid: any;
  filterParams = new Array<ExtendedParam>();
  currFiles = [];
  currYear = moment().format(consts.DB_DATE_FORMAT).substring(2, 4);
  ifValid = true;

  @Ref() filterForm: GenericForm;

  processLoadedElement(object: any) {
    console.log(object, 'Loaded templates...');
  }

  async showModal(object: ReportXls) {
    this.xlsTemplate = Object.assign(new ReportXls(), object);
    this.guid = Guid.create();
    this.guidValue = this.guid.value;
    if (!this.xlsTemplate.id) {
      this.xlsTemplate = new ReportXls();
      this.modalTitle = 'Dodaj nowy szablon';
      this.currFiles = [];
    } else {
      this.modalTitle = 'Edycja szablonu';
      if (this.xlsTemplate.attachment) {
        this.currFiles = [this.xlsTemplate.attachment];
      } else {
        this.currFiles = [];
      }
    }

    this.modal.showModal();
  }
  async saveModal(e: any) {
    this.ifValid = true;
    this.$root.$emit('ifValue', true);
    if (this.xlsTemplate.remarks.length === 0) {
      this.ifValid = false;
      this.$root.$emit('ifValue', false);
      helpers.error('Należy podać wymaganą nazwę pliku!');
    } else if (this.fileManager.fileArray.length !== 0) {
      if (this.fileManager.fileArray[0].name !== `${this.xlsTemplate.remarks}${this.currYear}.xlsx`) {
        this.ifValid = false;
        this.$root.$emit('ifValue', false);
        helpers.error(`Nazwa pliku musi składać się z wymaganej frazy + ${this.currYear}.xlsx`);
      } else {
        await this.save();
      }
    } else {
      await this.save();
    }
  }

  async save() {
    console.log('saving', this.xlsTemplate);
    await this.fileManager.deleteMarkedFiles();
    if (this.xlsTemplate.attachment && this.fileManager.fileArray.length !== 0) {
      this.xlsTemplate.attachment = this.fileManager.fileArray[0];
      this.xlsTemplate.attachment_id = this.xlsTemplate.attachment.id;
    } else {
      this.xlsTemplate.attachment = null;
      this.xlsTemplate.attachment_id = null;
    }
    if (this.guidValue) {
      this.xlsTemplate.guid = this.guidValue;
    }
    const response = await API.saveXlsTemplate(this.xlsTemplate);
    if (response.data.type_exists) {
      helpers.error('Istnieje już dokument, który ma przypisany taki sam symbol');
    } else {
      helpers.requestSucess();
      this.mainList.fillObjects();
    }
    this.ifValid = true;
    this.$root.$emit('ifValue', true);
  }

  clear() {
    this.copyFilterObject = {};
    console.log(this.filterForm, 'filterForm');
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }
  async openFilterModal() {
    await this.modalFilter.showModal();
    //this.clear();
  }
  sendFilterQuery(e: any) {
    console.log('OK', this.filterObject, e);
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  async created() {
    this.xlsTemplateParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szablonu',
      },
      {
        value: 'type',
        fieldType: 'multiselect',
        fieldName: 'type',
        headerName: 'Symbol',
        multiSelectTrack: 'name',
        options: [
          {
            name: consts.ReportTypeStr.WEGIEL,
            type: consts.ReportTypeSymbol.WEGIEL,
          },
          {
            name: consts.ReportTypeStr.BIOMASA,
            type: consts.ReportTypeSymbol.BIOMASA,
          },
          {
            name: consts.ReportTypeStr.BIOMASA_ANA_SIT,
            type: consts.ReportTypeSymbol.BIOMASA_ANA_SIT,
          },
          {
            name: consts.ReportTypeStr.ODPAD_PAL,
            type: consts.ReportTypeSymbol.ODPAD_PAL,
          },
          {
            name: consts.ReportTypeStr.POP_ZUZ,
            type: consts.ReportTypeSymbol.POP_ZUZ,
          },
        ],
        action: (e: any, object: any) => {
          object.type = e.type;
        },
      },
      {
        fieldType: 'input',
        fieldName: 'version',
        headerName: 'Wersja dokumentu',
      },
      {
        fieldType: 'input',
        fieldName: 'remarks',
        headerName: 'Wymagana fraza w nazwie pliku',
      },
    ];

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa szablonu',
      },
      {
        value: 'type',
        fieldType: 'multiselect',
        fieldName: 'type',
        headerName: 'Symbol',
        multiSelectTrack: 'name',
        options: [
          {
            name: consts.ReportTypeStr.WEGIEL,
            type: consts.ReportTypeSymbol.WEGIEL,
          },
          {
            name: consts.ReportTypeStr.BIOMASA,
            type: consts.ReportTypeSymbol.BIOMASA,
          },
          {
            name: consts.ReportTypeStr.BIOMASA_ANA_SIT,
            type: consts.ReportTypeSymbol.BIOMASA_ANA_SIT,
          },
          {
            name: consts.ReportTypeStr.ODPAD_PAL,
            type: consts.ReportTypeSymbol.ODPAD_PAL,
          },
          {
            name: consts.ReportTypeStr.POP_ZUZ,
            type: consts.ReportTypeSymbol.POP_ZUZ,
          },
        ],
        action: (e: any, object: any) => {
          object.type = e.type;
        },
      },
    ];

    //------------wyswietlane---------------
    this.params = [
      {
        value: '$name',
        headerName: 'Nazwa',
        columnCss: 'col-2 p-0',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        value: '$type',
        headerName: 'Symbol',
        sortField: 'type',
        headerCss: 'col-2 p-0',
      },
      {
        fieldType: 'icon',
        headerName: 'Załącznik',
        headerCss: 'col-2 p-0',
        icon: object => {
          return 'file-pdf';
        },
        size: 'lg',
        key: 0,
        css: 'word-icon',
        visible: (object: any) => {
          return object.attachment_id;
        },
        action: async (object: any, e: any) => {
          this.url = process.env.VUE_APP_API_URL + 'media/' + object.attachment.path;
          await this.$forceUpdate();
          document.getElementById('download').click();
        },
      },
      {
        value: '$version',
        headerName: 'Wersja dokumentu',
        columnCss: 'col-2 p-0',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        value: '$remarks',
        headerName: 'Wymagana fraza w nazwie pliku',
        columnCss: 'col-3 p-0',
        headerCss: 'col-3 p-0 text-center',
      },
    ];
    //-------------------------------------
  }
}
