













































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import Modal from '../../../components/spec/Modal.vue';
import ModalReportAuthorise from '../../../components/dummy/ModalReportAuthorise.vue';
import * as API from '../../../store/api';
import * as consts from '../../../consts';
import * as helpers from '../../../helpers';
import { User } from '../../../types';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    Header,
    Modal,
    Footer,
    ModalReportAuthorise,
  },
})
export default class ReportsXls extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly modal: Modal;
  @Ref() readonly modalReportAuthorise: ModalReportAuthorise;
  objects = [
    { lp: 1, name: '', type: consts.ReportTypeStr.WEGIEL, status: '', ready: 'Y', fileName: '' },
    { lp: 2, name: '', type: consts.ReportTypeStr.BIOMASA, status: '', ready: 'Y', fileName: '' },
    { lp: 3, name: '', type: consts.ReportTypeStr.BIOMASA_ANA_SIT, status: '', ready: 'Y', fileName: '' },
    { lp: 4, name: '', type: consts.ReportTypeStr.ODPAD_PAL, status: '-', ready: 'Y', fileName: '' },
    { lp: 5, name: '', type: consts.ReportTypeStr.POP_ZUZ, status: '-', ready: 'Y', fileName: '' },
  ];
  user: User = new User();
  show = true;
  url = '';
  object = {};
  authorised = consts.ReportStatusStr.AUTHORIZED;
  notAuthorised = consts.ReportStatusStr.NOT_AUTH;
  toCorrect = consts.ReportStatusStr.TO_CORRECT;
  typeToAuthorize = '';
  typeToAuthorizeSym = '';
  statusStr = '';
  currYear = moment().format(consts.DB_DATE_FORMAT).substring(2, 4);
  actionQuestion = 'Czy chcesz zautoryzować raport?';
  spinnerGenerate = false;
  spinnerAuthorise = false;
  ReportXLSTypeMapping = [
    [consts.ReportTypeSymbol.WEGIEL, consts.ReportTypeStr.WEGIEL],
    [consts.ReportTypeSymbol.BIOMASA, consts.ReportTypeStr.BIOMASA],
    [consts.ReportTypeSymbol.BIOMASA_ANA_SIT, consts.ReportTypeStr.BIOMASA_ANA_SIT],
    [consts.ReportTypeSymbol.ODPAD_PAL, consts.ReportTypeStr.ODPAD_PAL],
    [consts.ReportTypeSymbol.POP_ZUZ, consts.ReportTypeStr.POP_ZUZ],
  ];
  ReportXLSStatusMapping = [
    [consts.ReportStatusSymbol.NOT_AUTH, consts.ReportStatusStr.NOT_AUTH],
    [consts.ReportStatusSymbol.TO_CORRECT, consts.ReportStatusStr.TO_CORRECT],
    [consts.ReportStatusSymbol.AUTHORIZED, consts.ReportStatusStr.AUTHORIZED],
  ];

  typeMappingToSym(typeStr) {
    let typeSym = '';
    this.ReportXLSTypeMapping.forEach(m => {
      if (typeStr === m[1]) {
        return (typeSym = m[0]);
      }
    });
    return typeSym;
  }

  statusMappingToStr(statusSym) {
    let typeStr = '';
    this.ReportXLSStatusMapping.forEach(m => {
      if (statusSym === m[0]) {
        return (typeStr = m[1]);
      }
    });
    return typeStr;
  }

  changeObjectStatus(type, status) {
    let index = 0;
    this.objects.forEach(o => {
      if (o.type === type) {
        return (this.objects[index].status = status);
      }
      index++;
    });
  }

  showModal(object: any) {
    this.object = object;
    this.modal.showModal();
  }

  async generateReport(type) {
    helpers.info('Generacja raportu ' + type + ' w toku...');
    this.spinnerGenerate = true;
    const typeSym = this.typeMappingToSym(type);
    const response = await API.generateReport(typeSym, this.user);
    if (response.data.success === true) {
      helpers.info('Raport ' + type + ' wygenerowany!');
      this.changeObjectStatus(type, consts.ReportStatusStr.NOT_AUTH);
      this.spinnerGenerate = false;
    }
  }

  get mediaURL() {
    return process.env.VUE_APP_API_URL + 'media';
  }

  get wegielReport() {
    const wegiel = this.objects.find(o => o['type'] == consts.ReportTypeStr.WEGIEL);
    return `${this.mediaURL}/ReportXls/${wegiel.fileName}${this.currYear}.xlsx`;
  }

  get biomasaReport() {
    const biomasa = this.objects.find(o => o['type'] == consts.ReportTypeStr.BIOMASA);
    return `${this.mediaURL}/ReportXls/${biomasa.fileName}${this.currYear}.xlsx`;
  }

  get biomasaAnaSitReport() {
    const biomasaAnaSit = this.objects.find(o => o['type'] == consts.ReportTypeStr.BIOMASA_ANA_SIT);
    return `${this.mediaURL}/ReportXls/${biomasaAnaSit.fileName}${this.currYear}.xlsx`;
  }

  get odpadPalReport() {
    const odpadPal = this.objects.find(o => o['type'] == consts.ReportTypeStr.ODPAD_PAL);
    return `${this.mediaURL}/ReportXls/${odpadPal.fileName}${this.currYear}.xlsx`;
  }

  get popiolZuzelReport() {
    const popiolZuzel = this.objects.find(o => o['type'] == consts.ReportTypeStr.POP_ZUZ);
    return `${this.mediaURL}/ReportXls/${popiolZuzel.fileName}${this.currYear}.xlsx`;
  }

  getReport(type) {
    if (type === consts.ReportTypeStr.WEGIEL) {
      document.getElementById('downloadWegiel').click();
    }
    if (type === consts.ReportTypeStr.BIOMASA) {
      document.getElementById('downloadBiomasa').click();
    }
    if (type === consts.ReportTypeStr.BIOMASA_ANA_SIT) {
      document.getElementById('downloadBiomasaAnaSit').click();
    }
    if (type === consts.ReportTypeStr.ODPAD_PAL) {
      document.getElementById('downloadOdpadPal').click();
    }
    if (type === consts.ReportTypeStr.POP_ZUZ) {
      document.getElementById('downloadPopiolZuzel').click();
    }
  }

  authoriseModal(type) {
    this.typeToAuthorize = type;
    this.typeToAuthorizeSym = this.typeMappingToSym(type);
    this.modalReportAuthorise.showModal({});
  }

  async doNotAuthorise() {
    const response = await API.correctReportXls(this.typeToAuthorizeSym);
    if (response.data.success === true) {
      helpers.info('Raport ' + this.typeToAuthorize + ' oznaczony jako wymagający korekty');
      this.changeObjectStatus(this.typeToAuthorize, consts.ReportStatusStr.TO_CORRECT);
    }
    this.typeToAuthorize = '';
    this.typeToAuthorizeSym = '';
  }

  async authorise() {
    helpers.info('Autoryzacja raportu ' + this.typeToAuthorize + ' w toku...');
    this.spinnerAuthorise = true;
    const response = await API.authorizeReportXls(this.typeToAuthorizeSym, this.user);
    if (response.data.success === true) {
      helpers.info('Raport ' + this.typeToAuthorize + ' zautoryzowany!');
      this.changeObjectStatus(this.typeToAuthorize, consts.ReportStatusStr.AUTHORIZED);
      this.spinnerAuthorise = false;
    }
    this.typeToAuthorize = '';
    this.typeToAuthorizeSym = '';
  }

  async created() {
    this.user = this.$store.state.currentUser;
    const response = await API.fetchReportXls();
    // ustawienie w objects statusu zgodnie ze statusem z bazy
    if (response.data.success === true) {
      const reports = response.data.reports;
      let index = 0;
      this.objects.forEach(o => {
        const typeSym = this.typeMappingToSym(o.type);
        const report = reports.find(r => r.type === typeSym);
        if (report !== undefined) {
          this.statusStr = this.statusMappingToStr(report['status']);
          this.objects[index].status = this.statusStr;
          this.objects[index].name = report['name'];
          this.objects[index].fileName = report['remarks'];
        }
        index++;
      });
    }
  }
}
