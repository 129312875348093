














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({ components: {} })
export default class SuperSubScriptDisplayer extends Vue {
  @Prop({ default: '', type: String }) scriptText: string;

  SUPSCRIPT = 2;
  NORMAL = 1;
  SUBSCRIPT = 0;

  textsArray = [];

  addTextElement(paraType: string, text: string) {
    let type = -1;

    if (paraType === '') type = this.NORMAL;
    else if (paraType === '|') type = this.SUBSCRIPT;
    else if (paraType === '^') type = this.SUPSCRIPT;

    this.textsArray.push({ type, text });
  }

  parseScriptText() {
    let paraType = '';
    let text = '';
    const stext = this.scriptText;
    for (let i = 0; i < stext.length; i++) {
      const c = stext[i];
      if (c === '|' || c === '^') {
        this.addTextElement(paraType, text);
        paraType = c;
        text = '';
        i += 1;
      } else if (c === '}') {
        this.addTextElement(paraType, text);
        paraType = '';
        text = '';
      } else {
        text += c;
      }
    }

    this.addTextElement(paraType, text);
  }

  @Watch('scriptText')
  symbolTextChanged() {
    this.textsArray = [];
    this.parseScriptText();
  }

  created() {
    this.parseScriptText();
  }
}
