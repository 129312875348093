































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as API from '../../store/api';
import * as consts from '../../consts';
import * as helpers from '../../helpers';
import moment from 'moment';

const NOT_CHOOSEN = 100;
const ZERO_HOUR = 0;

@Component
export default class AdditionalSampleModal extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ default: 'Anuluj' }) readonly buttonCancelText: string;
  @Prop({ default: 'Zapisz' }) readonly buttonOKText: string;
  @Prop({ type: String, required: false, default: 'lg' }) size;
  @Prop({ type: Boolean, required: false, default: false }) backClose;
  @Prop({ type: Boolean, required: false, default: false }) okOnly;
  @Prop({ type: String, required: false, default: 'success' }) okVariant;
  @Prop({ type: Boolean, required: false, default: false }) disabledOkButton;

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  shiftDate = moment().format(consts.DATE_FORMAT);
  shiftTime = '';
  shiftTakePlace = '';

  showModal(params = {}) {
    this.show = true;
    this.params = params;
  }
  clear() {
    this.$emit('CLEAR');
  }

  get currentUser() {
    return this.$store.state.currentUser;
  }

  async saveModal() {
    if (this.shiftDate === '' || this.shiftTime === '' || this.shiftTakePlace === '') {
      helpers.error('Proszę wypełnić wszystkie pola');
      this.btnSave++;
      return;
    }

    const dt = moment(this.shiftDate, consts.DATE_FORMAT, true);
    if (!dt.isValid()) {
      console.log('Data niepoprawna', dt);
      helpers.error('Data niepoprawna. Wymagany format: DD.MM.YYYY');
      this.btnSave++;
      return;
    }

    const checkIfLackZero = this.shiftTime.split(':');
    if (checkIfLackZero[0].length === 1) {
      checkIfLackZero[0] = ZERO_HOUR + checkIfLackZero[0];
      this.shiftTime = checkIfLackZero[0] + ':' + checkIfLackZero[1];
    }
    const tm = moment(this.shiftTime, consts.TIME_FORMAT, true);
    if (!tm.isValid()) {
      console.log('Czas niepoprawny', tm);
      helpers.error('Godzina niepoprawna. Wymagany format: HH:mm');
      this.btnSave++;
      return;
    }

    this.save = true;
    this.params['date'] = moment(this.shiftDate, consts.DATE_FORMAT).format(consts.DB_DATE_FORMAT);
    this.params['time'] = this.shiftTime;
    this.params['take_place'] = this.shiftTakePlace;
    this.params['user'] = this.currentUser;

    await API.addAdditionalShift({
      date: this.params['date'],
      time: this.params['time'],
      take_place: this.params['take_place'],
      user: this.params['user'],
    });
    this.$emit('SAVE', this.params);
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
