













import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import ProfileMenu from './ProfileMenu.vue';
import { AppView } from '../../types';
@Component({
  components: { ProfileMenu },
})
export default class TopPanel extends Vue {}
