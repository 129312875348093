import store from '@/store';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'app',
  store,
  dynamic: true,
})
class AppModule extends VuexModule {
  isMobile = false;
  appVersion = '1.0.1';

  get getAppVersion() {
    return this.appVersion;
  }

  @Mutation
  setMobile(isMobile: boolean): any {
    this.isMobile = isMobile;
  }

  @Mutation
  setVersion(version: string): any {
    this.appVersion = version;
  }
}

export default getModule(AppModule);
