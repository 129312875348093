







































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import * as API from '../../../store/api';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import GenericForm from '../../../components/spec/GenericForm.vue';
import GenericList from '../../../components/spec/GenericList.vue';
import GenericField from '../../../components/spec/GenericField.vue';
import Modal from '../../../components/spec/Modal.vue';
import * as helpers from '../../../helpers';
import { ExtendedParam, Dict, DictToSave, Structure, StandardMultiselectEngine } from '../../../types';
import { DictType, DictTypeTitles } from '../../../consts';
import * as consts from '../../../consts';

@Component({
  components: {
    TopPanel,
    GenericList,
    GenericForm,
    GenericField,
    Header,
    Modal,
    Footer,
  },
})
export default class DictList extends Vue {
  @Ref() readonly modalFilter: Modal;
  @Ref() readonly dictModal: Modal;
  @Ref() readonly list: GenericList;
  @Ref() filterForm: GenericForm;
  @Ref() relationForm: GenericForm;

  params = [];
  show = true;
  objectCopy = {};
  filterObject = {};
  copyFilterObject = {};
  url = '';
  filterParams = new Array<ExtendedParam>();
  listKey = 0;
  structure = [];
  relationTypes = [DictType.OBJECT, DictType.SOURCE, DictType.OBJECT_TYPE, DictType.TAKE_METHOD];
  isEditing = false;
  idsVisible = false;
  loaded = false;
  question = '';
  spinner = false;
  areaKey = 0;
  treeKey = 0;
  objectKey = 0;
  structureId = 0;
  relatedStructure = [];
  dicts = [];
  dictModalTitle = '';
  dictId = 0;
  dictValue = '';
  dictActive = true;
  dictSymbol = '';
  dictValue2 = '';
  dictPrepareNorm = '';
  dictRecalculateNorm = '';
  dictArea = '';
  lastSelectedNode = { id: 0, name: '', active: true, dict: { value: '', symbol: '', value2: '' } };
  lastNewId = consts.NEW_ID_START;
  dictTypeInt = [
    consts.DictType.OBJECT,
    consts.DictType.SOURCE,
    consts.DictType.OBJECT_TYPE,
    consts.DictType.TAKE_METHOD,
    consts.DictType.TAKE_PLACE,
  ];

  get dictTitle() {
    return consts.DictTypeTitles[this.dictType];
  }

  get dictValueTitle() {
    return consts.DictTypeValueTitles[this.dictType];
  }

  get modalParams() {
    return [
      {
        headerName: this.dictValueTitle,
        fieldType: 'input',
        fieldName: 'value',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Symbol (opcjonalnie)',
        fieldType: 'input',
        fieldName: 'symbol',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Wartość dodatkowa (opcjonalnie)',
        fieldType: 'input',
        fieldName: 'value2',
        mainCss: 'mt-2',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'checkbox',
        fieldName: 'active',
        mainCss: 'mt-2',
      },
    ];
  }

  async loadDicts() {
    console.log('Loading dicts');
    const response = await API.fetchDicts();
    this.dicts = response.data.dicts;
    console.log('Dicts loaded..');
  }

  async openFilterModal() {
    await this.modalFilter.showModal();
  }

  async showDictModal(object: any = {}) {
    console.log('object', object);
    if (object && 'id' in object) {
      this.dictModalTitle = 'Edytuj wartość słownika';
    } else {
      this.dictModalTitle = 'Dodaj nową wartość słownika';
    }
    this.objectCopy = Object.assign({}, object);
    this.dictModal.showModal();
  }

  async saveDict() {
    const identicalDict = this.dicts.filter(d => d.value === this.objectCopy['value']);
    if (identicalDict.length > 0 && identicalDict[0].id !== this.objectCopy['id']) {
      helpers.error('Istnieje już słownik o takiej wartości');
      return;
    } else {
      if (!('active' in this.objectCopy)) {
        this.objectCopy['active'] = false;
      }
      this.objectCopy['type'] = this.dictType;
      console.log('obj to save', this.objectCopy);
      const response = await API.saveDict(this.objectCopy);
      if (response.data.success) {
        helpers.info('Pomyślnie zapisano');
        this.list.fillObjects();
      } else {
        helpers.error('Wystąpił nieoczekiwany błąd po stronie serwera');
      }
      this.dictModal.hideModal();
      this.objectCopy = null;
    }
  }

  clear() {
    this.copyFilterObject = {};
    this.filterForm.fields.forEach(el => {
      el.reset();
      el.$forceUpdate();
    });
  }

  sendFilterQuery(e: any) {
    this.filterObject = Object.assign({}, this.copyFilterObject);
  }

  parseRouteParams() {
    if (this.$route.params.type !== undefined) {
      this.dictType = parseInt(this.$route.params.type);
      this.filterObject['type'] = this.dictType;
    } else {
      this.dictType = 0;
      delete this.filterObject['type'];
    }
  }

  get dictArray() {
    // array for generic params
    const arr = [];
    for (let i = 1; i < DictTypeTitles.length; i++) {
      arr.push({ name: DictTypeTitles[i], id: i });
    }
    return arr;
  }

  async setup() {
    this.parseRouteParams();
    await this.loadDicts();
  }
  dictType = 0;
  async created() {
    await this.setup();

    this.filterParams = [
      {
        fieldType: 'input',
        fieldName: 'value',
        headerName: 'Wartość główna',
      },
      {
        fieldType: 'multiselect',
        fieldName: 'type',
        options: this.dictArray,
        multiSelectTrack: 'name',
        engine: new StandardMultiselectEngine('type', 'id'),
        headerName: 'Typ słownika',
      },
    ];

    this.params = [
      {
        fieldType: 'badge',
        badgeVariant: (object, param) => {
          const id = object.type - 1;
          const variants = [
            'success',
            'info',
            'secondary',
            'danger',
            'warning',
            'primary',
            'primary',
            'light',
            'danger',
            'success',
          ];
          return variants[id];
        },
        value: (object, param) => {
          const id = object.type;
          const texts = DictTypeTitles;
          return texts[id];
        },
        headerName: 'RODZAJ SŁOWNIKA',
        sortField: 'type',
        columnCss: 'col-2 p-0 font-16',
        headerCss: 'col-2 p-0',
      },
      {
        value: '$value',
        headerName: 'WARTOSC',
        columnCss: 'col-5 p-0 text-left font-16',
        headerCss: 'col-5 p-0 text-center',
      },
      {
        value: '$symbol',
        headerName: 'SYMBOL',
        columnCss: 'col-2 p-0 text-center font-16',
        headerCss: 'col-2 p-0 text-center',
      },
      {
        headerName: 'Aktywny',
        fieldType: 'boolean',
        value: 'active',
        columnCss: 'col-1 p-0 text-center font-16',
        headerCss: 'col-1 p-0 text-center',
      },
      {
        headerName: 'Operacje',
        fieldType: 'bdropdown',
        getValues: (obj: any) => {
          return [
            {
              css: 'font-12',
              text: 'Usuń',
              action: async (obj: any) => {
                const response = await API.deleteObjectModelById(obj.id, 'Dict');
                if (response.data.cannot_delete === true) {
                  if (obj.active === false) {
                    helpers.error('Słownik wykorzystywany w strukturze, nie można usunąć');
                  } else {
                    obj.active = false;
                    const response = await API.saveDict(obj);
                    if (response.data.success) {
                      helpers.error('Słownik wykorzystywany w strukturze, nie można usunąć, ustawiono jako nieaktywny');
                      this.list.fillObjects();
                    } else {
                      helpers.error('Wystąpił nieoczekiwany błąd po stronie serwera');
                    }
                    await this.setup();
                    this.$forceUpdate();
                  }
                }
                await this.setup();
                this.$forceUpdate();
              },
            },
          ];
        },
      },
    ];
  }

  @Watch('$route')
  onRouteDictTypeChanged() {
    this.parseRouteParams();
    this.listKey++;
  }
}
