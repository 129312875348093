import store from '@/store';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as API from '../../store/api';
import * as consts from '../../consts';
import * as types from '../../types';

@Module({
  namespaced: true,
  name: 'samples',
  store,
  dynamic: true,
})
class SamplesModule extends VuexModule {
  samples = [];
  version = 0;

  get getVersion() {
    return this.version;
  }

  @Mutation
  addSample(sample: types.Sample): any {
    this.samples.push(sample);
  }

  @Mutation
  setVersion(version): any {
    this.version = version;
  }
}

export default getModule(SamplesModule);
