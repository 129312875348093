





































import { Component, Vue, Prop, Ref, Emit, Watch } from 'vue-property-decorator';
import * as consts from '../../consts';
import ModalChooseTime from '@/components/dummy/ModalChooseTime.vue';
import moment from 'moment';

@Component({
  components: {
    ModalChooseTime,
  },
})
export default class DateInput extends Vue {
  @Ref() readonly modalChooseTime: ModalChooseTime;
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: Boolean, default: true }) enabled;
  events: [consts.ModalEvents.DATE_CHANGED_EVENT];
  dateDbFormat = '';
  dateText = '';

  onMainDateContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.dateDbFormat = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = moment(this.dateDbFormat, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
      // if (this.param.action) {
      //   this.param.action(ctx, this.object, this.param);
      // }
      this.$emit(consts.ModalEvents.DATE_CHANGED_EVENT, this.dateDbFormat);
    }
  }

  dateTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.dateDbFormat = today.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = today.format(consts.DATE_FORMAT);
    } else {
      this.dateDbFormat = moment(event.target.value, consts.DATE_FORMAT).format(consts.DB_DATE_TIME_FORMAT);
    }
    this.$emit(consts.ModalEvents.DATE_CHANGED_EVENT, this.dateDbFormat);
  }

  reset() {
    this.dateText = '';
    this.$forceUpdate();
  }

  mounted() {
    //this.value = this.$attrs.value;
  }
}
