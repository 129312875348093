















































































import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ModalExamFilter from '@/components/spec/ModalExamFilter.vue';
import GenericForm from '@/components/spec/GenericForm.vue';
import MinimalGenericList from '@/components/spec/MinimalGenericList.vue';
import GenericField from '@/components/spec/GenericField.vue';
import Modal from '@/components/spec/Modal.vue';
import * as API from '@/store/api';
import * as helpers from '@/helpers';
import * as consts from '@/consts';
import { SortDir, LABORANT_ROUTE_PREFIX, DashboardObjectTypes, DATE_TIME_FORMAT } from '@/consts';
import { AppView, Exam, DashboardItem, User, DashboardAction, Sample, ExtendedParam, Tool } from '@/types';
import { BButton } from 'bootstrap-vue';

import SampleParamsEditor from '@/components/spec/SampleParamsEditor.vue';

@Component({
  components: {
    TopPanel,
    MinimalGenericList,
    GenericForm,
    GenericField,
    SampleParamsEditor,
    Header,
    Modal,
    Footer,
  },
})
export default class MethodForm extends Vue {
  modalParams = new Array<ExtendedParam>();
  detailsParams = new Array<ExtendedParam>();
  @Prop({ required: true }) object: any;
  @Prop({ required: false, default: false }) canUserEditMethod: boolean;
  @Ref() minList: MinimalGenericList;
  @Ref() dateField: GenericField;
  buttonText = '+ Dodaj';

  sample = {};
  finalResults = [{}];
  elements = [];
  types = [];
  methods = [];
  tools = [];
  toolTestsIds = [];
  toolKey = 0;
  users = [];
  sections = [];
  choosenGroups = [];
  additionalObjectParams = [];
  finalResultParams = [];
  savedObj = {};
  // spinner = true;
  groups = [
    { name: 'waga', id: 1 },
    { name: 'suszarka', id: 2 },
  ];
  groupSelected = '';

  addGroup() {
    console.log(this.groupSelected, 'selected');
    if (!this.choosenGroups.includes(this.groupSelected)) {
      this.choosenGroups.push({ name: this.groupSelected });
      this.object.testToolGroups = this.choosenGroups;
      this.groupSelected = '';
    }
  }

  deselectGroups(group) {
    this.choosenGroups = this.choosenGroups.filter(a => a.id != group.id);
    this.object.testToolGroups = this.choosenGroups;
  }

  get selectedGroupTool() {
    return group_id => {
      const groupIndex = this.choosenGroups.findIndex(g => g.id === group_id);
      const toolId = this.choosenGroups[groupIndex]['tool_id'];
      return this.tools.find(t => t.tool_id === toolId);
    };
  }

  selectTool(e: any, group_id: number) {
    const groupIndex = this.choosenGroups.findIndex(g => g.id === group_id);
    this.choosenGroups[groupIndex]['tool_id'] = e.tool_id;
    this.object.testToolGroups = this.choosenGroups;
    this.toolKey++;
  }

  async saveObject(item) {
    item.isEdited = false;
    await this.minList.reload();
  }

  async switchObjectEdit(item) {
    item.isEdited = !item.isEdited;
    if (item.isEdited) {
      this.savedObj = Object.assign({}, item);
    } else {
      item = this.savedObj;
    }

    //trzeba stworzyc lepsze wyjscie do tego
    await this.minList.$forceUpdate();
    //await this.minList.reload();
  }

  async created() {
    this.object.sampleTestParams = [];
    this.object.finalResultParams = this.finalResultParams = [];
    this.object.deletedParams = [];
    this.object.testToolGroups = this.choosenGroups;

    console.log('object', this.object);

    let response = await API.fetchModel('UserData');
    response.data.objs.forEach(element => {
      element.fullName = element.user.first_name + ' ' + element.user.last_name;
      this.users.push(element);
    });

    response = await API.fetchDictType(consts.DictType.OBJECT);
    response.data.objs.forEach(element => {
      this.types.push(Object.assign({}, element));
    });

    response = await API.fetchDictType(consts.DictType.METHOD);
    response.data.objs.forEach(element => {
      this.methods.push(Object.assign({}, element));
    });

    response = await API.fetchToolTests({ test_id: this.object.id });
    this.toolTestsIds = response.data.tool_tests.map(tt => tt.tool_id);

    response = await API.fetchModel('Tool');
    this.tools = response.data.objs.map(tool => {
      return { tool_id: tool.id, tool_code_number: tool.code_number };
    });
    this.tools = this.tools.filter(t => this.toolTestsIds.includes(t.tool_id));

    response = await API.fetchDictType(consts.DictType.SECTION);
    response.data.objs.forEach(element => {
      if (element.value !== consts.Sections.WS) {
        this.sections.push(Object.assign({}, element));
      }
    });

    if (this.object.id) {
      const testId = this.object.id;
      console.log(testId, 'testId');
      response = await API.fetchTestToolGroups(testId);
      if (response.data.success) {
        this.choosenGroups = response.data.test_tool_groups;
      } else {
        this.choosenGroups = [];
      }
    }

    this.modalParams = [
      {
        fieldType: 'input',
        fieldName: 'number',
        headerName: 'Numer metody',
        disabled: !this.canUserEditMethod,
      },
      {
        fieldType: 'input',
        fieldName: 'name',
        headerName: 'Nazwa metody',
        disabled: !this.canUserEditMethod,
      },
      {
        value: 'method',
        headerName: 'Metoda analiz',
        fieldName: 'method',
        fieldType: 'multiselect',
        disabled: !this.canUserEditMethod,
        options: this.methods,
        multiSelectTrack: 'value',
        action: (e: any, obj: any) => {
          obj.method_id = e.id;
        },
      },
      {
        headerName: 'Norma',
        fieldName: 'norm',
        fieldType: 'input',
        disabled: !this.canUserEditMethod,
      },
      {
        value: 'object',
        headerName: 'Obiekt',
        fieldName: 'object',
        fieldType: 'multiselect',
        disabled: !this.canUserEditMethod,
        options: this.types,
        multiSelectTrack: 'value',
        action: (e: any, obj: any) => {
          obj.object_id = e.id;
        },
      },
      {
        value: 'section',
        fieldType: 'multiselect',
        fieldName: 'section',
        headerName: 'Sekcja',
        disabled: !this.canUserEditMethod,
        options: this.sections,
        multiSelectTrack: 'value',
        action: (e: any, obj: any) => {
          obj.section_id = e.id;
        },
      },
      {
        fieldType: 'radiobuttons',
        value: 'actual',
        css: 'subpart w-50 p-3',
        headerName: 'Norma  aktualna',
        getValues: () => {
          return [
            { text: 'TAK', value: 0, disabled: !this.canUserEditMethod },
            { text: 'NIE', value: 1, disabled: !this.canUserEditMethod },
            { text: 'Wycofana bez zastąpienia', value: 2, disabled: !this.canUserEditMethod },
          ];
        },
        multiSelectTrack: 'name',
      },
      {
        fieldType: 'radiobuttons',
        value: 'accreditation',
        css: 'subpart w-50 p-3',
        headerName: 'Akredytacja',
        getValues: () => {
          return [
            {
              text: 'Tak',
              value: true,
              disabled: !this.canUserEditMethod,
            },
            {
              text: 'Nie',
              value: false,
              disabled: !this.canUserEditMethod,
            },
          ];
        },
      },
    ];
  }
}
