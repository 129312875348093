













































import { Component, Emit, Ref, Vue } from 'vue-property-decorator';
import * as types from '../../types';
import * as API from '../../store/api';
import * as consts from '../../consts';
import Modal from './Modal.vue';
import moment from 'moment';
import { StatusCodes } from '../../consts';

@Component({
  components: {
    Modal,
  },
})
export default class ImportSamplesCSV extends Vue {
  @Ref() readonly modal: Modal;

  uploading = false;
  analysis = [];
  allLinesCount = 0;
  counter = 0;
  savedCounter = 0;
  failedCounter = 0;
  existedCounter = 0;
  modalMessage = '';
  dates = [];
  dots = '';
  dotsInterval;
  terminate = false;
  regex = /;\r\n|;\n/;
  mappingArray = [
    [/PELET SŁONECZNIKOWY/g, 'Pelet ze słonecznika'],
    [/Pobrał/g, 'Pobrał;'],
    [/Bioenergia/g, 'Bioenergia;'],
    [/Elpolab/g, 'Elpolab;'],
    [/;;/g, ';'],
  ];
  disabledOkButton = true;
  allDicts = [];
  importedSamples = [];
  importedSample = {};
  buttonKey = 1;
  isAgroImport = false;
  isWoodImport = false;

  update(e: any, param: any = null) {
    if (this.importedSamples.length > 0) {
      this.$emit('update', this.importedSamples);
    }
  }

  formatDate(date: string) {
    return moment(date, consts.DATE_TIME_FORMAT).format(consts.DB_DATE_TIME_FORMAT);
  }

  async importFileChange(e) {
    this.counter = 0;
    this.savedCounter = 0;
    this.failedCounter = 0;
    this.existedCounter = 0;
    this.importedSamples = [];
    let response = await API.fetchTests();
    if (response.data.success === true) {
      this.analysis = response.data.tests;
    }
    response = await API.fetchDicts();
    this.allDicts = response.data.dicts;
    console.log('Import start');
    const formData = new FormData();
    formData.append('rd_file', e.target.files[0]);
    formData.set('filename', e.target.files[0].name);

    this.uploading = true;
    console.log(e.target.files[0]);
    const reader = new FileReader();
    this.modal.showModal();

    reader.onload = async function (theFile) {
      console.log('theFile', theFile);
      let resultString = reader.result.toString();
      const endofHeader1 = resultString.indexOf('Mar');
      const startOfHeader = resultString.indexOf('[kJ/kg]');
      const endOfHeader2 = resultString.lastIndexOf('[%]');
      const startOfFooter = resultString.indexOf('Próbki pobrane przez');
      resultString =
        resultString.slice(endofHeader1 + 1, startOfHeader - 1) +
        resultString.slice(endOfHeader2 + 3, startOfFooter - 1);
      for (let j = 0; j < this.mappingArray.length; j++) {
        resultString = resultString.replace(this.mappingArray[j][0], this.mappingArray[j][1]);
      }
      const allLines = resultString.split(this.regex);
      console.log('allLines', allLines);
      for (const line of allLines) {
        if (line.includes(consts.ObjectTypes.PELET)) {
          this.isAgroImport = true;
          break;
        } else {
          this.isAgroImport = false;
        }
      }
      this.isWoodImport = !this.isAgroImport;
      this.allLinesCount = allLines.length - 1;
      let response = await API.fetchSamples();
      let data = response.data;
      const samples = data.samples;
      response = await API.fetchDicts();
      data = response.data;
      const dicts = data.dicts;

      const func = function () {
        this.dots += '.';
        if (this.dots.length > 3) {
          this.dots = '';
        }
      }.bind(this);
      this.dotsInterval = window.setInterval(func, 500);
      for (let i = 1; i < allLines.length; i++) {
        if (this.terminate) {
          this.terminate = false;
          this.uploading = false;
          break;
        }
        console.log('lp. ', i);
        let line = allLines[i];
        if (line === '') {
          continue;
        }
        line = line.replace(/"/g, '');

        const NUMBER_COL = 0;
        const DATE_COL = 1;
        const A_HOUR_COL = 2;
        const PLACE_COL = 3;
        const PT_NUMBER_COL = 4;
        const OBJECT_TYPE_ID_COL = 5;
        const A_IDENTIFIER_COL = 6;
        const W_VEHICLE_COUNT = 6;
        const A_VEHICLE_COUNT = 7;
        const W_TONNAGE_COL = 7;
        const A_TONNAGE_COL = 8;
        const W_TAKE_PERSON_COL = 8;
        const A_TAKE_PERSON_COL = 9;

        const sampleCsvRow = line.split(';');
        if (samples.find(s => s.number === sampleCsvRow[NUMBER_COL])) {
          this.existedCounter++;
          this.counter++;
          console.log('EXISTS');
          this.buttonKey++;
        } else if (sampleCsvRow[NUMBER_COL] == '') {
          continue;
        } else {
          console.log(sampleCsvRow);

          const sample = new types.Sample();
          sample.number = sampleCsvRow[NUMBER_COL];
          const place = dicts.find(
            d => d.type === consts.DictType.TAKE_PLACE && d.value.toLowerCase() === sampleCsvRow[3].toLowerCase()
          );
          if (place) {
            sample.take_place_ids = [{ id: place.id }];
          }
          sample.pt_number = sampleCsvRow[PT_NUMBER_COL];
          const objectType = dicts.find(
            d =>
              d.type === consts.DictType.OBJECT_TYPE &&
              d.value.toLowerCase() === sampleCsvRow[OBJECT_TYPE_ID_COL].toLowerCase()
          );
          if (objectType) {
            sample.object_type_id = objectType.id;
          }
          sample.sample_object_id = dicts.find(
            d => d.type === consts.DictType.OBJECT && d.value.toLowerCase() === consts.Objects.BIOMASA.toLowerCase()
          ).id;
          if (this.isAgroImport) {
            sample.vehicle_count = parseInt(sampleCsvRow[A_VEHICLE_COUNT]);
            sample.tonnage = parseFloat(sampleCsvRow[A_TONNAGE_COL]);
            sample.take_person_string = sampleCsvRow[A_TAKE_PERSON_COL];
          } else {
            sample.vehicle_count = parseInt(sampleCsvRow[W_VEHICLE_COUNT]);
            sample.tonnage = parseFloat(sampleCsvRow[W_TONNAGE_COL]);
            sample.take_person_string = sampleCsvRow[W_TAKE_PERSON_COL];
          }
          let takeMethod = {};
          if (sample.number.includes('B')) {
            if (sample.take_person_string === consts.TakePersonStr.ELPOLAB) {
              takeMethod = dicts.find(
                d => d.type === consts.DictType.TAKE_METHOD && d.value === consts.TakeMethods.M131
              );
            } else {
              takeMethod = dicts.find(
                d => d.type === consts.DictType.TAKE_METHOD && d.value === consts.TakeMethods.LABSYS
              );
            }
          } else {
            takeMethod = dicts.find(d => d.type === consts.DictType.TAKE_METHOD && d.value === consts.TakeMethods.M129);
          }
          if (takeMethod) {
            sample.take_method_ids.push(takeMethod);
          }
          sample.take_place_string = sampleCsvRow[PLACE_COL];
          sample.area_test_id = dicts.find(
            d => d.type === consts.DictType.AREA && d.value === consts.AreaTestStr.OUT
          ).id;
          const testNumbers = [
            consts.Tests.WILGOC_CALK.number,
            consts.Tests.WILGOC_POPIOL_BIOMASA_A.number,
            consts.Tests.WILGOC_POPIOL_BIOMASA_B.number,
            consts.Tests.SIARKA_CALK.number,
            consts.Tests.WODOR.number,
            consts.Tests.CIEPLO_SPALANIA.number,
          ];
          const labOrderedTests = [];
          sample.tests = this.analysis.filter(a => testNumbers.includes(a.number));
          testNumbers.forEach(a => {
            labOrderedTests.push(sample.tests.filter(t => t.number === a)[0]);
          });
          sample.tests = labOrderedTests;
          sample.source_id = this.allDicts.find(
            d => d.type === consts.DictType.SOURCE && d.value === consts.Sources.DOSTAWY_S
          ).id;
          sample.client_id = consts.CLIENT_ID_ELEKTROWNIA;
          sample.prepare_norm_id = this.allDicts.find(
            d => d.type === consts.DictType.PREPARE_NORM && d.value === consts.PrepareNorm.BIOMASA
          ).id;
          sample.recalculate_norm_id = this.allDicts.find(
            d => d.type === consts.DictType.CALC_NORM && d.value === consts.CalcNorm.BIOMASA
          ).id;

          sample.sample_dates = [];
          if (this.isAgroImport) {
            const takeHours = sampleCsvRow[A_HOUR_COL].split(/\r\n|\n/);
            const takeIds = sampleCsvRow[A_IDENTIFIER_COL].split(/\r\n|\n/);
            const datesHours = [];
            for (let h = 0; h < takeHours.length; h++) {
              datesHours[h] = sampleCsvRow[DATE_COL] + ' ' + takeHours[h];
            }
            if (sampleCsvRow[DATE_COL].includes('.')) {
              for (let h = 0; h < takeHours.length; h++) {
                const sampleDate = {
                  date_from: this.formatDate(datesHours[h]),
                  time_from: takeHours[h],
                  number: takeIds[h],
                };
                sample.sample_dates.push(sampleDate);
              }
            } else {
              for (let h = 0; h < takeHours.length; h++) {
                const sampleDate = {
                  date_from: datesHours[h],
                  time_from: takeHours[h],
                  number: takeIds[h],
                };
                sample.sample_dates.push(sampleDate);
              }
            }
          } else {
            const regEx = /\d{4}-\d{2}-\d{2}/g;
            const dates = sampleCsvRow[DATE_COL].match(regEx);
            const sampleDate = {
              date_from: dates[0],
              time_from: '00:00',
              date_to: dates[1],
              time_to: '00:00',
            };
            sample.sample_dates.push(sampleDate);
          }
          if (sample.number.includes('B')) {
            sample.biomass_source = consts.DocSymbols.F11_9;
          } else {
            sample.biomass_source = consts.DocSymbols.F11_6;
          }
          console.log('sample to save', sample);
          let response = await API.saveSample(sample);
          if (response.status === StatusCodes.OK) {
            this.counter++;
            this.savedCounter++;
            response = await API.fetchSampleByNumber({ number: sample.number });
            this.importedSample = response.data.sample;
            this.importedSamples.push(this.importedSample);
            console.log('imported samples', this.importedSamples);
          } else {
            this.counter++;
            this.failedCounter++;
          }
        }
      }
      this.uploading = false;
      this.disabledOkButton = false;
      clearInterval(this.dotsInterval);
    }.bind(this);

    reader.readAsText(e.target.files[0], 'UTF-8');
    this.buttonKey++;
  }
  stopClick() {
    this.disabledOkButton = false;
    this.terminate = true;
  }
}
